import React from 'react';
import { GraphQLError } from 'graphql';
import { ServerError, ServerParseError } from '@apollo/client';
import { ErrorDialog } from './ErrorDialog';

interface Props {
  error: Error | GraphQLError | ServerError | ServerParseError;
  resetErrorBoundary: () => void;
}

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: Props): JSX.Element => {
  const [showErrorDialog, setShowErrorDialog] = React.useState(true);

  const getTitle = () => {
    if (error.hasOwnProperty('extensions')) {
      const graphqlError = error as GraphQLError;
      const { code } = graphqlError.extensions ?? { code: '' };
      return (code as string).replaceAll('_', ' ').toLocaleUpperCase();
    }

    return `${error.name ?? 'Error'}`;
  };

  const title = getTitle();
  const errorMessage = `${error.message}`;

  const handleDismiss = () => {
    setShowErrorDialog(false);
    resetErrorBoundary();
  };

  return (
    <ErrorDialog
      open={showErrorDialog}
      title={title}
      errorMessage={errorMessage}
      handleDismiss={handleDismiss}
    />
  );
};
