import { LocalOffer } from '@material-ui/icons';

import { EditTagPage } from '../../../../../pages/app/admin/tags/EditTagPage';
import { NewTagPage } from '../../../../../pages/app/admin/tags/NewTagPage';
import { TagsTablePage } from '../../../../../pages/app/admin/TagsTablePage';
import { Route } from '../../../Route';

export type TagRoutesType = {
  tagsNew: Route<unknown>;
  tagsEdit: Route<unknown>;
  tags: Route<unknown>;
};

export const tagRoutes = (parent: Route<unknown>): TagRoutesType => {
  const tags = new Route({
    icon: LocalOffer,
    component: TagsTablePage,
    path: '/tags',
    parent,
    exact: true,
  });

  const tagsNew = new Route({
    path: '/new',
    component: NewTagPage,
    parent: tags,
    exact: true,
  });

  const tagsEdit = new Route({
    path: '/:id',
    component: EditTagPage,
    parent: tags,
    exact: true,
  });

  return { tagsNew, tagsEdit, tags };
};
