import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../common/access-control/has-role-hook';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { useFormStyles } from '../../common/form/styles';
import { ApiRequestErrorAndLoadingWrapper } from '../../common/ApiRequestWrapper';
import { KeycloakUserWithRole } from '../hooks/get-users-hook';
import { useUpdateUserRole } from '../hooks/update-user-role-hook';

interface Props {
  user: KeycloakUserWithRole;
  formId: string;
  handleFormSubmitted: () => void;
}

export const EditUserForm = ({
  user,
  formId,
  handleFormSubmitted,
}: Props): JSX.Element => {
  const classes = useFormStyles();
  const { t } = useTranslation();

  const { updateUserRole, loading, error } = useUpdateUserRole();

  const initialValues = {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: user.role,
  };

  const { handleSubmit, control } = useForm<typeof initialValues>({
    mode: 'onTouched',
    defaultValues: initialValues,
  });

  const submitHandler: SubmitHandler<typeof initialValues> = async (data) => {
    await updateUserRole(user, data.role);
    handleFormSubmitted();
  };

  return (
    <ApiRequestErrorAndLoadingWrapper loading={loading} error={error}>
      <form id={formId} onSubmit={handleSubmit(submitHandler)}>
        <Grid
          container
          spacing={LayoutConstants.gridSpacing}
          className={classes.root}
        >
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('users.dialog.form.name')}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('users.dialog.form.email')}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="user-role-label">
                {t('users.dialog.form.role')}
              </InputLabel>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="user-role-label"
                    label={t('users.dialog.form.role')}
                  >
                    {Object.values(UserRole).map((role) => (
                      <MenuItem value={role} key={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
