import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { titleCase } from '../../../common/StringUtils';
import { BookInput } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { AuthorForm } from '../../common/form/AuthorForm';
import { InputField } from '../../common/form/InputField';
import { OriginalLanguageSelect } from '../../common/form/OriginalLanguageSelect';
import { TagsAutocompleteInput } from '../../common/form/tags-autocomplete/TagsAutocompleteInput';

const BookMetaForm = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    formState: { errors },
  } = useFormContext<BookInput>();

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={12}>
        <InputField name="ISBN" label="ISBN" errorData={errors.ISBN} />
      </Grid>
      <Grid item xs={12}>
        <InputField
          name="externalLink"
          label={titleCase(t('form.linkToBook'))}
          required={true}
          errorData={errors.externalLink}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <OriginalLanguageSelect
          name="originalLanguage"
          label={t('phrases.originalLanguage')}
          errorData={errors.originalLanguage}
        />
      </Grid>
      <Grid item xs={6} sm={2} xl={1}>
        <InputField
          name="year"
          type={'number'}
          label={titleCase(t('commons.year'))}
          errorData={errors.year}
        />
      </Grid>
      <Grid item xs={12} sm xl>
        <InputField
          name="publisher"
          label={titleCase(t('commons.publisher'))}
          multiline
          errorData={errors.publisher}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <AuthorForm />
      </Grid>
      <Grid item xs={12}>
        <TagsAutocompleteInput name="tagIds" />
      </Grid>
    </Grid>
  );
};

export { BookMetaForm };
