import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../../common/StringUtils';
import { getTranslatedLanguage } from '../../../../common/supported-languages';
import { TranslationLink } from './types';

interface Props {
  translationLinks: TranslationLink[];
}

export const ResourceCardTranslationLinks = ({
  translationLinks,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { lang: activeLanguage } = useParams<{ lang: string }>();

  return (
    <Grid container item xs={12} justify="space-between" alignItems="center">
      <Typography color="textSecondary">{t('commons.translations')}</Typography>
      {translationLinks.length > 0 ? (
        translationLinks.map(({ language, url }) => {
          const languageString = capitalizeFirstLetter(
            getTranslatedLanguage(t, language)
          );
          return (
            <Typography color="textSecondary" key={language}>
              {language !== activeLanguage ? (
                <Link to={url}>{languageString}</Link>
              ) : (
                <span>{languageString}</span>
              )}
            </Typography>
          );
        })
      ) : (
        <Typography color="textSecondary">
          <span>-</span>
        </Typography>
      )}
    </Grid>
  );
};
