import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AcademicWorkDataFragment } from '../../graphql/models';
import { GenericCard } from '../common/layout/GenericCard';
import { ResourceCardTagsSection } from '../common/resources/card/ResourceCardTagsSection';
import { ResourceCardTranslationLinks } from '../common/resources/card/ResourceCardTranslationLinks';
import { ResourceCardOriginalLanguageSection } from '../common/resources/card/ResourceCardOriginalLanguageSection';
import { useRoutes } from '../../common/routing/routes-hook';
import { Grid, Typography, Button } from '@material-ui/core';

interface Props {
  academicWork: AcademicWorkDataFragment;
}

export const AcademicWorkCard = ({ academicWork }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { routes } = useRoutes();
  const { lang: activeLanguage } = useParams<{ lang: string }>();

  const { originalLanguage, externalLink, content, id, tags } = academicWork;

  const translationLinks = content
    .filter((text) => text.language !== originalLanguage)
    .flatMap((text) => ({
      url: routes.academicWorkResult.pathWithArguments({
        id: id,
        lang: text.language,
      }),
      language: text.language,
    }));

  const originalLanguageLinkData = {
    url: routes.academicWorkResult.pathWithArguments({
      id: id,
      lang: originalLanguage,
    }),
    language: originalLanguage,
  };

  const originalLanguageIsAccessible =
    content.map((text) => text.language).includes(originalLanguage) &&
    academicWork.originalLanguage !== activeLanguage;

  return (
    <GenericCard title={t('phrases.additionalInformation')}>
      <ResourceCardOriginalLanguageSection
        originalLanguageLink={originalLanguageLinkData}
        isAccessible={originalLanguageIsAccessible}
      />
      <ResourceCardTranslationLinks translationLinks={translationLinks} />
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary">Link</Typography>
        <Button href={externalLink} target="_blank">
          {t('phrases.externalLink')}
        </Button>
      </Grid>
      <ResourceCardTagsSection tags={tags || []} />
    </GenericCard>
  );
};
