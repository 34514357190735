import { Grid } from '@material-ui/core';
import { BookForm } from '../../../../components/book/form/BookForm';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { useParams } from 'react-router-dom';
import { useBookQuery } from '../../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../../components/common/ApiRequestWrapper';
import { FormBreadCrumbs } from '../../../../components/common/navigation/breadcrumbs/FormBreadcrumbs';

export const EditBookPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const numberId = Number(id);

  const { data, error, loading } = useBookQuery({
    variables: { id: numberId },
  });

  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <FormBreadCrumbs translateLastPathName={data?.book === undefined} />
        </Grid>
        <Grid item xs={12}>
          <BookForm book={data?.book} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
