import { Grid, Hidden, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';
import { SearchResultsFilterAccordion } from '../../../components/search/results/filter-accordion/SearchResultsFilterAccordion';
import { useSearchDataContext } from '../../../components/search/SearchContext';
import {
  SupportedLanguage,
  TagCategory,
  TagDataFragment,
  useSearchLazyQuery,
  useTagsQuery,
} from '../../../graphql/models';
import {
  VisibleTotalSearchResultCount,
  TagCategoryWithTags,
  SearchResultsTagFilter,
} from './types';
import { SearchResults } from '../../../components/search/results/SearchResults';
import {
  getFilteredResourcesFromAllResources,
  SearchResultResourcesType,
} from './filters';
import { countResourcesWithTag } from './count';

export type ResourcesCountType = {
  [resourceName in keyof SearchResultResourcesType]: VisibleTotalSearchResultCount;
};

export type TagsCountMapType = Map<number, VisibleTotalSearchResultCount>;

const useStyles = makeStyles((theme) => ({
  resultContent: {
    padding: theme.spacing(2),
    '& p': {
      ...theme.typography.body2,
    },
  },
  contentSpacing: {
    paddingLeft: theme.spacing(18),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(6),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
}));

export const SearchResultsPage = (): JSX.Element => {
  const classes = useStyles();
  const { searchInputData, searchCount } = useSearchDataContext();
  const { i18n } = useTranslation();

  const [lastSearchCount, setLastSearchCount] = React.useState(searchCount);

  const preferredLanguage = i18n.language.substr(0, 2) as SupportedLanguage;

  const [
    fetchSearchResults,
    { data: searchData, loading: searchLoading, error: searchError },
  ] = useSearchLazyQuery({
    variables: {
      input: {
        ...searchInputData,
        preferredLanguage,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const {
    data: tagData,
    loading: tagLoading,
    error: tagError,
  } = useTagsQuery();

  const orderedTags = [...(tagData?.tags ?? [])].sort(
    (a, b) => a.category.priority - b.category.priority
  );

  const getDistinctCategories = (tagList: Array<TagDataFragment>) => {
    return [...new Set(tagList.map((tag) => tag.category))];
  };

  const addTagsToCategory = (category: TagCategory) => {
    return {
      ...category,
      tags: orderedTags.filter((tag) => tag.category.id === category.id),
    };
  };

  const getCategoriesWithTags = (categoryList: Array<TagCategory>) => {
    return categoryList.map(addTagsToCategory);
  };

  /* We only show categories that contain at least one tag. */
  const orderedCategories: TagCategoryWithTags[] = getCategoriesWithTags(
    getDistinctCategories(orderedTags)
  );

  const [searchResultsTagFilter, setSearchResultsTagFilter] =
    React.useState<SearchResultsTagFilter>({});

  // triggered whenever the search button is clicked
  React.useEffect(() => {
    fetchSearchResults();
  }, [fetchSearchResults, searchCount]);

  /* Initially, all tags are visible. */
  const getInitialVisibleTags = (tags?: TagDataFragment[]) => {
    return (
      tags?.reduce((prev, curr) => {
        prev[curr.id] = true;
        return prev;
      }, {} as SearchResultsTagFilter) ?? []
    );
  };

  React.useEffect(() => {
    if (searchCount !== lastSearchCount) {
      setSearchResultsTagFilter(getInitialVisibleTags(tagData?.tags));
      setLastSearchCount(searchCount);
    }
  }, [tagData, searchCount, lastSearchCount]);

  React.useEffect(() => {
    setSearchResultsTagFilter((prevSearchResultsTagFilter) => ({
      ...prevSearchResultsTagFilter,
      ...getInitialVisibleTags(tagData?.tags),
    }));
  }, [tagData]);

  if (!tagData || tagLoading || !searchData || searchLoading) {
    return (
      <ApiRequestErrorAndLoadingWrapper
        loading={tagLoading || searchLoading}
        error={tagError || searchError}
      />
    );
  }

  const { executionTimeInSeconds, __typename, ...untypedResources } =
    searchData.search;

  const resources = untypedResources as SearchResultResourcesType;

  const filteredResources = getFilteredResourcesFromAllResources(
    resources,
    searchResultsTagFilter
  );

  // counts per resource type
  const resourcesCount = Object.fromEntries(
    Object.entries(resources).map(([key, resource]) => {
      const resourceType = key as keyof SearchResultResourcesType;

      const visibleCount = filteredResources[resourceType].length;
      const totalCount = resource.length;

      return [resourceType, { visibleCount, totalCount }];
    })
  ) as ResourcesCountType;

  // counts per tag type
  const tagsCountMap: TagsCountMapType = new Map(
    orderedTags.map((tag) => {
      const visibleCount = countResourcesWithTag(filteredResources, tag);
      const totalCount = countResourcesWithTag(resources, tag);

      return [tag.id, { visibleCount, totalCount }];
    })
  );

  const totalResultsCount = Object.values(resourcesCount).reduce(
    (previous, current) => previous + current.totalCount,
    0
  );
  const visibleResultsCount = Object.values(resourcesCount).reduce(
    (previous, current) => previous + current.visibleCount,
    0
  );

  return (
    <Grid container className={classes.contentSpacing}>
      <Grid item sm={12} md={8} className={classes.resultContent}>
        <SearchResults
          filteredResources={filteredResources}
          executionTimeInSeconds={executionTimeInSeconds}
          resourcesCount={resourcesCount}
          countSearchResults={{
            visible: visibleResultsCount,
            total: totalResultsCount,
          }}
        />
      </Grid>
      <Hidden smDown>
        <Grid item xs={4}>
          <SearchResultsFilterAccordion
            searchResultsTagFilter={searchResultsTagFilter}
            setSearchResultsTagFilter={setSearchResultsTagFilter}
            orderedCategories={orderedCategories}
            tagsCountMap={tagsCountMap}
            countSearchResults={{
              visibleCount: visibleResultsCount,
              totalCount: totalResultsCount,
            }}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};
