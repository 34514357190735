import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { BookValidation } from '../../../model/validation/BookValidation';
import { BookMetaForm } from './BookMetaForm';
import {
  BookDataFragment,
  useCreateBookMutation,
  useUpdateBookMutation,
} from '../../../graphql/models';
import { QUERY_BOOKS } from '../../../graphql/queries/book/books';
import {
  yupAuthorsSchema,
  yupLinkSchema,
  yupOriginalLanguageSchema,
  yupTagIdsSchema,
} from '../../../common/CommonSchemas';
import { ResourceForm } from '../../common/form/ResourceForm';
import { useSetupResourceForm } from '../../common/hooks/useSetupResourceForm';
import { ResourceContentForm } from '../../common/form/ResourceContentForm';

interface Props {
  book?: BookDataFragment;
}

export const BookForm = ({ book }: Props): JSX.Element => {
  const { t } = useTranslation();

  const formTitle = book
    ? t('books.form.title.edit')
    : t('books.form.title.new');

  const additionalInitialValues = {
    ISBN: book?.ISBN || '',
    year: book?.year || new Date().getFullYear(),
    publisher: book?.publisher || '',
    externalLink: book?.externalLink || '',
  };

  const validationSchema = Yup.object().shape({
    ISBN: BookValidation.yupISBNSchema(t),
    year: BookValidation.yupYearSchema(),
    publisher: BookValidation.yupPublisherSchema(),
    authors: yupAuthorsSchema(),
    content: BookValidation.yupContentSchema(t),
    originalLanguage: yupOriginalLanguageSchema(),
    externalLink: yupLinkSchema(),
    tagIds: yupTagIdsSchema(),
  });

  const [createMutation] = useCreateBookMutation();
  const [updateMutation] = useUpdateBookMutation();

  const [form, handleSubmit, handleReset, errorMessage] = useSetupResourceForm({
    resource: book,
    resourceName: 'books',
    validationSchema,
    createMutation,
    updateMutation,
    query: QUERY_BOOKS,
    additionalInitialValues,
  });

  return (
    <ResourceForm
      formTitle={formTitle}
      form={form}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      metaForm={<BookMetaForm />}
      contentForm={<ResourceContentForm contentKey="summary" />}
      errorMessage={errorMessage}
    />
  );
};
