import { Assessment } from '@material-ui/icons';

import { TagVisualizationPage } from '../../../../pages/app/TagVisualizationPage';
import { UserRole } from '../../../access-control/has-role-hook';
import { Route } from '../../Route';

import { bookRoutes, BookRoutesType } from './books/book-routes';
import { summaryRoutes, SummaryRoutesType } from './summaries/summary-routes';
import { articleRoutes, ArticleRoutesType } from './articles/article-routes';
import {
  academicWorkRoutes,
  AcademicWorkRoutesType,
} from './academic-works/academic-work-routes';
import {
  tagCategoryRoutes,
  TagCategoryRoutesType,
} from './tag-categories/tag-category-routes';
import { tagRoutes, TagRoutesType } from './tags/tag-routes';
import { userRoutes, UserRoutesType } from './users/user-routes';
import { linkRoutes, LinkRoutesType } from './links/link-routes';

export interface AdminRoutesType
  extends ArticleRoutesType,
    SummaryRoutesType,
    LinkRoutesType,
    BookRoutesType,
    AcademicWorkRoutesType,
    TagCategoryRoutesType,
    TagRoutesType,
    UserRoutesType {
  admin: Route<unknown>;
}

export const adminRoutes = (): AdminRoutesType => {
  const admin = new Route({
    path: '/admin',
    component: TagVisualizationPage,
    icon: Assessment,
    roles: [UserRole.editor],
    exact: true,
  });

  const article = articleRoutes(admin);
  const summary = summaryRoutes(admin);
  const link = linkRoutes(admin);
  const book = bookRoutes(admin);
  const academicWork = academicWorkRoutes(admin);

  const tagCategory = tagCategoryRoutes(admin);
  const tag = tagRoutes(admin);

  const user = userRoutes(admin);

  return {
    ...article,
    ...summary,
    ...link,
    ...book,
    ...academicWork,
    ...tagCategory,
    ...tag,
    ...user,
    admin,
  };
};
