import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DEPLOYMENT_ROOT_PATH } from './config';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

void i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    // explicitly whitelist the supported languages to avoid getting the browser
    // language if it is not supported (e.g. using `i18n.language`)
    supportedLngs: ['en', 'it', 'de'],
    load: 'languageOnly',
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: { useSuspense: true },

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    returnEmptyString: false, // don't return strings that have not yet been translated

    backend: {
      loadPath: `${DEPLOYMENT_ROOT_PATH}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
