import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { Translate } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { SUPPORTED_LANGUAGES_OBJECT } from '../../../../common/supported-languages';
import { titleCase } from '../../../../common/StringUtils';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';

interface Props {
  menuSize: number;
}

const LocaleSelect = ({ menuSize }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
  const { i18n } = useTranslation();

  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ): Promise<void> => {
    if (typeof event.currentTarget.value !== 'undefined') {
      const index = event.currentTarget.value;
      const selectedLanguage = SUPPORTED_LANGUAGES_OBJECT[index].locale;
      await i18n.changeLanguage(selectedLanguage);
    }
    setAnchorEl(null);
  };

  // just take the first 2 letters since otherwise Safari crashes the app
  const currentLanguage = i18n.language.slice(0, 2);

  const languageOption = SUPPORTED_LANGUAGES_OBJECT.filter((obj) => {
    return obj.locale === currentLanguage;
  });

  // if the user's browser language is not supported, use the first one (default)
  const language =
    languageOption.length > 0
      ? languageOption[0]
      : SUPPORTED_LANGUAGES_OBJECT[0];

  return (
    <>
      <Button onClick={handleClick} startIcon={<Translate />} color="inherit">
        {language.original}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: menuSize,
          },
        }}
      >
        {SUPPORTED_LANGUAGES_OBJECT.map((supportedLanguage, index) => {
          return (
            <MenuItem onClick={handleClose} value={index} key={index}>
              <ListItemText primary={titleCase(supportedLanguage.original)} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export { LocaleSelect };
