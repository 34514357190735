import { Grid } from '@material-ui/core';

interface Props {
  children?: React.ReactNode;
}

export const ResourcePageCard = ({ children }: Props): JSX.Element => {
  return (
    <Grid item xs={12} md={5} container>
      <Grid item xs={12} lg={7}>
        {children}
      </Grid>
    </Grid>
  );
};
