import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Container,
  Divider,
} from '@material-ui/core';
import { LayoutConstants } from '../theme/LayoutConstants';
import { FullWidthFooter } from '../components/common/navigation/FullWidthFooter';
import { TopBar } from '../components/common/navigation/TopBar';

import { LatestEntries } from '../components/homepage/LatestEntries';
import { SiteTitle } from '../components/homepage/SiteTitle';
import { LoginAndRegisterButtons } from '../components/homepage/LoginAndRegisterButtons';
import { Introduction } from '../components/homepage/Introduction';
import { Logos } from '../components/homepage/Logos';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      width: '100%',
    },
    fullWidth: {
      width: '100%',
    },
    siteNameContainer: {
      marginTop: theme.spacing(LayoutConstants.gridSpacing),
    },
  })
);

export const HomePage = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar showUserMenu={false} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container maxWidth="sm">
          <Grid container spacing={LayoutConstants.gridSpacing}>
            <Grid item xs={12} className={classes.siteNameContainer}>
              <SiteTitle />
            </Grid>

            <Grid item xs={12}>
              <LatestEntries />
            </Grid>

            <Grid item xs={12}>
              <LoginAndRegisterButtons />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Introduction />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Logos />
            </Grid>
          </Grid>
        </Container>
      </main>
      <FullWidthFooter />
    </div>
  );
};
