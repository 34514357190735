import { Grid } from '@material-ui/core';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { TagCategoryForm } from '../../../../components/tag-category/form/TagCategoryForm';
import { FormBreadCrumbs } from '../../../../components/common/navigation/breadcrumbs/FormBreadcrumbs';

export const NewTagCategoryPage = (): JSX.Element => {
  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={12}>
        <FormBreadCrumbs />
      </Grid>
      <Grid item xs={12}>
        <TagCategoryForm />
      </Grid>
    </Grid>
  );
};
