import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';

import { TextEditorLinkDecoratorComponent } from './TextEditorLinkDecoratorComponent';

export const createLinkDecorator = (): CompositeDecorator =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: TextEditorLinkDecoratorComponent,
    },
  ]);

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
): void {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}
