import { LocalOffer } from '@material-ui/icons';

import { EditTagCategoryPage } from '../../../../../pages/app/admin/tag-categories/EditTagCategoryPage';
import { NewTagCategoryPage } from '../../../../../pages/app/admin/tag-categories/NewTagCategoryPage';
import { TagCategoriesTablePage } from '../../../../../pages/app/admin/TagCategoriesTablePage';
import { Route } from '../../../Route';

export type TagCategoryRoutesType = {
  tagCategoriesNew: Route<unknown>;
  tagCategoriesEdit: Route<unknown>;
  tagCategories: Route<unknown>;
};

export const tagCategoryRoutes = (
  parent: Route<unknown>
): TagCategoryRoutesType => {
  const tagCategories = new Route({
    icon: LocalOffer,
    component: TagCategoriesTablePage,
    path: '/tag-categories',
    parent,
    exact: true,
  });

  const tagCategoriesNew = new Route({
    path: '/new',
    component: NewTagCategoryPage,
    parent: tagCategories,
    exact: true,
  });

  const tagCategoriesEdit = new Route({
    path: '/:id',
    component: EditTagCategoryPage,
    parent: tagCategories,
    exact: true,
  });

  return {
    tagCategoriesNew,
    tagCategoriesEdit,
    tagCategories,
  };
};
