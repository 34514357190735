import { Grid, createStyles, makeStyles } from '@material-ui/core';

import conservatorioLogo from '../../assets/images/logo-conservatorio-website.png';
import estaLogo from '../../assets/images/logo-esta-website.png';

const useStyles = makeStyles(() =>
  createStyles({
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  })
);

export const Logos = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={5} className={classes.logoContainer}>
        <img
          className={classes.logo}
          src={conservatorioLogo}
          alt="conservatorio logo"
        />
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={4} className={classes.logoContainer}>
        <img
          className={classes.logo}
          src={estaLogo}
          alt="european string teachers association logo"
        />
      </Grid>
    </Grid>
  );
};
