import { Description } from '@material-ui/icons';

import { EditSummaryPage } from '../../../../../pages/app/admin/summaries/EditSummaryPage';
import { NewSummaryPage } from '../../../../../pages/app/admin/summaries/NewSummaryPage';
import { SummariesTablePage } from '../../../../../pages/app/admin/SummariesTablePage';
import { Route } from '../../../Route';

export type SummaryRoutesType = {
  summaries: Route<unknown>;
  summaryNew: Route<unknown>;
  summaryEdit: Route<unknown>;
};

export const summaryRoutes = (parent: Route<unknown>): SummaryRoutesType => {
  const summaries = new Route({
    path: '/summaries',
    component: SummariesTablePage,
    icon: Description,
    parent,
    exact: true,
  });

  const summaryNew = new Route({
    path: '/new',
    component: NewSummaryPage,
    parent: summaries,
    exact: true,
  });

  const summaryEdit = new Route({
    path: '/:id',
    component: EditSummaryPage,
    parent: summaries,
    exact: true,
  });

  return {
    summaries,
    summaryNew,
    summaryEdit,
  };
};
