import { Box } from '@material-ui/core';
import { SABreadcrumbs, SABreadcrumbsProps } from './Breadcrumbs';

export const FormBreadCrumbs = (props: SABreadcrumbsProps): JSX.Element => {
  return (
    <Box mb={2} mt={2} ml={1}>
      <SABreadcrumbs {...props} />
    </Box>
  );
};
