import React from 'react';

export default function useComponentResize<T extends HTMLElement>(
  ignore = false
): [React.RefObject<T>, number] {
  const componentRef = React.useRef<T>(null);

  const [componentWidth, setComponentWidth] = React.useState(0);
  const [componentWindowWidthDelta, setComponentWindowWidthDelta] =
    React.useState(0);

  const onWindowResize = React.useCallback(() => {
    setComponentWidth(window.innerWidth - componentWindowWidthDelta);
  }, [setComponentWidth, componentWindowWidthDelta]);

  React.useEffect(() => {
    if (componentWindowWidthDelta !== 0) {
      onWindowResize();
    }
  }, [componentWindowWidthDelta, onWindowResize]);

  React.useEffect(() => {
    window.addEventListener('resize', onWindowResize);

    const component = componentRef.current;

    if (component && !ignore) {
      const delta = window.innerWidth - component.getBoundingClientRect().width;
      if (delta !== componentWindowWidthDelta) {
        setComponentWindowWidthDelta(delta);
      }
    }

    return (): void => window.removeEventListener('resize', onWindowResize);
  }, [componentRef, componentWindowWidthDelta, onWindowResize, ignore]);

  return [componentRef, componentWidth];
}
