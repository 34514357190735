import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import useComponentResize from '../hooks/use-component-resize';

interface Props {
  children: React.ReactElement[];
  hide?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editorRoot: {
      fontSize: theme.typography.body1.fontSize,
      marginBottom: theme.spacing(1),
      '& .public-DraftEditorPlaceholder-root': {
        margin: '0 0 -15px 0',
        color: theme.palette.text.secondary,
      },
      '& .public-DraftEditorPlaceholder-hasFocus': {
        display: 'none',
      },
      '& .DraftEditor-editorContainer': {
        position: 'relative',
      },
    },
  })
);

export const TextEditorContainer = ({ children, hide }: Props): JSX.Element => {
  const classes = useStyles();

  const [containerRef, containerWidth] =
    useComponentResize<HTMLDivElement>(hide);

  return (
    <div ref={containerRef}>
      <div
        className={classes.editorRoot}
        style={{
          width: containerWidth ?? undefined,
        }}
      >
        {children}
      </div>
    </div>
  );
};
