import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { formatDate } from '../../common/date';
import { SummaryDataFragment } from '../../graphql/models';
import { GenericCard } from '../common/layout/GenericCard';
import { ResourceCardTagsSection } from '../common/resources/card/ResourceCardTagsSection';
import { ResourceCardTranslationLinks } from '../common/resources/card/ResourceCardTranslationLinks';
import { ResourceCardOriginalLanguageSection } from '../common/resources/card/ResourceCardOriginalLanguageSection';
import { useRoutes } from '../../common/routing/routes-hook';

interface Props {
  summary: SummaryDataFragment;
}

export const SummaryCard = ({ summary }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { routes } = useRoutes();
  const { lang: activeLanguage } = useParams<{ lang: string }>();

  const {
    dateCreated,
    dateUpdated,
    originalLanguage,
    content: contentList,
    id,
    tags,
  } = summary;

  const translationLinks = contentList
    .filter((content) => content.language !== originalLanguage)
    .flatMap((content) => ({
      url: routes.summaryResult.pathWithArguments({
        id: id,
        lang: content.language,
      }),
      language: content.language,
    }));

  const originalLanguageLinkData = {
    url: routes.summaryResult.pathWithArguments({
      id: id,
      lang: originalLanguage,
    }),
    language: originalLanguage,
  };

  const originalLanguageIsAccessible =
    contentList.map((content) => content.language).includes(originalLanguage) &&
    summary.originalLanguage !== activeLanguage;

  return (
    <GenericCard title={t('phrases.additionalInformation')}>
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary">
          {' '}
          {t('phrases.publishedOn')}
        </Typography>
        <Typography color="textPrimary">
          {formatDate(new Date(dateCreated))}
        </Typography>
      </Grid>
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary"> {t('phrases.updatedOn')}</Typography>
        <Typography color="textPrimary">
          {formatDate(new Date(dateUpdated))}
        </Typography>
      </Grid>
      <ResourceCardOriginalLanguageSection
        originalLanguageLink={originalLanguageLinkData}
        isAccessible={originalLanguageIsAccessible}
      />
      <ResourceCardTranslationLinks translationLinks={translationLinks} />
      <ResourceCardTagsSection tags={tags || []} />
    </GenericCard>
  );
};
