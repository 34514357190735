import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation, Trans } from 'react-i18next';

export const SearchInstructions = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>{t('search.instructions.title')}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider />
        <div>
          <Trans t={t} i18nKey="search.instructions.details">
            <p>
              To search a document in StringsART you can use <b>text search</b>{' '}
              and <b>tag filtering</b>. They can be used individually or you can
              combine them.
            </p>
            <p>
              <b>Text Search</b>
            </p>
            <p>
              The text search works much like a web search engine where you can
              search for keywords. The search query can include words and
              identifiers from a document's title, content, authors, year, tag,
              venue, DOI, publisher and ISBN.
            </p>
            <p>
              In order to refine the search you can use the following syntax:
            </p>
            <ul>
              <li>
                unquoted text will search for documents that contain all of the
                words
                <br />
                e.g., <code>violin intonation</code>
              </li>
              <li>
                quoted text will search for documents that contain the given
                phrase
                <br />
                e.g., <code>"violin intonation"</code>
              </li>
              <li>
                the word <code>OR/or</code> will search for documents that
                contain any of the words
                <br />
                e.g., <code>violin or intonation</code>
              </li>
              <li>
                The dash (<code>-</code>) before a word will search for
                documents that don't contain the given word
                <br />
                e.g., <code>-violin intonation</code>
              </li>
            </ul>
            <p>
              You can combine any of the above in order to create more refined
              queries.
            </p>
            <p>
              <b>Tag Filtering</b>
            </p>
            <p>
              Using tag filtering the search will return those documents that
              contain all of the tags selected.
            </p>
            <p>
              <b>Complete Example</b>
            </p>
            <p>
              If you want to find documents that have the words{' '}
              <code>violin</code> or <code>cello</code>, not the word{' '}
              <code>bass</code>, the two words <code>string</code> and{' '}
              <code>performance</code> in the given order, as well as being
              tagged with <code>intonation</code>, you would use the query
              below:
            </p>
            <ul>
              <li>
                text: <code>"violin OR cello" -bass "string performance"</code>
              </li>
              <li>
                tags: <code>intonation</code>
              </li>
            </ul>
          </Trans>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
