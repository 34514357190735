import { TagDataFragment } from '../../../graphql/models';
import { SearchResultResourcesType } from './filters';

export const countResourcesWithTag = (
  resources: SearchResultResourcesType,
  tag: TagDataFragment
): number =>
  Object.entries(resources).reduce((total, [_, resourceType]) => {
    return (
      total +
      resourceType.filter((resource) =>
        resource.tags?.map((resourceTag) => resourceTag.id).includes(tag.id)
      ).length
    );
  }, 0);
