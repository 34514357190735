import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { LinkMetaForm } from './LinkMetaForm';
import {
  useCreateLinkMutation,
  useUpdateLinkMutation,
  LinkDataFragment,
} from '../../../graphql/models';
import { QUERY_LINKS } from '../../../graphql/queries/link/links';
import {
  yupAuthorsSchema,
  yupContentSchema,
  yupLinkSchema,
  yupOriginalLanguageSchema,
  yupTagIdsSchema,
} from '../../../common/CommonSchemas';
import { ResourceForm } from '../../common/form/ResourceForm';
import { useSetupResourceForm } from '../../common/hooks/useSetupResourceForm';
import { ResourceContentForm } from '../../common/form/ResourceContentForm';

interface Props {
  link?: LinkDataFragment;
}

export const LinkForm = ({ link }: Props): JSX.Element => {
  const { t } = useTranslation();

  const formTitle = link
    ? t('links.form.title.edit')
    : t('links.form.title.new');

  const additionalInitialValues = {
    link: link?.link ?? '',
  };

  const validationSchema = Yup.object().shape({
    authors: yupAuthorsSchema(),
    link: yupLinkSchema(),
    content: yupContentSchema('description', t),
    originalLanguage: yupOriginalLanguageSchema(),
    tagIds: yupTagIdsSchema(),
  });

  const [createMutation] = useCreateLinkMutation();
  const [updateMutation] = useUpdateLinkMutation();

  const [form, handleSubmit, handleReset, errorMessage] = useSetupResourceForm({
    resource: link,
    resourceName: 'links',
    validationSchema,
    createMutation,
    updateMutation,
    query: QUERY_LINKS,
    additionalInitialValues,
  });

  return (
    <ResourceForm
      formTitle={formTitle}
      form={form}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      metaForm={<LinkMetaForm />}
      contentForm={<ResourceContentForm contentKey="description" />}
      errorMessage={errorMessage}
    />
  );
};
