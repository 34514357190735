import { Grid, Hidden } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../common/date';
import { NarrativeInput } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { AuthorForm } from '../../common/form/AuthorForm';
import { InputField } from '../../common/form/InputField';
import { OriginalLanguageSelect } from '../../common/form/OriginalLanguageSelect';
import { TagsAutocompleteInput } from '../../common/form/tags-autocomplete/TagsAutocompleteInput';

interface Props {
  dateCreated: Date;
  dateUpdated: Date;
}

export const SummaryMetaForm = ({
  dateCreated,
  dateUpdated,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const {
    formState: { errors },
  } = useFormContext<NarrativeInput>();

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={6} sm={4} lg={2}>
        <InputField
          name="dateCreated"
          label={t('phrases.publishedOn')}
          defaultValue={formatDate(dateCreated)}
          disabled
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <InputField
          name="dateUpdated"
          label={t('phrases.updatedOn')}
          defaultValue={formatDate(dateUpdated)}
          disabled
        />
      </Grid>
      <Hidden xsDown>
        <Grid item xs sm={4} lg={8} />
      </Hidden>
      <Grid item xs={6} sm={4} lg={2}>
        <OriginalLanguageSelect
          name="originalLanguage"
          label={t('phrases.originalLanguage')}
          errorData={errors.originalLanguage}
        />
      </Grid>
      <Grid item xs={12}>
        <AuthorForm />
      </Grid>
      <Grid item xs={12}>
        <TagsAutocompleteInput name="tagIds" />
      </Grid>
    </Grid>
  );
};
