import { GridSpacing } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const LayoutConstants = {
  gridSpacing: 3 as GridSpacing,
  formTopSpacing: 1 as number,
  elevation: 0 as number,
  searchFieldSpacing: '150px' as string,
  tagSpacing: 1,
  toolbarHeight: '64px',
  drawerWidth: '240px',
  drawerBreakpoint: 'md' as Breakpoint,
  minimalToolbarHeight: '40px',
};
