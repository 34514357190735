import { TFunction, i18n } from 'i18next';
import { SupportedLanguage, Tag, TagDataFragment } from '../graphql/models';
import { TagDescriptionTooltipProps } from '../components/tag/TagTooltipHelpers';
import { languageCompare } from '../common/supported-languages';

export const getTranslatedTagNameOrDefault = (
  tag: TagDataFragment | Tag,
  language: SupportedLanguage
): string => {
  const translatedName = tag.content.find(
    (content) => content.language === language
  );
  if (translatedName) {
    return translatedName.name;
  }
  return tag.content[0].name;
};

export const getTagDescriptionTooltipProps = (
  tag: TagDataFragment,
  i18n: i18n,
  t?: TFunction
): TagDescriptionTooltipProps => {
  const description =
    tag.content.find(({ language }) => languageCompare(language, i18n))
      ?.description ||
    tag.content.find((value) => Boolean(value.description))?.description ||
    '';
  return {
    description,
    references: tag.references.map((reference) => ({
      text: reference.text,
      url: reference.url ?? '',
    })),
    descriptionTitle: t ? t('commons.form.description') : '',
    referenceTitle: t ? t('commons.form.references') : '',
  };
};

export const translatedNameLabel = (
  language: SupportedLanguage,
  t?: TFunction
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t ? t('tag.content.englishName') : '';
    case SupportedLanguage.It:
      return t ? t('tag.content.italianName') : '';
    case SupportedLanguage.De:
      return t ? t('tag.content.germanName') : '';
  }
};

export const translatedDescriptionLabel = (
  language: SupportedLanguage,
  t?: TFunction
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t ? t('tag.content.englishDescription') : '';
    case SupportedLanguage.It:
      return t ? t('tag.content.italianDescription') : '';
    case SupportedLanguage.De:
      return t ? t('tag.content.germanDescription') : '';
  }
};
