export const SITE_NAME = 'StringsART';

export const DEPLOYMENT_ROOT_PATH = process.env.PUBLIC_URL || '';

export const GRAPHQL_HOST =
  process.env.REACT_APP_GRAPHQL_HOST || 'http://localhost:5000/graphql';

export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL || '';
export const KEYCLOAK_REALM =
  process.env.REACT_APP_KEYCLOAK_REALM || 'stringsart';
export const KEYCLOAK_CLIENT_ID =
  process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'frontend';
