import { SvgIconComponent } from '@material-ui/icons';
import { generatePath } from 'react-router-dom';
import { UserRole } from '../access-control/has-role-hook';
import { Optional } from '../types';

interface RouteData<T> {
  path: string;
  parent?: Route<T>;
  roles?: UserRole[];
  component: React.ComponentType<T>;
  exact: boolean;
  icon?: SvgIconComponent;
}

export class Route<T> {
  constructor(private data: RouteData<T>) {}

  public get roles(): UserRole[] {
    return this.data.roles ?? this.data.parent?.roles ?? [UserRole.public];
  }

  public get icon(): Optional<SvgIconComponent> {
    return this.data.icon;
  }

  public get component(): React.ComponentType<T> {
    return this.data.component;
  }

  public get exact(): boolean {
    return this.data.exact;
  }

  public get path(): string {
    return this.resolvePath();
  }

  public pathWithArguments(data: { [key: string]: string | number }): string {
    return generatePath(this.resolvePath(), data);
  }

  private resolvePath = (): string => {
    const finalRoute = [this.data.path];
    let parent = this.data.parent;
    while (parent !== undefined) {
      finalRoute.push(parent?.data.path);
      parent = parent?.data.parent;
    }

    finalRoute.reverse();

    return finalRoute.join('');
  };
}
