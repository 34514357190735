import React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { UserRole } from '../../../common/access-control/has-role-hook';
import { KEYCLOAK_REALM, KEYCLOAK_URL } from '../../../config';
import { useAuthorizationHeader } from './authorization-header-hook';

export interface KeycloakUser {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface KeycloakUserWithRole extends KeycloakUser {
  role: UserRole;
}

export const useFetchKeycloakUsers = (): {
  users?: KeycloakUserWithRole[];
  error?: Error;
  loading: boolean;
  refreshUsers: () => void;
} => {
  const [keycloakUsers, setKeycloakUsers] =
    React.useState<AxiosResponse<Array<KeycloakUser>>>();
  const [usersWithRoles, setUsersWithRoles] =
    React.useState<KeycloakUserWithRole[]>();

  const [error, setError] = React.useState<Error | undefined>();
  const [loading, setLoading] = React.useState(false);

  const { authorizationHeader } = useAuthorizationHeader();

  const refreshUsers = () => setKeycloakUsers(undefined);

  const getUsersUrl = `${KEYCLOAK_URL}/admin/realms/${KEYCLOAK_REALM}/users`;

  const config = React.useMemo(
    (): AxiosRequestConfig => ({
      headers: { ...authorizationHeader, Accept: 'application/json' },
    }),
    [authorizationHeader]
  );

  React.useEffect(() => {
    // The user REST API doesn't contain the user roles
    // so we need to fetch the roles for each user

    const fetchUsers = async () => {
      try {
        if (!loading && !keycloakUsers) {
          setLoading(true);
          const axiosResponse = await axios.get(getUsersUrl, config);
          setKeycloakUsers(axiosResponse);
        }
      } catch (axiosError) {
        setError(axiosError as Error);
        setLoading(false);
      }
    };

    void fetchUsers();
  }, [authorizationHeader, loading, keycloakUsers, getUsersUrl, config]);

  React.useEffect(() => {
    const fetchRoles = async () => {
      try {
        if (loading && keycloakUsers) {
          const keycloakUsersWithRoles: KeycloakUserWithRole[] =
            await Promise.all(
              keycloakUsers.data.map(async (user) => {
                const getRoleUrl = `${getUsersUrl}/${user.id}/role-mappings/realm`;
                const role = await axios.get<
                  Array<{ name: string; id: string }>
                >(getRoleUrl, config);
                return { ...user, role: role.data[0].name as UserRole };
              })
            );
          setUsersWithRoles(keycloakUsersWithRoles);
          setLoading(false);
        }
      } catch (axiosError) {
        setError(axiosError as Error);
        setLoading(false);
      }
    };

    void fetchRoles();
  }, [loading, keycloakUsers, getUsersUrl, config]);

  return { users: usersWithRoles, error, loading, refreshUsers };
};
