import { Grid } from '@material-ui/core';
import { Article } from '../../../../components/article/form/ArticleForm';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { FormBreadCrumbs } from '../../../../components/common/navigation/breadcrumbs/FormBreadcrumbs';

export const NewArticlePage = (): JSX.Element => {
  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={12}>
        <FormBreadCrumbs />
      </Grid>
      <Grid item xs={12}>
        <Article />
      </Grid>
    </Grid>
  );
};
