import {
  SupportedLanguage,
  TagDataFragment,
  TagsQuery,
} from '../../../../graphql/models';
import { getTranslatedTagCategoryContentOrDefault } from '../../../../model/TagCategory';
import { Category } from './network.type';

export function collectSortedCategoriesFromTags(
  tags: TagDataFragment[],
  language: SupportedLanguage
): Category[] {
  const updatedCategories: Category[] = tags.reduce(
    (collectedCategories, { category }) => {
      const currentGroupOption = collectedCategories.find(
        (collectedCategory) => collectedCategory.id === category.id
      );
      if (!currentGroupOption) {
        collectedCategories.push({
          id: category.id,
          priority: category.priority,
          name: getTranslatedTagCategoryContentOrDefault(
            category,
            'name',
            language
          ),
          itemStyle: {
            color: category.color,
          },
          show: true,
        });
      }

      return collectedCategories;
    },
    [] as Category[]
  );

  return updatedCategories.sort((a, b) => a.priority - b.priority);
}

export function buildTagDataWithUpdatedCategories(
  data: TagsQuery,
  tagCategoryIdToUpdatedTagCategory: Map<number, Category>
): TagsQuery['tags'] {
  const tags = data.tags
    .filter(
      (tag) => tagCategoryIdToUpdatedTagCategory.get(tag.category.id)?.show
    )
    .map((tag) => ({
      ...tag,
      category: {
        ...tag.category,
        priority:
          tagCategoryIdToUpdatedTagCategory.get(tag.category.id)?.priority ??
          tag.category.priority,
      },
    }));

  return tags;
}

export function buildCategoryIdToUpdatedCategories(
  categoryLegendState: Category[]
): Map<number, Category> {
  return new Map(
    categoryLegendState
      .filter((cat) => cat.show)
      .sort((a, b) => a.priority - b.priority)
      .map((cat, index) => [cat.id, { ...cat, priority: index + 1 }])
  );
}
