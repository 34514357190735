import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AcademicWorkMetaForm } from './AcademicWorkMetaForm';
import {
  AcademicWorkDataFragment,
  useCreateAcademicWorkMutation,
  useUpdateAcademicWorkMutation,
} from '../../../graphql/models';
import { QUERY_ACADEMIC_WORKS } from '../../../graphql/queries/academic-work/academic-works';
import {
  yupAuthorsSchema,
  yupContentSchema,
  yupLinkSchema,
  yupOriginalLanguageSchema,
  yupTagIdsSchema,
} from '../../../common/CommonSchemas';
import { ResourceForm } from '../../common/form/ResourceForm';
import { useSetupResourceForm } from '../../common/hooks/useSetupResourceForm';
import { ResourceContentForm } from '../../common/form/ResourceContentForm';

interface Props {
  academicWork?: AcademicWorkDataFragment;
}

export const AcademicWorkForm = ({ academicWork }: Props): JSX.Element => {
  const { t } = useTranslation();

  const formTitle = academicWork
    ? t('academicWorks.form.title.edit')
    : t('academicWorks.form.title.new');

  const additionalInitialValues = {
    externalLink: academicWork?.externalLink ?? '',
  };

  const validationSchema = Yup.object().shape({
    authors: yupAuthorsSchema(),
    externalLink: yupLinkSchema(),
    content: yupContentSchema('text', t),
    originalLanguage: yupOriginalLanguageSchema(),
    tagIds: yupTagIdsSchema(),
  });

  const [createMutation] = useCreateAcademicWorkMutation();
  const [updateMutation] = useUpdateAcademicWorkMutation();

  const [form, handleSubmit, handleReset, errorMessage] = useSetupResourceForm({
    resource: academicWork,
    resourceName: 'academicWorks',
    validationSchema,
    createMutation,
    updateMutation,
    query: QUERY_ACADEMIC_WORKS,
    additionalInitialValues,
  });

  return (
    <ResourceForm
      formTitle={formTitle}
      form={form}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      metaForm={<AcademicWorkMetaForm />}
      contentForm={<ResourceContentForm contentKey="text" />}
      errorMessage={errorMessage}
    />
  );
};
