import { Box, Grid } from '@material-ui/core';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { LinksTable } from '../../../components/link/table/LinksTable';
import { SABreadcrumbs } from '../../../components/common/navigation/breadcrumbs/Breadcrumbs';
import { useLinksQuery } from '../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';

export const LinksTablePage = (): JSX.Element => {
  const { data, error, loading } = useLinksQuery();

  const links = data?.links ?? [];

  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <SABreadcrumbs translateLastPathName />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LinksTable linkResources={links} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
