import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const NoSearchResults = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        {t('phrases.noSearchResults')}
      </Typography>
    </Grid>
  );
};
