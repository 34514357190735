import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import React from 'react';

interface Props {
  name: string;
  label: string;
  defaultValue?: string;
  required?: boolean;
  variant?: 'outlined' | 'standard';
  multiline?: boolean;
  type?: 'text' | 'number';
  errorData?: FieldError;
  error?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  autoComplete?: 'on' | 'off';
  translationField?: boolean;
  customOnBlur?: () => void;
  multline?: boolean;
}

export const InputField = ({
  name,
  label,
  defaultValue,
  required,
  variant,
  errorData,
  error,
  disabled = false,
  hidden = false,
  autoComplete = 'on',
  multiline = false,
  translationField,
  customOnBlur,
}: Props): JSX.Element => {
  const { control, setValue, getValues } = useFormContext();

  // Ensure that the original language input is empty
  React.useEffect(() => {
    if (translationField !== undefined && !translationField) {
      setValue(name, '');
    } else if (
      translationField !== undefined &&
      (!control.getIsDirty(name) || getValues()[name] !== '')
    ) {
      setValue(name, defaultValue);
    }
  }, [control, defaultValue, getValues, name, setValue, translationField]);

  let isError = false;
  let errorMessage = '';
  if (error) {
    isError = true;
  }
  if (errorData) {
    isError = true;
    errorMessage = errorData.message ?? '';
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => {
        const {
          field: { onBlur, ...restField },
        } = props;
        return (
          <TextField
            label={label}
            error={isError}
            fullWidth={true}
            InputLabelProps={{
              required: required || false,
            }}
            helperText={errorMessage}
            variant={variant ? variant : 'outlined'}
            disabled={disabled}
            hidden={hidden}
            autoComplete={autoComplete}
            multiline={multiline}
            rows={multiline ? 4 : 1}
            onBlur={() => {
              onBlur();
              if (customOnBlur) {
                customOnBlur();
              }
            }}
            {...restField}
          />
        );
      }}
    />
  );
};
