import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import {
  useFormContext,
  useFieldArray,
  DeepMap,
  FieldError,
} from 'react-hook-form';

import { titleCase } from '../../../common/StringUtils';
import { AuthorInput } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { SupportedForms } from './form-options';
import { InputField } from './InputField';

interface AuthorProps {
  index: number;
  label: string;
  defaultValue?: string;
  errorData: (DeepMap<AuthorInput, FieldError> | undefined)[] | undefined;
}

const AuthorField = ({
  index,
  label,
  defaultValue,
  errorData,
}: AuthorProps): JSX.Element => {
  const error = errorData ? errorData[index]?.name : undefined;

  return (
    <InputField
      name={`authors[${index}].name`}
      label={label}
      defaultValue={defaultValue}
      errorData={error}
      required={true}
    />
  );
};

const AuthorForm = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<SupportedForms>();

  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'authors',
  });

  const isLastElement = (index: number) => index + 1 !== fields.length;

  return (
    <>
      {fields.map((author, index) => (
        <Grid
          key={author.id}
          item
          container
          spacing={LayoutConstants.gridSpacing}
          alignItems="center"
        >
          <Grid item xs={8} sm={9}>
            <AuthorField
              index={index}
              label={`${titleCase(t('commons.author'))} ${index + 1}`}
              defaultValue={author.name}
              errorData={errors.authors}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={3}
            container
            spacing={LayoutConstants.gridSpacing}
          >
            {isLastElement(index) ? (
              <Grid item xs lg={6}>
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<DeleteIcon />}
                  onClick={(): void => remove(index)}
                  fullWidth
                >
                  {t('commons.delete')}
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} lg={6}>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={(): void => remove(index)}
                    disabled={fields.length === 1}
                    startIcon={<DeleteIcon />}
                    size="small"
                    fullWidth
                  >
                    {t('commons.delete')}
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(): void => insert(index + 1, { name: '' })}
                    startIcon={<AddCircle />}
                    size="small"
                    fullWidth
                  >
                    {t('commons.add')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export { AuthorForm };
