import { OutlinedTextFieldProps, TextField } from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  params: AutocompleteRenderInputParams;
}

export const TagsAutocompleteTextField = ({
  params,
  label,
  size,
  ...restProps
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TextField
      {...params}
      {...restProps}
      variant="outlined"
      label={label || t('commons.tags')}
      size={size || 'medium'}
    />
  );
};
