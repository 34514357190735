import React from 'react';
import {
  ApolloClient,
  createHttpLink,
  from,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import { cache } from './client/cache';
import { authLink } from './client/links';
import { GRAPHQL_HOST } from '../../../config';
import {
  GraphqlClientContext,
  GraphqlClientContextValue,
} from './client/GraphqlClientContext';

export function useGraphqlClient(): [
  ApolloClient<NormalizedCacheObject>,
  GraphqlClientContextValue | undefined
] {
  const context = React.useContext(GraphqlClientContext);

  if (context === undefined) {
    throw new Error(
      'useGraphqlClient must be used within a GraphqlClientContextProvider'
    );
  }

  const { setError } = context;

  const httpLink = createHttpLink({
    uri: GRAPHQL_HOST,
  });

  const retryLink = new RetryLink({
    attempts: {
      retryIf: (error, operation) => {
        return false;
      },
    },
  });

  const getErrorLink = React.useCallback(() => {
    return onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        setError(graphQLErrors[0]);
      }

      if (networkError) {
        setError(networkError);
      }
    });
  }, [setError]);

  const client = new ApolloClient({
    cache,
    link: from([getErrorLink(), retryLink, authLink, httpLink]),
  });

  return [client, context];
}
