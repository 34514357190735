import { SearchResultFragment, TagDataFragment } from '../../../graphql/models';
import { SearchResultsTagFilter } from './types';

export type SearchResultResourcesType = Omit<
  SearchResultFragment,
  '__typename' | 'executionTimeInSeconds'
>;

export const getFilteredResourcesFromAllResources = (
  resources: SearchResultResourcesType,
  tagFilter: SearchResultsTagFilter
): SearchResultResourcesType => {
  return Object.keys(resources).reduce((curr, key) => {
    const resourceType = key as keyof SearchResultResourcesType;

    curr[resourceType] = resources[resourceType].filter((resourceToFilter) =>
      filterResourcesToShow(tagFilter, resourceToFilter.tags ?? [])
    );

    return curr;
  }, {} as SearchResultResourcesType);
};

const filterResourcesToShow = (
  tagFilter: SearchResultsTagFilter,
  resourceTags?: TagDataFragment[]
): boolean => {
  return (
    // if the resource has no tags, the resource is always visible
    resourceTags?.every((tag) => tagFilter[tag.id]) ?? true
  );
};
