import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMountEffect } from '../../hooks/use-mount-effect';
import { titleCase } from '../../../../common/StringUtils';
import { SearchInput } from '../../../../graphql/models';
interface Props {
  fullPage?: boolean;
  size?: 'small' | 'medium';
  resetOnMount?: boolean;
}

export const SearchTermInputField = ({
  fullPage,
  size,
  resetOnMount,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const name = 'searchTerm';
  const { register, setValue, control } = useFormContext<SearchInput>();

  const searchTerm = useWatch({
    control,
    name,
  });

  useMountEffect(() => {
    if (resetOnMount) {
      setValue(name, '');
    }
  });

  return (
    <TextField
      {...register(name)}
      onChange={(evt): void => setValue(name, evt.target.value)}
      id="outlined-search"
      value={searchTerm}
      variant="outlined"
      label={`${titleCase(t('commons.textSearch'))}`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchTerm.length > 0 && (
              <IconButton size="small" onClick={(): void => setValue(name, '')}>
                <Clear />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      autoComplete="off"
      size={size || 'small'}
    />
  );
};
