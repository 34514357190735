import { Button, Grid } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import { BookCard } from '../../../components/book/BookCard';
import { SupportedLanguage, useBookQuery } from '../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';
import { ResourcePageCard } from '../../../components/common/resources/page/ResourcePageCard';
import {
  GenericResourceContent,
  ResourcePageContent,
} from '../../../components/common/resources/page/ResourcePageContent';
import { ResourcePageLayout } from '../../../components/common/resources/page/ResourcePageLayout';
import { useTranslation } from 'react-i18next';

export const BookPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string; lang: SupportedLanguage }>();
  const idNumber = Number(id);

  const { data, error, loading } = useBookQuery({
    variables: { id: idNumber },
  });

  if (!data || !data.book) {
    return <ApiRequestErrorAndLoadingWrapper error={error} loading={loading} />;
  }

  const content: GenericResourceContent = data.book.content.map((cnt) => ({
    language: cnt.language,
    title: cnt.title,
    body: cnt.summary,
  }));

  return (
    <ResourcePageLayout>
      <ResourcePageContent authors={data.book.authors ?? []} content={content}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            target="_blank"
            href={data.book.externalLink ?? '#'}
          >
            {t('phrases.externalLink')}
          </Button>
        </Grid>
      </ResourcePageContent>
      <ResourcePageCard>
        <BookCard book={data.book} />
      </ResourcePageCard>
    </ResourcePageLayout>
  );
};
