import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { TagsCountMapType } from '../../../../pages/app/search/SearchResultsPage';
import {
  VisibleTotalSearchResultCount,
  SearchResultsTagFilter,
  TagCategoryWithTags,
} from '../../../../pages/app/search/types';
import { SearchResultsFilterAccordionMainHeading } from './SearchResultsFilterAccordionMainHeading';
import { TagSearchResultsFilterAccordion } from './TagSearchResultsFilterAccordion';

interface Props {
  orderedCategories: TagCategoryWithTags[];
  searchResultsTagFilter: SearchResultsTagFilter;
  setSearchResultsTagFilter: (filter: SearchResultsTagFilter) => void;
  tagsCountMap: TagsCountMapType;
  countSearchResults: VisibleTotalSearchResultCount;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    textOverflow: 'ellipsis',
    maxWidth: '75%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  mainHeading: {
    padding: theme.spacing(2),
    width: '100%',
  },
  accordion: {
    width: '100%',
  },
}));

export const SearchResultsFilterAccordion = ({
  orderedCategories,
  searchResultsTagFilter,
  setSearchResultsTagFilter,
  tagsCountMap,
  countSearchResults,
}: Props): JSX.Element => {
  const classes = useStyles();

  const handleTagFilterChange =
    (tagId: number) =>
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSearchResultsTagFilter({
        ...searchResultsTagFilter,
        [tagId]: checked,
      });
    };

  return (
    <Grid item md={12} lg={9} xl={8} container>
      <SearchResultsFilterAccordionMainHeading
        className={classes.mainHeading}
        countSearchResults={countSearchResults}
      />
      {orderedCategories.map((category) => {
        return (
          <TagSearchResultsFilterAccordion
            headingClassName={classes.heading}
            accordionClassName={classes.accordion}
            category={category}
            key={category.id}
            tagsCountMap={tagsCountMap}
            handleTagFilterChange={handleTagFilterChange}
            searchResultsTagFilter={searchResultsTagFilter}
          />
        );
      })}
    </Grid>
  );
};
