import { Grid, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LinkDataFragment } from '../../graphql/models';
import { GenericCard } from '../common/layout/GenericCard';
import { ResourceCardTagsSection } from '../common/resources/card/ResourceCardTagsSection';
import { ResourceCardTranslationLinks } from '../common/resources/card/ResourceCardTranslationLinks';
import { ResourceCardOriginalLanguageSection } from '../common/resources/card/ResourceCardOriginalLanguageSection';
import { useRoutes } from '../../common/routing/routes-hook';

interface Props {
  linkResource: LinkDataFragment;
}

export const LinkCard = ({ linkResource }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { routes } = useRoutes();
  const { lang: activeLanguage } = useParams<{ lang: string }>();

  const {
    link,
    originalLanguage,
    content: contentList,
    id,
    tags,
  } = linkResource;

  const translationLinks = contentList
    .filter((content) => content.language !== originalLanguage)
    .flatMap((content) => ({
      url: routes.linkResult.pathWithArguments({
        id: id,
        lang: content.language,
      }),
      language: content.language,
    }));

  const originalLanguageLinkData = {
    url: routes.linkResult.pathWithArguments({
      id: id,
      lang: originalLanguage,
    }),
    language: originalLanguage,
  };

  const originalLanguageIsAccessible =
    contentList.map((content) => content.language).includes(originalLanguage) &&
    linkResource.originalLanguage !== activeLanguage;

  return (
    <GenericCard title={t('phrases.additionalInformation')}>
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary"> {t('commons.link')}</Typography>
        <Typography color="textPrimary">
          <Link href={link} target="_blank" rel="noreferrer">
            {link}
          </Link>
        </Typography>
      </Grid>
      <ResourceCardOriginalLanguageSection
        originalLanguageLink={originalLanguageLinkData}
        isAccessible={originalLanguageIsAccessible}
      />
      <ResourceCardTranslationLinks translationLinks={translationLinks} />
      <ResourceCardTagsSection tags={tags || []} />
    </GenericCard>
  );
};
