import { Grid } from '@material-ui/core';
import React from 'react';
import { ResourcesCountType } from '../../../pages/app/search/SearchResultsPage';
import {
  ResourceType,
  SearchResultsResources,
} from '../../../pages/app/search/types';
import { getSelectedResourceType } from '../../../pages/app/search/utils';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { NoSearchResults } from './NoSearchResults';
import { SearchResultsInfo } from './SearchResultsInfo';
import { ResultList } from './SearchResultsList';
import { SearchResultsTabs } from './SearchResultsTabs';

interface Props {
  executionTimeInSeconds: number;
  filteredResources: SearchResultsResources;
  resourcesCount: ResourcesCountType;
  countSearchResults: { visible: number; total: number };
}

export const SearchResults = ({
  countSearchResults,
  executionTimeInSeconds,
  resourcesCount,
  filteredResources,
}: Props): JSX.Element => {
  const [selectedResourceType, setSelectedResourceType] =
    React.useState<ResourceType>(() => {
      const resourceTypesWithResults = Object.entries(resourcesCount).filter(
        ([, count]) => count.visibleCount > 0
      );
      if (resourceTypesWithResults.length > 0) {
        return resourceTypesWithResults[0][0] as ResourceType;
      }
      return 'summaries';
    });

  const handleTabChange = (newIndex: number) => {
    setSelectedResourceType(getSelectedResourceType(newIndex));
  };

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <SearchResultsInfo
        totalResults={countSearchResults.total}
        visibleResults={countSearchResults.visible}
        seconds={executionTimeInSeconds}
      />
      <SearchResultsTabs
        resourcesCount={resourcesCount}
        initialTabIndex={Object.keys(resourcesCount).indexOf(
          selectedResourceType
        )}
        onTabChange={handleTabChange}
      />
      {filteredResources[selectedResourceType].length > 0 ? (
        <ResultList
          results={filteredResources[selectedResourceType]}
          resourceType={selectedResourceType}
        />
      ) : (
        <NoSearchResults />
      )}
    </Grid>
  );
};
