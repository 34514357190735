import { Grid, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { VisibleTotalSearchResultCount } from '../../../../pages/app/search/types';
import { theme } from '../../../../theme/theme';
import { TagChip } from '../../../tag/TagChip';
import {
  Description,
  HtmlTooltip,
  References,
  TagDescriptionTooltipProps,
} from '../../../tag/TagTooltipHelpers';

interface Props {
  countSearchResults: VisibleTotalSearchResultCount;
  title: string;
  titleClassName?: string;
  variant?: Variant;
  useChip?: boolean;
  color?: string;
  hoverValue?: TagDescriptionTooltipProps;
}

export const SearchResultsFilterAccordionHeading = ({
  countSearchResults,
  title,
  titleClassName,
  hoverValue,
  color,
  variant,
  useChip,
}: Props): JSX.Element => {
  const label = `${countSearchResults.visibleCount ?? 0} / ${
    countSearchResults.totalCount ?? 0
  }`;
  const hasDescription = Boolean(hoverValue?.description);
  const hasReferences = (hoverValue?.references?.length ?? 0) > 0;

  const titleElement = (
    <Typography className={titleClassName} variant={variant}>
      <>{title}</>
    </Typography>
  );

  return (
    <Grid container justify="space-between" alignItems="center">
      {hasDescription ? (
        <HtmlTooltip
          interactive
          title={
            <>
              <Description {...hoverValue} />
              {hasReferences && <References {...hoverValue} />}
            </>
          }
        >
          {titleElement}
        </HtmlTooltip>
      ) : (
        titleElement
      )}

      {useChip ? (
        <TagChip label={label} color={color ?? theme.palette.text.disabled} />
      ) : (
        <Typography variant={variant}>{label}</Typography>
      )}
    </Grid>
  );
};
