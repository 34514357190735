import { Button, Grid, Typography } from '@material-ui/core';
import { RotateLeft, Publish } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LayoutConstants } from '../../../theme/LayoutConstants';

interface Props {
  title: string;
  handleFormReset: () => void;
}

export const FormHeaderWithSubmit = ({
  title,
  handleFormReset,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const {
    formState: { dirtyFields },
  } = useFormContext();

  const hasNoFormEdits = Object.keys(dirtyFields).length === 0;

  return (
    <Grid container spacing={LayoutConstants.gridSpacing} alignItems="flex-end">
      <Grid item xs={4} sm={8}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item xs={4} sm={2}>
        <Button
          onClick={handleFormReset}
          variant="contained"
          color="default"
          startIcon={<RotateLeft />}
          fullWidth
        >
          {t('commons.reset')}
        </Button>
      </Grid>
      <Grid item xs={4} sm={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<Publish />}
          fullWidth
          disabled={hasNoFormEdits}
        >
          {t('commons.submit')}
        </Button>
      </Grid>
    </Grid>
  );
};
