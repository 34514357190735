import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  totalResults: number;
  visibleResults: number;
  seconds: number;
}

export const SearchResultsInfo = ({
  totalResults,
  visibleResults,
  seconds,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Box fontWeight="fontWeightLight" fontSize="small">
        {t('pages.public.searchResult.info', {
          totalResults,
          visibleResults,
          seconds: seconds.toFixed(3),
        })}
      </Box>
    </Grid>
  );
};
