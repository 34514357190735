import React from 'react';
import { useTranslation } from 'react-i18next';

import ProgressDialog from './graphql/ProgressDialog';

interface Props {
  error?: Error;
  loading: boolean;
}

export const ApiRequestErrorAndLoadingWrapper: React.FC<Props> = ({
  error,
  loading,
  children,
}) => {
  const { t } = useTranslation();

  if (error) {
    throw error;
  }
  if (loading) {
    return <ProgressDialog open={true} message={t('commons.loading')} />;
  }

  return <>{children}</>;
};
