import { useKeycloak } from '@react-keycloak/web';

export enum UserRole {
  public = 'public',
  editor = 'editor',
  admin = 'admin',
}

export function useHasRole(roles: UserRole[]): boolean {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    return false;
  }

  return roles.some((role) => keycloak.hasRealmRole(role));
}
