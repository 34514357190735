import {
  Tab,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ResourcesCountType } from '../../../pages/app/search/SearchResultsPage';

import { GenericTabs } from '../../common/layout/GenericTabs';
import { SearchResultsFilterAccordionHeading } from './filter-accordion/SearchResultsFilterAccordionHeading';

interface Props {
  resourcesCount: ResourcesCountType;
  initialTabIndex?: number;
  onTabChange: (tabIndex: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100% - 24px)',
      marginLeft: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    heading: {
      marginRight: theme.spacing(2),
    },
  })
);

export const SearchResultsTabs = ({
  resourcesCount,
  initialTabIndex = 0,
  onTabChange,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <GenericTabs initialValue={initialTabIndex} onTabChange={onTabChange}>
        <Tab
          label={
            <SearchResultsFilterAccordionHeading
              title={t('navigation.summaries')}
              titleClassName={classes.heading}
              useChip
              color={theme.palette.grey[100]}
              countSearchResults={{
                visibleCount: resourcesCount.summaries.visibleCount,
                totalCount: resourcesCount.summaries.totalCount,
              }}
            />
          }
          color="primary"
        />
        <Tab
          label={
            <SearchResultsFilterAccordionHeading
              title={t('navigation.articles')}
              titleClassName={classes.heading}
              useChip
              color={theme.palette.grey[100]}
              countSearchResults={{
                visibleCount: resourcesCount.articles.visibleCount,
                totalCount: resourcesCount.articles.totalCount,
              }}
            />
          }
        />
        <Tab
          label={
            <SearchResultsFilterAccordionHeading
              title={t('navigation.links')}
              titleClassName={classes.heading}
              useChip
              color={theme.palette.grey[100]}
              countSearchResults={{
                visibleCount: resourcesCount.links.visibleCount,
                totalCount: resourcesCount.links.totalCount,
              }}
            />
          }
        />
        <Tab
          label={
            <SearchResultsFilterAccordionHeading
              title={t('navigation.books')}
              titleClassName={classes.heading}
              useChip
              color={theme.palette.grey[100]}
              countSearchResults={{
                visibleCount: resourcesCount.books.visibleCount,
                totalCount: resourcesCount.books.totalCount,
              }}
            />
          }
        />
        <Tab
          label={
            <SearchResultsFilterAccordionHeading
              title={t('navigation.academicWorks')}
              titleClassName={classes.heading}
              useChip
              color={theme.palette.grey[100]}
              countSearchResults={{
                visibleCount: resourcesCount.academicWorks.visibleCount,
                totalCount: resourcesCount.academicWorks.totalCount,
              }}
            />
          }
        />
      </GenericTabs>
    </div>
  );
};
