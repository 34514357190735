import { setContext } from '@apollo/client/link/context';

import { keycloak } from '../../../../keycloak';

export const authLink = setContext(
  (_, { headers }: { headers?: Record<string, string> }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${keycloak.token ?? ''}`,
      },
    };
  }
);
