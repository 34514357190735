import { ResourceType } from './types';

export const getSelectedResourceType = (selectedTab: number): ResourceType => {
  switch (selectedTab) {
    case 0:
      return 'summaries';
    case 1:
      return 'articles';
    case 2:
      return 'links';
    case 3:
      return 'books';
    case 4:
      return 'academicWorks';
    default:
      return 'summaries';
  }
};
