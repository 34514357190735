import { Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LayoutConstants } from '../../theme/LayoutConstants';

import {
  TagCategoryReference,
  TagReference,
  useTagsQuery,
} from '../../graphql/models';
import { groupTagsByCategory } from '../../model/TagCategory';
import { ApiRequestErrorAndLoadingWrapper } from '../../components/common/ApiRequestWrapper';
import { GlossaryCategoryCard } from '../../components/glossary/GlossaryCategoryCard';

export interface GlossaryCategory {
  id: number;
  priority: number;
  name: string;
  description?: string;
  references: TagCategoryReference[];
  tags: GlossaryTag[];
}

interface GlossaryTag {
  id: number;
  name: string;
  description?: string;
  references: TagReference[];
}

export const GlossaryPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { data, loading, error } = useTagsQuery();

  const categories = groupTagsByCategory(data?.tags ?? []).sort(
    (a, b) => a.priority - b.priority
  );

  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Container maxWidth="md">
        <Grid container spacing={LayoutConstants.gridSpacing}>
          <Grid item xs={12}>
            <Typography variant="h4">{t('glossary.title')}</Typography>
            <Typography variant="body1">{t('glossary.subtitle')}</Typography>
          </Grid>
          {categories.map((category) => (
            <GlossaryCategoryCard key={category.id} category={category} />
          ))}
        </Grid>
      </Container>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
