import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { KeycloakProfile } from 'keycloak-js';

export const useCurrentUser = (): {
  userProfile?: KeycloakProfile;
  keycloak: Keycloak.KeycloakInstance;
} => {
  const { keycloak } = useKeycloak();

  const [userProfile, setUserProfile] = useState<KeycloakProfile | undefined>(
    undefined
  );

  useEffect(() => {
    const loadAndSetUserProfile = async () => {
      const profile = await keycloak.loadUserProfile();
      setUserProfile(profile);
    };
    void loadAndSetUserProfile();
  }, [keycloak]);

  return { userProfile, keycloak };
};
