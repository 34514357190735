import { Grid } from '@material-ui/core';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { TagForm } from '../../../../components/tag/form/TagForm';
import { FormBreadCrumbs } from '../../../../components/common/navigation/breadcrumbs/FormBreadcrumbs';

export const NewTagPage = (): JSX.Element => {
  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={12}>
        <FormBreadCrumbs />
      </Grid>
      <Grid item xs={12}>
        <TagForm />
      </Grid>
    </Grid>
  );
};
