import gql from 'graphql-tag';
import { TAG_FRAGMENT } from '../../fragments/tag.fragment';

export const QUERY_SUMMARIES_TABLE = gql`
  query summariesTable {
    summaries: narratives {
      id
      authors {
        name
      }
      content {
        title
        language
      }
      tags {
        ...TagData
      }
    }
  }
  ${TAG_FRAGMENT}
`;
