import { Menu as MenuIcon } from '@material-ui/icons';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  Link,
  createStyles,
  GridItemsAlignment,
  GridSize,
  GridSpacing,
  Hidden,
  Button,
} from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import { LocaleSelect } from './top-bar/TopBarLocaleDropdown';
import { UserMenuDropdown } from './top-bar/TopBarUserDropdown';
import { SITE_NAME } from '../../../config';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { SearchForm } from '../form/search/SearchForm';
import { useRoutes } from '../../../common/routing/routes-hook';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      '& .MuiToolbar-root': {
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'wrap',
        },
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(LayoutConstants.drawerBreakpoint)]: {
        display: 'none',
      },
    },
    loginButton: {
      marginRight: theme.spacing(2),
    },
    linkNoColor: {
      textDecoration: 'none',
      color: 'inherit',
    },
    search: {
      marginLeft: theme.spacing(9),
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(2),
      },
      /*
       * This element is as wide as the result container. Similar
       * to the result container, we take 2/3 of its parent's length
       * minus the result container padding, the result container parent padding
       * and the right padding of the result content container.
       */
      width: `calc(((100% - ${theme.spacing(
        2 * 3
      )}px) * 0.66667) - ${theme.spacing(2 * 4)}px - ${theme.spacing(4)}px)`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& button.searchButton': {
        width: '100%',
      },
    },
    grow: {
      flexGrow: 1,
    },
    topBarSearchField: {
      '& .MuiGrid-item': {
        '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap',
        },
      },
    },
    pages: {
      ...theme.typography.body1,
      textAlign: 'center',
      '* > &': {
        padding: theme.spacing(1),
      },
      '& a': {
        marginLeft: theme.spacing(2),
      },
    },
  })
);

interface Props {
  handleDrawerToggle?: () => void;
  isSearch?: boolean;
  showUserMenu?: boolean;
  showPageLinks?: boolean;
}

export const TopBar = ({
  handleDrawerToggle,
  isSearch,
  showUserMenu = true,
}: Props): JSX.Element => {
  const pathname = useLocation().pathname;
  const { routes } = useRoutes();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const isAdminPath = pathname.includes(routes.admin.path);

  const classes = useStyles();

  const menuPaperSize = 200;

  const searchFormProps = {
    inputSize: 'small' as const,
    gridContainerProps: {
      className: classes.topBarSearchField,
      alignItems: 'center' as GridItemsAlignment,
      spacing: 2 as GridSpacing,
    },
    gridItemProps: {
      xs: 4 as GridSize,
      lg: 5 as GridSize,
    },
    searchButtonGridProps: {
      xs: 4 as GridSize,
      lg: 2 as GridSize,
    },
  };

  return (
    <AppBar
      elevation={LayoutConstants.elevation}
      variant="outlined"
      position="fixed"
      className={classes.appBar}
      color={isAdminPath ? 'primary' : 'default'}
    >
      <Toolbar>
        <Hidden xsUp={!isAdminPath}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Typography variant="h6" noWrap>
          <RouterLink to={routes.home.path} className={classes.linkNoColor}>
            {SITE_NAME}
          </RouterLink>
        </Typography>
        {showUserMenu && (
          <div className={classes.pages}>
            <Link
              component={RouterLink}
              to={routes.aboutUs.path}
              color="inherit"
            >
              {t('aboutUs.title')}
            </Link>
            <Link
              component={RouterLink}
              to={routes.tagVisualization.path}
              color="inherit"
            >
              {t('tagVisualization.title')}
            </Link>
            <Link
              component={RouterLink}
              to={routes.glossary.path}
              color="inherit"
            >
              {t('glossary.title')}
            </Link>
          </div>
        )}
        {isSearch && (
          <div className={classes.search}>
            <SearchForm {...searchFormProps} />
          </div>
        )}
        <div className={classes.grow} />
        <div>
          <LocaleSelect menuSize={menuPaperSize} />
          {showUserMenu ? (
            <UserMenuDropdown menuSize={menuPaperSize} />
          ) : (
            <Button
              color="primary"
              onClick={() => keycloak.login()}
              variant="contained"
              className={classes.loginButton}
            >
              Login
            </Button>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};
