import { Typography } from '@material-ui/core';
import { SITE_NAME } from '../../config';

export const SiteTitle = (): JSX.Element => {
  return (
    <Typography component="h1" variant="h2" align="center" color="textPrimary">
      {SITE_NAME}
    </Typography>
  );
};
