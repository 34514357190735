import { Assignment } from '@material-ui/icons';

import { EditAcademicWorkPage } from '../../../../../pages/app/admin/academic-works/EditAcademicWorkPage';
import { NewAcademicWorkPage } from '../../../../../pages/app/admin/academic-works/NewAcademicWorkPage';
import { AcademicWorksTablePage } from '../../../../../pages/app/admin/AcademicWorksTablePage';
import { Route } from '../../../Route';

export type AcademicWorkRoutesType = {
  academicWorksNew: Route<unknown>;
  academicWorksEdit: Route<unknown>;
  academicWorks: Route<unknown>;
};

export const academicWorkRoutes = (
  parent: Route<unknown>
): AcademicWorkRoutesType => {
  const academicWorks = new Route({
    path: '/academic-works',
    component: AcademicWorksTablePage,
    icon: Assignment,
    parent,
    exact: true,
  });

  const academicWorksNew = new Route({
    path: '/new',
    component: NewAcademicWorkPage,
    parent: academicWorks,
    exact: true,
  });

  const academicWorksEdit = new Route({
    path: '/:id',
    component: EditAcademicWorkPage,
    parent: academicWorks,
    exact: true,
  });

  return { academicWorksNew, academicWorksEdit, academicWorks };
};
