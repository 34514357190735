import { TFunction, i18n } from 'i18next';
import { SupportedLanguage } from '../graphql/models';

export interface SupportedLanguageObject {
  locale: SupportedLanguage;
  text: 'english' | 'italian' | 'german';
  original: 'english' | 'italiano' | 'deutsch';
}

export const SUPPORTED_LANGUAGES_OBJECT: SupportedLanguageObject[] = [
  { locale: SupportedLanguage.En, text: 'english', original: 'english' }, // the default language
  { locale: SupportedLanguage.It, text: 'italian', original: 'italiano' },
  { locale: SupportedLanguage.De, text: 'german', original: 'deutsch' },
];

export const getTranslatedLanguage = (
  t: TFunction,
  language: SupportedLanguage
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t('languages.english');
    case SupportedLanguage.It:
      return t('languages.italian');
    case SupportedLanguage.De:
      return t('languages.german');
  }
};

export const languageCompare = (lang: string, i18n: i18n): boolean => {
  return lang === i18n.language.split('-')[0];
};

export const getSupportedLanguageFrom = (i18n: i18n): SupportedLanguage => {
  const language = i18n.language.split('-')[0];
  switch (language) {
    case 'en':
      return SupportedLanguage.En;
    case 'it':
      return SupportedLanguage.It;
    case 'de':
      return SupportedLanguage.De;
    default:
      return SupportedLanguage.En;
  }
};
