import { useKeycloak } from '@react-keycloak/web';

export const useAuthorizationHeader = (): {
  authorizationHeader: { Authorization: string };
} => {
  const { keycloak } = useKeycloak();

  const authorizationHeader = {
    Authorization: `Bearer ${keycloak.token ?? ''}`,
  };

  return { authorizationHeader };
};
