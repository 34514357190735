import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    noMarginTop: {
      marginTop: 0,
    },
    noMarginBottom: {
      marginBottom: 0,
    },
  })
);
