import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { SummaryInput } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { AuthorForm } from '../../common/form/AuthorForm';
import { OriginalLanguageSelect } from '../../common/form/OriginalLanguageSelect';
import { TagsAutocompleteInput } from '../../common/form/tags-autocomplete/TagsAutocompleteInput';
import { InputField } from '../../common/form/InputField';
import { titleCase } from '../../../common/StringUtils';

export const AcademicWorkMetaForm = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    getValues,
    formState: { errors },
  } = useFormContext<SummaryInput>();

  const values = getValues();

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={6} sm={4} lg={2}>
        <OriginalLanguageSelect
          name="originalLanguage"
          label={t('phrases.originalLanguage')}
          errorData={errors.originalLanguage}
        />
      </Grid>
      <Grid item xs={12}>
        <AuthorForm />
      </Grid>
      <Grid item xs={12}>
        <InputField
          name="externalLink"
          label={titleCase(t('commons.link'))}
          defaultValue={values.externalLink}
          required={true}
          errorData={errors.externalLink}
        />
      </Grid>
      <Grid item xs={12}>
        <TagsAutocompleteInput name="tagIds" />
      </Grid>
    </Grid>
  );
};
