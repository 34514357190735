import React from 'react';

import { Grid } from '@material-ui/core';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { AcademicWorkForm } from '../../../../components/academic-work/form/AcademicWorkForm';
import { useParams } from 'react-router-dom';
import { useAcademicWorkQuery } from '../../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../../components/common/ApiRequestWrapper';
import { FormBreadCrumbs } from '../../../../components/common/navigation/breadcrumbs/FormBreadcrumbs';

export const EditAcademicWorkPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const numberId = Number(id);

  const { data, error, loading } = useAcademicWorkQuery({
    variables: { id: numberId },
  });

  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <FormBreadCrumbs
            translateLastPathName={data?.academicWork === undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <AcademicWorkForm academicWork={data?.academicWork} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
