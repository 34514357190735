import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { ProgressBox } from '../../ProgressBox';

interface Props {
  open: boolean;
  message: string;
}

const ProgressDialog = ({ open, message }: Props): JSX.Element => (
  <Dialog open={open}>
    <DialogContent>
      <ProgressBox message={message} />
    </DialogContent>
  </Dialog>
);

export default ProgressDialog;
