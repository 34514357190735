import { Home } from '@material-ui/icons';

import { AboutUsPage } from '../../../pages/app/AboutUs';

import { SearchPage } from '../../../pages/app/Search';
import { SearchResultsPage } from '../../../pages/app/search/SearchResultsPage';
import { NotFoundPage } from '../../../pages/error/NotFoundPage';
import { Route } from '../Route';
import { adminRoutes, AdminRoutesType } from './admin/admin-routes';
import { resourceRoutes, ResourceRoutesType } from './resource-routes';
import { GlossaryPage } from '../../../pages/app/Glossary';
import { TagVisualizationPage } from '../../../pages/app/TagVisualizationPage';

export interface IndexRoutesType extends AdminRoutesType, ResourceRoutesType {
  search: Route<unknown>;
  aboutUs: Route<unknown>;
  tagVisualization: Route<unknown>;
  glossary: Route<unknown>;
  home: Route<unknown>;
  notFound: Route<unknown>;
}

export const indexRoutes = (): IndexRoutesType => {
  const home = new Route({
    icon: Home,
    component: SearchPage,
    path: '/',
    exact: true,
  });

  const search = new Route({
    component: SearchResultsPage,
    path: '/search',
    exact: true,
  });

  const aboutUs = new Route({
    component: AboutUsPage,
    path: '/about-us',
    exact: true,
  });

  const tagVisualization = new Route({
    component: TagVisualizationPage,
    path: '/tag-visualization',
    exact: true,
  });

  const glossary = new Route({
    component: GlossaryPage,
    path: '/glossary',
    exact: true,
  });

  const notFound = new Route({
    component: NotFoundPage,
    path: '*',
    exact: true,
  });

  const admin = adminRoutes();

  const resource = resourceRoutes(home);

  return {
    ...admin,
    ...resource,
    search,
    aboutUs,
    tagVisualization,
    glossary,
    home,
    notFound,
  };
};
