import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { Home, Settings } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

import {
  useHasRole,
  UserRole,
} from '../../../../../common/access-control/has-role-hook';
import { useRoutes } from '../../../../../common/routing/routes-hook';

interface Props {
  handleClose: () => void;
}

export const AdminSwitcherMenuItem = ({
  handleClose,
}: Props): JSX.Element | null => {
  const isAdminPath = useLocation().pathname.includes('admin');
  const canAccessAdmin = useHasRole([UserRole.editor]);
  const { routes } = useRoutes();

  if (!canAccessAdmin) {
    return null;
  }

  const toRoute = isAdminPath ? routes.home.path : routes.admin.path;
  const itemText = isAdminPath ? 'Home' : 'Admin';
  const icon = isAdminPath ? <Home /> : <Settings />;

  return (
    <>
      <MenuItem component={Link} to={toRoute} onClick={handleClose}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={itemText} />
      </MenuItem>
      <Divider />
    </>
  );
};
