import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  id: string;
  label: string;
  children: React.ReactNode;
  error: boolean;
  touched?: boolean;
  hide?: boolean;
}

// TODO: import styles from theme
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  content: {
    borderRadius: theme.shape.borderRadius,
    padding: '5px 14px 5px 14px',
  },
  inputLabel: {
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'translate(0, 24px) scale(1)',
  },
  error: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const LabelledOutline = ({
  id,
  label,
  children,
  error,
  hide,
  touched = true,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef<HTMLLabelElement>(null);

  React.useEffect(() => {
    const labelNode = labelRef.current;
    const labelNodeWidth = labelNode?.offsetWidth ?? 0;

    if (!hide && labelNodeWidth !== 0) {
      setLabelWidth(labelNodeWidth);
    }
  }, [hide]);

  return (
    <div style={{ position: 'relative', marginTop: '8px' }}>
      <InputLabel
        ref={labelRef}
        htmlFor={id}
        variant="outlined"
        className={`${classes.inputLabel} ${
          touched && error ? classes.error : ''
        }`}
        shrink
      >
        {label}
      </InputLabel>
      <div className={classes.root}>
        <div id={id} className={classes.content}>
          {children}
          <NotchedOutline
            notched
            labelWidth={labelWidth}
            className={`${touched && error ? classes.error : ''}`}
          />
        </div>
      </div>
    </div>
  );
};
export { LabelledOutline };
