import { Chip, ChipProps } from '@material-ui/core';
import { toColor } from 'react-color-palette';
import { getContrastYIQ } from '../../common/ColorUtils';
import {
  Description,
  HtmlTooltip,
  References,
  TagDescriptionTooltipProps,
} from './TagTooltipHelpers';

import {
  defaultSearchInput,
  useSearchDataContext,
} from '../search/SearchContext';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../common/routing/routes-hook';

interface Props {
  id?: number;
  color: string;
  cursor?: string;
  clickToSearch?: boolean;
  hoverValue?: TagDescriptionTooltipProps;
}

export const TagChip = ({
  id,
  color,
  clickToSearch = false,
  cursor,
  hoverValue,
  ...rest
}: Props & Omit<ChipProps, 'color' | 'id' | 'clickable'>): JSX.Element => {
  const hasDescription = Boolean(hoverValue?.description);
  const hasReferences = (hoverValue?.references?.length ?? 0) > 0;
  const history = useHistory();
  const { routes } = useRoutes();
  const searchPath = routes.search.path;
  const { searchInputData, setSearchInputData } = useSearchDataContext();
  const defaultValues = searchInputData || defaultSearchInput;

  const tagChip = (
    <Chip
      style={{
        backgroundColor: color,
        color: getContrastYIQ(toColor('hex', color)).hex,
        boxShadow: '0 0 1px black',
        cursor,
      }}
      size="small"
      clickable={clickToSearch}
      onClick={() => {
        if (!clickToSearch || !id) {
          return;
        }
        setSearchInputData({
          ...defaultValues,
          tagFilter: [id],
        });
        history.push(searchPath);
      }}
      {...rest}
    />
  );

  return hasDescription ? (
    <HtmlTooltip
      interactive
      title={
        <>
          <Description {...hoverValue} />
          {hasReferences && <References {...hoverValue} />}
        </>
      }
    >
      {tagChip}
    </HtmlTooltip>
  ) : (
    tagChip
  );
};
