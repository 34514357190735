import { useState } from 'react';
import { InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Color, toColor, useColor } from 'react-color-palette';

import { ColorPickerDialog } from './ColorPickerDialog';
import { ColorPreview } from './ColorPreview';
import { titleCase } from '../../../../common/StringUtils';

import 'react-color-palette/lib/css/styles.css';
interface Props {
  name: string;
  label: string;
  setValue: (color: string) => void;
  value: string;
  error?: boolean;
}

export const ColorPickerInput = ({
  error,
  label,
  name,
  value,
  setValue,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [color, setColor] = useColor('hex', value);
  const [prevColor, setPrevColor] = useColor('hex', value);

  const handleColorPickConfirmation = (confirmedColor: Color) => {
    setOpen(false);
    setNewColor(confirmedColor);
  };

  const handleColorPickCancellation = () => {
    setOpen(false);
    setColor(prevColor);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const setNewColor = (newColor: Color) => {
    setValue(newColor.hex);
    setColor(newColor);
    setPrevColor(newColor);
  };

  return (
    <>
      <InputLabel htmlFor="outlined-input">{titleCase(name)}</InputLabel>
      <OutlinedInput
        id="outlined-input"
        onBlur={(event): void =>
          setNewColor(toColor('hex', event.target.value))
        }
        onChange={(event): void => setValue(event.target.value)}
        value={value}
        label={label}
        error={error}
        startAdornment={
          <InputAdornment position="start">
            <ColorPreview
              width={32}
              height={32}
              onClick={handleClickOpen}
              color={color.hex}
            />
          </InputAdornment>
        }
      />
      <ColorPickerDialog
        color={color}
        onClose={handleColorPickCancellation}
        open={open}
        setColor={setColor}
        handleColorPickCancellation={handleColorPickCancellation}
        handleColorPickConfirmation={handleColorPickConfirmation}
      />
    </>
  );
};
