import { MenuBook } from '@material-ui/icons';

import { EditBookPage } from '../../../../../pages/app/admin/books/EditBookPage';
import { NewBookPage } from '../../../../../pages/app/admin/books/NewBookPage';
import { BooksTablePage } from '../../../../../pages/app/admin/BooksTablePage';

import { Route } from '../../../Route';

export type BookRoutesType = {
  booksNew: Route<unknown>;
  booksEdit: Route<unknown>;
  books: Route<unknown>;
};

export const bookRoutes = (parent: Route<unknown>): BookRoutesType => {
  const books = new Route({
    path: '/books',
    component: BooksTablePage,
    icon: MenuBook,
    parent,
    exact: true,
  });

  const booksNew = new Route({
    path: '/new',
    component: NewBookPage,
    parent: books,
    exact: true,
  });

  const booksEdit = new Route({
    path: '/:id',
    component: EditBookPage,
    parent: books,
    exact: true,
  });

  return { booksNew, booksEdit, books };
};
