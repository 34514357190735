import gql from 'graphql-tag';
import { ACADEMIC_WORK_FRAGMENT } from '../../fragments/academic-work.fragment';

export const QUERY_ACADEMIC_WORKS = gql`
  query academicWorks {
    academicWorks: summaries {
      ...AcademicWorkData
    }
  }
  ${ACADEMIC_WORK_FRAGMENT}
`;
