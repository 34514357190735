/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from 'i18next';
import * as Yup from 'yup';
export class ArticleValidation {
  static yupDoiSchema(dois: Array<string>, t?: TFunction) {
    const invalidDoiMessage = t
      ? t('validation.error.custom.invalidDoi')
      : 'invalid doi';

    return Yup.string()
      .trim()
      .notOneOf(dois, t ? t('validation.error.custom.duplicateDoi') : '')
      .default('')
      .test('is-valid-doi', invalidDoiMessage, (value) => {
        // https://www.crossref.org/blog/dois-and-matching-regular-expressions/
        const doiPatterns = [
          /^$/, // empty string
          /10\.\d{4,9}\/[-._;()/:a-z0-9]/, // modern crossref dois
          /10\.1002\/[^\s]+/,
          /10\.\d{4}\/\d+-\d+X?(\d+)\d+<[\d\w]+:[\d\w]*>\d+.\d+.\w+;\d/,
          /10\.1021\/\w\w\d+/,
          /10\.1207\/[\w\d]+&\d+_\d+/,
        ];

        return doiPatterns.some((pattern) => new RegExp(pattern).test(value));
      });
  }

  static yupYearSchema() {
    return Yup.number()
      .min(1900)
      .max(new Date().getFullYear() + 1)
      .required()
      .default(1900);
  }

  static yupVenueSchema() {
    return Yup.string().trim().required().default('');
  }
}
