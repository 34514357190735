import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from '@material-ui/core';
import ReactMarkdown from 'markdown-to-jsx';

import { TeamMemberData } from '../../pages/app/AboutUs';

interface Props extends Omit<TeamMemberData, 'curricula'> {
  curriculum: string;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    name: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    avatar: {
      width: '100%',
      height: '100%',
    },
  })
);

export const TeamMemberCurriculumDialog = ({
  firstName,
  lastName,
  photoUrl,
  curriculum,
  open,
  handleClose,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open} scroll="paper">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5">
              {firstName} {lastName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={photoUrl}
              variant="rounded"
              className={classes.avatar}
            />
          </Grid>
          <Grid item xs={12}>
            <ReactMarkdown
              options={{
                overrides: {
                  p: {
                    component: Typography,
                    props: {
                      variant: 'body1',
                      paragraph: true,
                      align: 'justify',
                    },
                  },
                },
              }}
            >
              {curriculum}
            </ReactMarkdown>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
