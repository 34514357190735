import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useRoutes } from '../../common/routing/routes-hook';

import { LayoutConstants } from '../../theme/LayoutConstants';

const useStyles = makeStyles((_) => ({
  container: {
    marginTop: '25vh',
  },
  gridContainer: {
    '& > *': {
      marginTop: '2rem',
    },
  },
}));

export const NotFoundPage = (): JSX.Element => {
  const classes = useStyles();
  const routerHistory = useHistory();
  const { t } = useTranslation();

  const pathname = useLocation().pathname;
  const { routes } = useRoutes();

  const handleBackButtonClick = () => routerHistory.goBack();

  const canGoBack = routerHistory.length > 0;
  const isAdminPath = pathname.startsWith(routes.admin.path);

  return (
    <Container className={classes.container}>
      <Grid
        container
        className={classes.gridContainer}
        justify="center"
        spacing={LayoutConstants.gridSpacing}
      >
        <Grid item xs={12} container justify="center">
          <Typography variant="h2">{t('404.pageNotFound')}</Typography>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Typography variant="h4">{t('404.description')}</Typography>
        </Grid>
        <Grid item xs={8} container justify="space-evenly">
          {canGoBack ? (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleBackButtonClick}
            >
              {t('404.backButton')}
            </Button>
          ) : null}

          {isAdminPath ? (
            <Button
              color="primary"
              variant="outlined"
              component={RouterLink}
              target={'_blank'}
              to={routes.admin.path}
            >
              {t('404.adminPageButton')}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="outlined"
              component={RouterLink}
              to={routes.search.path}
            >
              {t('404.searchPageButton')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
