import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Hidden, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { useRoutes } from '../../../common/routing/routes-hook';
import {
  useHasRole,
  UserRole,
} from '../../../common/access-control/has-role-hook';
import { SideBarFooter } from './SideBarFooter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up(LayoutConstants.drawerBreakpoint)]: {
        width: LayoutConstants.drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: LayoutConstants.drawerWidth,
      background: theme.palette.background.paper,
    },
    drawerContainer: {
      paddingTop: LayoutConstants.toolbarHeight,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    userList: {
      flexGrow: 1,
    },
  })
);

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const ResponsiveDrawer = ({
  mobileOpen,
  handleDrawerToggle,
}: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { routes } = useRoutes();
  const { t } = useTranslation();

  const canEditUsers = useHasRole([UserRole.admin]);

  const drawer = (
    <div className={classes.drawerContainer}>
      <List>
        <ListItem button component={RouterLink} to={routes.admin.path}>
          <ListItemIcon>
            {routes.admin.icon && <routes.admin.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.admin')} />
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem button component={RouterLink} to={routes.articles.path}>
          <ListItemIcon>
            {routes.articles.icon && <routes.articles.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.articles')} />
        </ListItem>

        <ListItem button component={RouterLink} to={routes.summaries.path}>
          <ListItemIcon>
            {routes.summaries.icon && <routes.summaries.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.summaries')} />
        </ListItem>

        <ListItem button component={RouterLink} to={routes.links.path}>
          <ListItemIcon>
            {routes.links.icon && <routes.links.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.links')} />
        </ListItem>

        <ListItem button component={RouterLink} to={routes.books.path}>
          <ListItemIcon>
            {routes.books.icon && <routes.books.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.books')} />
        </ListItem>

        <ListItem button component={RouterLink} to={routes.academicWorks.path}>
          <ListItemIcon>
            {routes.academicWorks.icon && <routes.academicWorks.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.academicWorks')} />
        </ListItem>

        <Divider />

        <ListItem button component={RouterLink} to={routes.tagCategories.path}>
          <ListItemIcon>
            {routes.tagCategories.icon && <routes.tagCategories.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.tagCategories')} />
        </ListItem>

        <ListItem button component={RouterLink} to={routes.tags.path}>
          <ListItemIcon>
            {routes.tags.icon && <routes.tags.icon />}
          </ListItemIcon>
          <ListItemText primary={t('navigation.tags')} />
        </ListItem>
      </List>

      {canEditUsers ? (
        <>
          <Divider />

          <List className={classes.userList}>
            <ListItem button component={RouterLink} to={routes.users.path}>
              <ListItemIcon>
                {routes.users.icon && <routes.users.icon />}
              </ListItemIcon>
              <ListItemText primary={t('navigation.users')} />
            </ListItem>
          </List>
        </>
      ) : (
        <div className={classes.userList} />
      )}

      <SideBarFooter />
    </div>
  );

  return (
    <div className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
};

export { ResponsiveDrawer };
