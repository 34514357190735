import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { SUPPORTED_LANGUAGES_OBJECT } from '../../../common/supported-languages';
import { TagCategoryInput } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { InputField } from '../../common/form/InputField';
import {
  translatedDescriptionLabel,
  translatedNameLabel,
} from '../../../model/Tag';
import { ContentErrors, Optional } from '../../../common/types';

export const TagCategoryContentForm = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<TagCategoryInput>();

  const languages = SUPPORTED_LANGUAGES_OBJECT;

  const individualContentErrors = errors.content as Optional<ContentErrors>;
  const generalContentErrors = errors.content as Optional<{ message: string }>;

  const generalContentErrorMessage =
    !generalContentErrors || Array.isArray(generalContentErrors)
      ? ''
      : generalContentErrors.message;

  const { fields } = useFieldArray({
    control,
    name: 'content',
  });

  const customOnBlur = () => {
    void trigger('content');
  };

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      {/* Hidden Language Input Fields */}
      {fields.map((content, index) => (
        <input
          type="hidden"
          defaultValue={content.language}
          key={content.language}
          {...register(`content.${index}.language` as const)}
        />
      ))}
      {/* Name */}
      <Grid item>
        <Typography variant="h6">{t('commons.form.name')}</Typography>
      </Grid>
      <Grid container item spacing={LayoutConstants.gridSpacing}>
        {fields.map((content, index) => {
          const individualError = individualContentErrors?.[index]?.name;

          return (
            <Grid item xs={4} key={content.name}>
              <InputField
                {...register(`content.${index}.name` as const)}
                label={translatedNameLabel(languages[index].locale, t)}
                error={Boolean(individualError ?? generalContentErrorMessage)}
                defaultValue={content.name}
                customOnBlur={customOnBlur}
              />
              <FormHelperText error>
                {individualError?.message ?? ''}
              </FormHelperText>
            </Grid>
          );
        })}
      </Grid>

      {/* Description */}
      <Grid item>
        <Typography variant="h6">{t('commons.form.description')}</Typography>
      </Grid>
      <Grid container item spacing={LayoutConstants.gridSpacing}>
        {fields.map((content, index) => {
          const individualError =
            individualContentErrors?.[index]?.description ?? '';

          return (
            <Grid item xs={4} key={content.id}>
              <InputField
                {...register(`content.${index}.description` as const)}
                label={translatedDescriptionLabel(languages[index].locale, t)}
                error={Boolean(individualError || generalContentErrorMessage)}
                defaultValue={content.description ?? ''}
                customOnBlur={customOnBlur}
                multiline
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12} style={{ paddingTop: '0px' }}>
        <FormHelperText error>{generalContentErrorMessage}</FormHelperText>
      </Grid>
    </Grid>
  );
};
