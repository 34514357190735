import { MUIDataTableOptions } from 'mui-datatables';
import { LayoutConstants } from '../../../theme/LayoutConstants';

export const tableOptions: MUIDataTableOptions = {
  filter: true,
  print: false,
  download: false,
  search: true,
  elevation: LayoutConstants.elevation,
  pagination: false,
  fixedHeader: true,
};
