import React from 'react';
import { useTranslation } from 'react-i18next';
import { VisibleTotalSearchResultCount } from '../../../../pages/app/search/types';
import { GenericAccordion } from '../../../common/layout/GenericAccordion';
import { SearchResultsFilterAccordionHeading } from './SearchResultsFilterAccordionHeading';

interface Props {
  className: string;
  countSearchResults: VisibleTotalSearchResultCount;
}

export const SearchResultsFilterAccordionMainHeading = ({
  className,
  countSearchResults,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <GenericAccordion className={className}>
      <SearchResultsFilterAccordionHeading
        variant="h6"
        title={t('pages.public.searchResult.resultsCard.title')}
        countSearchResults={countSearchResults}
      />
    </GenericAccordion>
  );
};
