import { useParams } from 'react-router-dom';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';
import { LinkCard } from '../../../components/link/LinkCard';
import { SupportedLanguage, useLinkQuery } from '../../../graphql/models';
import { ResourcePageCard } from '../../../components/common/resources/page/ResourcePageCard';
import {
  GenericResourceContent,
  ResourcePageContent,
} from '../../../components/common/resources/page/ResourcePageContent';
import { ResourcePageLayout } from '../../../components/common/resources/page/ResourcePageLayout';
import { Grid, Link, Typography } from '@material-ui/core';

export const LinkPage = (): JSX.Element => {
  const { id } = useParams<{ id: string; lang: SupportedLanguage }>();
  const idNumber = Number(id);

  const { data, error, loading } = useLinkQuery({
    variables: { id: idNumber },
  });

  if (!data) {
    return <ApiRequestErrorAndLoadingWrapper error={error} loading={loading} />;
  }

  const content: GenericResourceContent = data.link.content.map((cnt) => ({
    language: cnt.language,
    title: cnt.title,
    body: cnt.description,
  }));

  return (
    <ResourcePageLayout>
      <ResourcePageContent
        authors={data.link.authors ?? []}
        beforeBody
        content={content}
      >
        <Grid item xs={12}>
          <Typography variant="body2">
            <Link href={data.link.link} target="_blank" rel="noreferrer">
              {data.link.link}
            </Link>
          </Typography>
        </Grid>
      </ResourcePageContent>
      <ResourcePageCard>
        <LinkCard linkResource={data.link} />
      </ResourcePageCard>
    </ResourcePageLayout>
  );
};
