import { Grid } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { titleCase } from '../../../common/StringUtils';
import { VideoLinkInput } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { AuthorForm } from '../../common/form/AuthorForm';
import { InputField } from '../../common/form/InputField';
import { OriginalLanguageSelect } from '../../common/form/OriginalLanguageSelect';
import { TagsAutocompleteInput } from '../../common/form/tags-autocomplete/TagsAutocompleteInput';

export const LinkMetaForm = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    getValues,
    formState: { errors },
  } = useFormContext<VideoLinkInput>();

  const { link } = getValues();

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={6} sm={4} lg={2}>
        <OriginalLanguageSelect
          name="originalLanguage"
          label={t('phrases.originalLanguage')}
          errorData={errors.originalLanguage}
        />
      </Grid>
      <Grid item xs={12}>
        <AuthorForm />
      </Grid>
      <Grid item xs={12}>
        <InputField
          name="link"
          label={titleCase(t('commons.link'))}
          defaultValue={link}
          multiline
          required={true}
          errorData={errors.link}
        />
      </Grid>
      <Grid item xs={12}>
        <TagsAutocompleteInput name="tagIds" />
      </Grid>
    </Grid>
  );
};
