import { AutocompleteGetTagProps } from '@material-ui/lab';
import { sortOutOfPlace } from '../../../../common/ListUtils';
import { TagDataFragment } from '../../../../graphql/models';
import { TagChip } from '../../../tag/TagChip';
import { TagDescriptionTooltipProps } from '../../../tag/TagTooltipHelpers';

interface Props {
  selectedTags: TagDataFragment[];
  getTagProps: AutocompleteGetTagProps;
  getTagName: (tag: TagDataFragment) => string;
  getTagDescription: (tag: TagDataFragment) => TagDescriptionTooltipProps;
  getTagId: (tag: TagDataFragment) => number;
  className?: string;
}

export const TagsAutocompleteTaglist = ({
  selectedTags,
  getTagId,
  getTagProps,
  getTagName,
  getTagDescription,
  className,
}: Props): JSX.Element => {
  return (
    <div className={className}>
      {sortOutOfPlace(
        selectedTags,
        (a, b) => a.category.priority - b.category.priority
      ).map((option, index) => {
        return (
          <TagChip
            label={getTagName(option)}
            hoverValue={getTagDescription(option)}
            color={option.category.color}
            {...getTagProps({ index })}
          />
        );
      })}
    </div>
  );
};
