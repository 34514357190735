import { Button, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BookDataFragment } from '../../graphql/models';
import { GenericCard } from '../common/layout/GenericCard';
import { ResourceCardTagsSection } from '../common/resources/card/ResourceCardTagsSection';
import { ResourceCardTranslationLinks } from '../common/resources/card/ResourceCardTranslationLinks';
import { ResourceCardOriginalLanguageSection } from '../common/resources/card/ResourceCardOriginalLanguageSection';
import { useRoutes } from '../../common/routing/routes-hook';

interface Props {
  book: BookDataFragment;
}

export const BookCard = ({ book }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { routes } = useRoutes();

  const { lang: activeLanguage } = useParams<{ lang: string }>();

  const {
    content: contentList,
    originalLanguage,
    year,
    ISBN,
    id,
    publisher,
    externalLink,
    tags,
  } = book;

  const translationLinks = contentList
    .filter((content) => content.language !== book.originalLanguage)
    .flatMap((content) => ({
      url: routes.bookResult.pathWithArguments({
        id: book.id,
        lang: content.language,
      }),
      language: content.language,
    }));

  const originalLanguageLinkData = {
    url: routes.bookResult.pathWithArguments({
      id: id,
      lang: originalLanguage,
    }),
    language: originalLanguage,
  };

  const originalLanguageIsAccessible =
    contentList
      .map((content) => content.language)
      .includes(book.originalLanguage) &&
    book.originalLanguage !== activeLanguage;

  return (
    <GenericCard title={t('phrases.additionalInformation')}>
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary">{t('commons.year')}</Typography>
        <Typography color="textPrimary">{year}</Typography>
      </Grid>
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary">ISBN</Typography>
        <Typography color="textPrimary"> {ISBN}</Typography>
      </Grid>
      <ResourceCardOriginalLanguageSection
        originalLanguageLink={originalLanguageLinkData}
        isAccessible={originalLanguageIsAccessible}
      />
      <ResourceCardTranslationLinks translationLinks={translationLinks} />
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary">{t('commons.publisher')}</Typography>
        <Typography color="textPrimary">{publisher}</Typography>
      </Grid>
      <Grid container item xs={12} justify="space-between" alignItems="center">
        <Typography color="textSecondary">Link</Typography>
        <Button href={externalLink} target="_blank">
          {t('phrases.link')}
        </Button>
      </Grid>
      <ResourceCardTagsSection tags={tags || []} />
    </GenericCard>
  );
};
