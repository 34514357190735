import {
  DraftEntityMutability,
  DraftEntityType,
  RawDraftEntity,
} from 'draft-js';

export interface LinkData {
  href: string;
  target: string;
}

export const getTargetFromLinkType = (isInternal: boolean): string => {
  return isInternal ? '_self' : '_blank';
};

export const isInternalLinkType = (target: string): boolean => {
  return target === '_self';
};

export const customEntityTransform = (
  entity: RawDraftEntity<LinkData>,
  text: string
): string | undefined => {
  if (entity.type !== ENTITY_TYPE.LINK) {
    return;
  }

  return `<a href="${entity.data.href}" target="${entity.data.target}">${text}</a>`;
};

export const ENTITY_TYPE: Record<DraftEntityType, DraftEntityType> = {
  LINK: 'LINK',
  TOKEN: 'TOKEN',
  PHOTO: 'PHOTO',
  IMAGE: 'IMAGE',
};

export const ENTITY_MUTABILITY: Record<
  DraftEntityMutability,
  DraftEntityMutability
> = {
  MUTABLE: 'MUTABLE',
  IMMUTABLE: 'IMMUTABLE',
  SEGMENTED: 'SEGMENTED',
};
