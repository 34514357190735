import { FormHelperText, FormControl } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TagCategoryInput } from '../../../graphql/models';
import { ColorPickerInput } from '../../common/form/color-picker/ColorPickerInput';

export const TagCategoryColorFormField = (): JSX.Element => {
  const { t } = useTranslation();

  const name = 'color';
  const label = t('commons.color');

  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext<TagCategoryInput>();

  const setFormValue = (color: string) => {
    setValue(name, color, { shouldDirty: true });
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Controller
        control={control}
        name={name}
        render={({ field: { name: fieldName, value } }) => (
          <ColorPickerInput
            name={fieldName}
            label={label}
            error={Boolean(errors.color)}
            setValue={setFormValue}
            value={value}
          />
        )}
      />
      {errors.color ? (
        <FormHelperText id="my-helper-text" error>
          {errors.color.message}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};
