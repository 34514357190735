import { Grid, useTheme } from '@material-ui/core';
import { Category } from '../data/network.type';
import { legendContainerStyle } from '../../styles';
import { TagLegendItem } from './TagLegendItem';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

interface TagLegendProps {
  categories: Category[];
  setCategoryLegendState: (categories: Category[]) => void;
  showLegend: boolean;
}

export const TagLegend = ({
  categories,
  setCategoryLegendState,
  showLegend,
}: TagLegendProps): JSX.Element => {
  const theme = useTheme();

  const onClick = (categoryToUpdate: Category) => {
    const updatedShowValue = !categoryToUpdate.show;
    // at least two categories should be visible
    const nextShowCategoriesCount = updatedShowValue ? 2 : 3;
    if (categories.filter((cat) => cat.show).length < nextShowCategoriesCount) {
      return;
    }

    const categoryIndex = categories.findIndex(
      (cat) => cat.id === categoryToUpdate.id
    );
    const newCategories = [...categories];
    newCategories[categoryIndex] = {
      ...categoryToUpdate,
      show: updatedShowValue,
    };
    setCategoryLegendState(newCategories);
  };

  const reorderCategories = (
    categories: Array<Category>,
    startIndex: number,
    endIndex: number
  ) => {
    const categoriesCopy = [...categories];
    const [removed] = categoriesCopy.splice(startIndex, 1);
    categoriesCopy.splice(endIndex, 0, removed);

    return categoriesCopy.map((category, index) => ({
      ...category,
      priority: index + 1,
    }));
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderCategories(
      categories,
      result.source.index,
      result.destination.index
    );

    setCategoryLegendState(items);
  };

  return showLegend ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <Grid
            style={legendContainerStyle(theme, snapshot.isDraggingOver)}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {categories.map((category, index) => {
              return (
                <TagLegendItem
                  key={category.id}
                  category={category}
                  onClick={() => onClick(category)}
                  provided={provided}
                  snapshot={snapshot}
                  index={index}
                />
              );
            })}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  ) : (
    <></>
  );
};
