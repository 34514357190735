import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';
import { LayoutConstants } from '../../../theme/LayoutConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    '& button': {
      ...theme.typography.body1,
    },
  },
  content: {
    wordWrap: 'break-word',
  },
}));

interface Props {
  title: string;
  children: React.ReactNode;
  titleVariant?: Variant;
}

export const GenericCard = ({
  title,
  children,
  titleVariant,
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
      elevation={LayoutConstants.elevation}
      variant="outlined"
    >
      <CardContent className={classes.content}>
        <Typography variant={titleVariant || 'h6'} gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={1}>
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};
