import gql from 'graphql-tag';

export const QUERY_ARTICLE_DOIS = gql`
  query articleDois {
    articles: publications {
      id
      doi
    }
  }
`;
