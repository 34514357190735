import { Link } from '@material-ui/icons';

import { EditLinkPage } from '../../../../../pages/app/admin/links/EditLinkPage';
import { NewLinkPage } from '../../../../../pages/app/admin/links/NewLinkPage';
import { LinksTablePage } from '../../../../../pages/app/admin/LinksTablePage';
import { Route } from '../../../Route';

export type LinkRoutesType = {
  linksNew: Route<unknown>;
  linksEdit: Route<unknown>;
  links: Route<unknown>;
};

export const linkRoutes = (parent: Route<unknown>): LinkRoutesType => {
  const links = new Route({
    path: '/links',
    component: LinksTablePage,
    icon: Link,
    parent,
    exact: true,
  });

  const linksNew = new Route({
    path: '/new',
    component: NewLinkPage,
    parent: links,
    exact: true,
  });

  const linksEdit = new Route({
    path: '/:id',
    component: EditLinkPage,
    parent: links,
    exact: true,
  });

  return {
    linksNew,
    linksEdit,
    links,
  };
};
