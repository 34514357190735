import React from 'react';
import Tab from '@material-ui/core/Tab';
import { GenericTabs } from '../layout/GenericTabs';
import {
  getTranslatedLanguage,
  SUPPORTED_LANGUAGES_OBJECT,
} from '../../../common/supported-languages';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from '../../../graphql/models';

interface Props {
  value: number;
  setValue: (value: number) => void;
  originalLanguage: SupportedLanguage;
}

const LanguageTabs = ({
  value,
  setValue,
  originalLanguage,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const handleChange = (newIndex: number): void => {
    setValue(newIndex);
  };

  const requiredStar = <sup style={{ position: 'absolute' }}>*</sup>;

  const getLabel = (
    language: SupportedLanguage,
    isOriginalLanguage: boolean
  ) => {
    return (
      <span>
        {getTranslatedLanguage(t, language)}
        {isOriginalLanguage && requiredStar}
      </span>
    );
  };

  return (
    <GenericTabs initialValue={value} onTabChange={handleChange}>
      {SUPPORTED_LANGUAGES_OBJECT.map((language, index) => (
        <Tab
          key={index}
          label={getLabel(
            language.locale,
            originalLanguage === language.locale
          )}
          aria-controls={`a11y-tabpanel-${index}`}
          id={`a11y-tab-${index}`}
        />
      ))}
    </GenericTabs>
  );
};

export { LanguageTabs };
