import {
  Box,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Category } from '../data/network.type';
import { draggableItemStyle, legendItemStyle } from '../../styles';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';

interface TagLegendItemProps {
  key: number;
  category: Category;
  onClick: () => void;
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
  index: number;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    item: {
      width: '100%',
      '&:hover': {
        backgroundColor: _theme.palette.grey[300],
      },
    },
  })
);

export const TagLegendItem = ({
  category,
  onClick,
  index,
  provided,
  snapshot,
}: TagLegendItemProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  const isActive = category.show;

  return (
    <Draggable
      key={category.id}
      draggableId={category.id.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={draggableItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            theme
          )}
        >
          <Grid
            item
            container
            alignItems="center"
            wrap="nowrap"
            onClick={onClick}
            className={classes.item}
          >
            <DragIndicatorIcon style={{ marginRight: '4px' }} />
            <Box
              style={legendItemStyle(theme, category.itemStyle.color, isActive)}
            />
            <Typography
              variant="body2"
              style={{ color: isActive ? 'initial' : theme.palette.grey[500] }}
            >
              {category.name}
            </Typography>
          </Grid>
        </div>
      )}
    </Draggable>
  );
};
