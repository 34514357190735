import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import React from 'react';
import { Color, ColorPicker } from 'react-color-palette';
import { useTranslation } from 'react-i18next';

interface Props {
  color: Color;
  onClose: (color: Color) => void;
  open: boolean;
  setColor: React.Dispatch<React.SetStateAction<Color>>;
  handleColorPickConfirmation: (color: Color) => void;
  handleColorPickCancellation: () => void;
}

export const ColorPickerDialog = ({
  color,
  onClose,
  open,
  setColor,
  handleColorPickCancellation,
  handleColorPickConfirmation,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose(color);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogContent>
        <ColorPicker width={300} color={color} onChange={setColor} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleColorPickCancellation} color="primary">
          {t('dialog.color.cancel')}
        </Button>
        <Button
          onClick={() => handleColorPickConfirmation(color)}
          color="primary"
          variant="contained"
        >
          {t('dialog.color.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
