import gql from 'graphql-tag';
import { AUTHOR_FRAGMENT } from './author.fragment';
import { TAG_FRAGMENT } from './tag.fragment';

export const BOOK_FRAGMENT = gql`
  fragment BookData on Book {
    id
    ISBN
    externalLink
    originalLanguage
    publisher
    year
    authors {
      ...AuthorData
    }
    content {
      summary
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AUTHOR_FRAGMENT}
  ${TAG_FRAGMENT}
`;
