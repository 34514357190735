import { Container, Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { TeamMember } from '../../components/about-us/TeamMember';
import { LayoutConstants } from '../../theme/LayoutConstants';

import { SupportedLanguage } from '../../graphql/models';
import { useTeamMembers } from './about-us/hooks/team-members-hook';

export interface TeamMemberName {
  firstName: string;
  lastName: string;
}
export interface TeamMemberData extends TeamMemberName {
  photoUrl: string;
  curricula: Map<SupportedLanguage, string>;
}

const teamMemberNames: TeamMemberName[] = [
  { firstName: 'Andreas', lastName: 'Cincera' },
  { firstName: 'Anna', lastName: 'Modesti' },
  { firstName: 'Antonello', lastName: 'Farulli' },
  { firstName: 'Cristina', lastName: 'Bellu' },
  { firstName: 'Hubert', lastName: 'Eiholzer' },
];

const collaboratorNames: TeamMemberName[] = [
  { firstName: 'Terje', lastName: 'Moe Hansen' },
];

export const AboutUsPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { teamMembers } = useTeamMembers(teamMemberNames);
  const { teamMembers: collaborators } = useTeamMembers(collaboratorNames);

  return (
    <Container maxWidth="md">
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Typography variant="h4">{t('aboutUs.title')}</Typography>
          <Typography variant="body1">{t('aboutUs.subtitle')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">{t('aboutUs.team.title')}</Typography>
        </Grid>
        {teamMembers.map((teamMember) => (
          <TeamMember
            key={`${teamMember.firstName}-${teamMember.lastName}`}
            teamMember={teamMember}
          />
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            {t('aboutUs.contributors.title')}
          </Typography>
        </Grid>
        {collaborators.map((collaborator) => (
          <TeamMember
            key={`${collaborator.firstName}-${collaborator.lastName}`}
            teamMember={collaborator}
          />
        ))}
      </Grid>
    </Container>
  );
};
