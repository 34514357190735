import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SupportedLanguage, useTagsQuery } from '../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../common/ApiRequestWrapper';

import { TagNetworkBuilder } from './tag-visualization/data/TagNetworkBuilder';
import { Category, NetworkData } from './tag-visualization/data/network.type';
import { ChartState, TagGraphChart } from './tag-visualization/TagGraphChart';
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
} from '@material-ui/core';
import { GenericPaper } from '../common/layout/GenericPaper';
import {
  buildCategoryIdToUpdatedCategories,
  buildTagDataWithUpdatedCategories,
  collectSortedCategoriesFromTags,
} from './tag-visualization/data/tag-category-utils';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  tagVisualizationContainer: {
    flex: 1,
  },
  chartStateContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  formControlLabel: {
    marginLeft: 0,
  },
}));

export const TagVisualization = (): JSX.Element => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const { data: originalData, loading, error } = useTagsQuery();

  const [chartState, setChartState] = useState<ChartState>({
    showLabels: true,
    showLegend: true,
  });

  const [networkData, setNetworkData] = useState<NetworkData | undefined>(
    undefined
  );

  const [categoryLegendState, setCategoryLegendState] = useState<Category[]>(
    collectSortedCategoriesFromTags(
      originalData?.tags ?? [],
      i18n.language as SupportedLanguage
    )
  );

  React.useEffect(() => {
    const updatedCategories = collectSortedCategoriesFromTags(
      originalData?.tags ?? [],
      i18n.language as SupportedLanguage
    );
    setCategoryLegendState(updatedCategories);
  }, [originalData, i18n.language]);

  React.useEffect(() => {
    const tagNetwork = new TagNetworkBuilder();

    if (originalData && categoryLegendState.length > 0) {
      const tagCategoryIdToUpdatedTagCategory =
        buildCategoryIdToUpdatedCategories(categoryLegendState);

      const dataWithUpdatedCategories = buildTagDataWithUpdatedCategories(
        originalData,
        tagCategoryIdToUpdatedTagCategory
      );

      const updatedNetworkData = tagNetwork.buildNetworkData(
        dataWithUpdatedCategories,
        tagCategoryIdToUpdatedTagCategory,
        i18n.language as SupportedLanguage
      );

      setNetworkData(updatedNetworkData);
    }
  }, [originalData, categoryLegendState, i18n.language]);

  if (!networkData || loading || error) {
    return <ApiRequestErrorAndLoadingWrapper loading={loading} error={error} />;
  }

  const handleChartStateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setChartState({ ...chartState, [event.target.name]: event.target.checked });
  };

  return (
    <GenericPaper className={classes.paperRoot}>
      <div className={classes.tagVisualizationContainer}>
        <TagGraphChart
          data={networkData}
          chartState={chartState}
          categoriesLegendState={categoryLegendState}
          setCategoriesLegendState={setCategoryLegendState}
        />
      </div>
      <div className={classes.chartStateContainer}>
        <FormGroup row>
          <FormControlLabel
            label={t('dashboard.chartState.showLabels')}
            className={classes.formControlLabel}
            control={
              <Switch
                name="showLabels"
                size="small"
                checked={chartState.showLabels}
                onChange={handleChartStateChange}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label={t('dashboard.chartState.showLegend')}
            className={classes.formControlLabel}
            control={
              <Switch
                name="showLegend"
                size="small"
                checked={chartState.showLegend}
                onChange={handleChartStateChange}
                color="primary"
              />
            }
          />
        </FormGroup>
      </div>
    </GenericPaper>
  );
};
