import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Avatar,
} from '@material-ui/core';

import { TeamMemberData } from '../../pages/app/AboutUs';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from '../../graphql/models';
import { useState } from 'react';
import { TeamMemberCurriculumDialog } from './TeamMemberCurriculumDialog';

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    },
  })
);

interface Props {
  teamMember: TeamMemberData;
}

export const TeamMember = ({ teamMember }: Props): JSX.Element => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const [showCurriculum, setShowCurriculum] = useState(false);

  const { firstName, lastName, photoUrl, curricula: curriculum } = teamMember;

  const currentLanguage = i18n.language.split('-')[0] as SupportedLanguage;

  const curriculumString =
    curriculum.get(currentLanguage) ??
    // get the english curriculum if the current language is not available
    curriculum.get(SupportedLanguage.En) ??
    '';

  return (
    <Grid item xs={4} container>
      <Grid item xs={12} onClick={() => setShowCurriculum(true)}>
        <Avatar
          alt={`${firstName} ${lastName}`}
          src={photoUrl}
          variant="rounded"
          className={classes.avatar}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">
          {firstName} {lastName}
        </Typography>
      </Grid>
      <TeamMemberCurriculumDialog
        open={showCurriculum}
        handleClose={() => setShowCurriculum(false)}
        curriculum={curriculumString}
        {...teamMember}
      />
    </Grid>
  );
};
