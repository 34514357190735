import { Card, CardContent, Grid, useTheme } from '@material-ui/core';
import {
  TagCategoryWithTags,
  getTagCategoryDescription,
} from '../../model/TagCategory';
import { useTranslation } from 'react-i18next';
import { ColorPreview } from '../common/form/color-picker/ColorPreview';
import { GlossaryTags } from './GlossaryTags';
import { Description, HtmlTooltip, References } from '../tag/TagTooltipHelpers';
import { TagCategoryHeader } from './glossary-category-card/TagCategoryHeader';

interface Props {
  category: TagCategoryWithTags;
}

export const GlossaryCategoryCard = ({ category }: Props): JSX.Element => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();

  const hoverValue = getTagCategoryDescription(category, i18n, t);
  const hasDescription = Boolean(hoverValue?.description);
  const hasReferences = (hoverValue?.references?.length ?? 0) > 0;

  return (
    <Card
      style={{
        backgroundColor: theme.palette.background.default,
        width: '100%',
        marginTop: '40px',
      }}
    >
      <CardContent>
        <Grid container alignItems="center">
          <ColorPreview
            color={category.color}
            width={25}
            height={25}
            margin="10px 10px 10px 0"
          />
          {hasDescription ? (
            <HtmlTooltip
              interactive
              title={
                <>
                  <Description {...hoverValue} />
                  {hasReferences && <References {...hoverValue} />}
                </>
              }
            >
              {/* The header doesn't render as expected so function call is necessary. Should be investigated. */}
              {TagCategoryHeader({ category })}
            </HtmlTooltip>
          ) : (
            <TagCategoryHeader category={category} />
          )}
        </Grid>
        <GlossaryTags tags={category.tags} />
      </CardContent>
    </Card>
  );
};
