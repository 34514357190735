import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';
import { ContentState } from 'draft-js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.info.main,
    },
  })
);

interface DecoratorLinkProps {
  contentState: ContentState;
  entityKey: string;
  children: React.ReactNode;
}

export const TextEditorLinkDecoratorComponent = ({
  contentState,
  entityKey,
  children,
}: DecoratorLinkProps): JSX.Element => {
  const { url, href } = contentState.getEntity(entityKey).getData() as {
    url: string;
    href: string;
  };
  const classes = useStyles();
  return (
    <MuiLink href={url || href} target="_blank" className={classes.link}>
      {children}
    </MuiLink>
  );
};
