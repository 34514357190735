import { TFunction } from 'i18next';
interface LocaleObject {
  mixed: {
    default: string;
    required: string;
    notType: (params: { type: 'number' }) => string;
  };
  number: {
    min: (value: { min: number }) => string;
    max: (value: { max: number }) => string;
  };
}

/**
 * see https://github.com/jquense/yup/blob/8f2bd2b1c33615e7e68b90796d0bf6619fc7c51c/src/locale.js
 *
 * @param t TFunction
 */
export const buildYupLocaleObject = (t: TFunction): LocaleObject => ({
  mixed: {
    default: t('validation.error.mixed.default'),
    required: t('validation.error.mixed.required'),
    notType: (params): string => {
      switch (params.type) {
        case 'number':
          return t('validation.error.mixed.notNumber');
        default:
          return t('validation.error.mixed.notTypeDefault');
      }
    },
  },
  number: {
    min: ({ min }): string => t('validation.error.number.min', { min }),
    max: ({ max }): string => t('validation.error.number.max', { max }),
  },
});
