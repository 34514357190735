import { Button, Popover } from '@material-ui/core';
import { EditorState, RichUtils } from 'draft-js';
import React, { Fragment } from 'react';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation } from 'react-i18next';

import { TextEditorLinkInput } from './TextEditorLinkInput';
import {
  ENTITY_MUTABILITY,
  ENTITY_TYPE,
  getTargetFromLinkType,
  LinkData,
} from '../commons';

interface Props {
  editorState: EditorState;
  setEditorState: (value: React.SetStateAction<EditorState>) => void;
  onChangeState: (state: EditorState) => void;
}

export const TextEditorLink = ({
  editorState,
  setEditorState,
  onChangeState,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const [urlValue, setUrlValue] = React.useState('');

  const popoverIsOpen = Boolean(anchorEl);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(e.currentTarget);

    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      // look for any existing link and set it as initial value
      // needed to be able to remove the link
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = (linkInstance.getData() as { url: string }).url;
      }

      setUrlValue(url);
    }
  };

  const confirmLink = (): void => {
    const contentState = editorState.getCurrentContent();

    const isInternal = urlValue.startsWith(window.location.origin);

    const target = getTargetFromLinkType(isInternal);

    const href = isInternal
      ? urlValue.replace(window.location.origin, '')
      : urlValue;

    const entityData: LinkData = {
      href,
      target,
    };

    const contentStateWithEntity = contentState.createEntity(
      ENTITY_TYPE.LINK,
      ENTITY_MUTABILITY.MUTABLE,
      entityData
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const editorStateWithEntity = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    const newEditorState = RichUtils.toggleLink(
      editorStateWithEntity,
      editorStateWithEntity.getSelection(),
      entityKey
    );

    setUrlValue('');
    setAnchorEl(null);
    onChangeState(newEditorState);
  };

  const removeLink = (): void => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(RichUtils.toggleLink(editorState, selection, null));
      setAnchorEl(null);
    }
  };

  return (
    <Fragment>
      <Button
        startIcon={<LinkIcon />}
        onClick={handleButtonClick}
        size="small"
        variant="outlined"
      >
        {t('editor.block.link')}
      </Button>
      <Popover
        open={popoverIsOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onBackdropClick={(): void => {
          setAnchorEl(null);
        }}
      >
        <TextEditorLinkInput
          urlValue={urlValue}
          setUrlValue={setUrlValue}
          handleConfirmLink={confirmLink}
          handleRemoveLink={removeLink}
        />
      </Popover>
    </Fragment>
  );
};
