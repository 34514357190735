/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Yup from 'yup';

export class AuthorValidation {
  static yupAuthorSchema() {
    return Yup.object()
      .required()
      .shape({ name: AuthorValidation.yupNameSchema() });
  }

  static yupNameSchema() {
    return Yup.string().trim().required().default('');
  }
}
