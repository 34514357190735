import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  optionData: Array<{
    displayValue: string;
    value: string | number;
  }>;
  errorData?: FieldError;
}

export const SelectInputField = ({
  name,
  label,
  optionData,
  errorData,
}: Props): JSX.Element => {
  const labelId = `${name}-label`;

  const { control } = useFormContext();

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            labelId={labelId}
            label={label}
            fullWidth
            error={errorData !== undefined}
          >
            {optionData.map(({ displayValue, value }) => {
              return (
                <MenuItem value={value} key={value}>
                  {displayValue}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      {errorData ? (
        <FormHelperText id="my-helper-text" error>
          {errorData?.message}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};
