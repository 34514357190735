import React from 'react';

import { EditorState } from 'draft-js';
import { StyleButton } from './TextEditorStyledButton';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { FormatBold, FormatItalic, FormatUnderlined } from '@material-ui/icons';

interface BlockProps {
  editorState: EditorState;
  onToggle: (value: string) => void;
}

const inlineStyles = (
  t: TFunction
): { label: string; style: string; icon: JSX.Element }[] => {
  return [
    { label: t('editor.inline.bold'), style: 'BOLD', icon: <FormatBold /> },
    {
      label: t('editor.inline.italic'),
      style: 'ITALIC',
      icon: <FormatItalic />,
    },
    {
      label: t('editor.inline.underline'),
      style: 'UNDERLINE',
      icon: <FormatUnderlined />,
    },
  ];
};

export const TextEditorInlineStyleControls = ({
  editorState,
  onToggle,
}: BlockProps): JSX.Element => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const { t } = useTranslation();

  return (
    <Grid container className="RichEditor-controls">
      {inlineStyles(t).map((type, index) => (
        <Grid item key={index}>
          <Box mr={1} mt={1}>
            <StyleButton
              key={type.label}
              active={currentStyle.has(type.style)}
              label={type.label}
              onToggle={onToggle}
              style={type.style}
              icon={type.icon}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
