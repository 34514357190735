import {
  AppBar,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { CodeLoungeCredits } from './footer/CodeLoungeCredits';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'static',
      zIndex: theme.zIndex.drawer + 1,
      flex: 'none',
      backgroundColor: 'inherit',
      color: theme.palette.grey[700],
      boxSizing: 'content-box',
      borderWidth: '1px 0',
      minHeight: '70px',
      [theme.breakpoints.up('sm')]: {
        minHeight: LayoutConstants.toolbarHeight,
      },
    },
    toolbar: {
      display: 'flex',
    },
    branding: {
      display: 'flex',
      alignItems: 'center',
      maxHeight: `calc(${LayoutConstants.toolbarHeight}/2)`,
      flexWrap: 'wrap',
      justifyContent: 'center',
      ...theme.typography.body2,
      lineHeight: 0,
    },
  })
);

export const SideBarFooter = (): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={LayoutConstants.elevation}
      variant="outlined"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.branding}>
          <CodeLoungeCredits colorMode="light" />
        </div>
      </Toolbar>
    </AppBar>
  );
};
