import { Box, Grid } from '@material-ui/core';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { BooksTable } from '../../../components/book/table/BooksTable';
import { SABreadcrumbs } from '../../../components/common/navigation/breadcrumbs/Breadcrumbs';
import { useBooksTableQuery } from '../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';

export const BooksTablePage = (): JSX.Element => {
  const { data, error, loading } = useBooksTableQuery();

  const books = data?.books ?? [];
  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <SABreadcrumbs translateLastPathName />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <BooksTable books={books} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
