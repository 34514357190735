import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { PostAdd } from '@material-ui/icons';

interface Props {
  tooltipText: string;
  onClick: () => void;
}

export const AddEntityToolbarButton = ({
  onClick,
  tooltipText,
}: Props): JSX.Element => {
  const handleClick = (): void => {
    onClick();
  };

  return (
    <React.Fragment>
      <Tooltip title={tooltipText}>
        <IconButton onClick={handleClick}>
          <PostAdd />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};
