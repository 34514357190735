import React, { useEffect } from 'react';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { titleCase } from '../../../common/StringUtils';
import {
  PublicationInput,
  useFetchPublicationDataFromCrossrefLazyQuery,
  FetchPublicationDataFromCrossrefQuery,
  PublicationContentInput,
} from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { AuthorForm } from '../../common/form/AuthorForm';
import { InputField } from '../../common/form/InputField';
import { OriginalLanguageSelect } from '../../common/form/OriginalLanguageSelect';
import { TagsAutocompleteInput } from '../../common/form/tags-autocomplete/TagsAutocompleteInput';
import { GenericSnackbar } from '../../common/feedback/GenericSnackbar';

export const ArticleMetaForm = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    getValues,
    reset,
    formState: { errors },
  } = useFormContext<PublicationInput>();

  const [publicationFromDoiLazyQuery, { loading, data, error }] =
    useFetchPublicationDataFromCrossrefLazyQuery();

  const [fetchedCrossrefData, setFetchedCrossrefData] = React.useState<
    FetchPublicationDataFromCrossrefQuery | undefined
  >(undefined);

  const loadingDoiData = (): void => {
    const doiValue = getValues('doi');

    publicationFromDoiLazyQuery({
      variables: {
        doi: doiValue,
      },
    });
  };

  const formValues = getValues();

  const { doi, externalLink, year, venue } = formValues;

  useEffect(() => {
    if (data === fetchedCrossrefData) {
      return;
    }

    setFetchedCrossrefData(data);

    const publication = data?.fetchPublicationDataFromCrossref;

    if (publication) {
      // exclude __typename since this causes graphql error when publication
      // is submitted
      const { __typename, ...publicationWithoutTypename } = publication;

      const updatedPublicationData: PublicationInput = {
        ...formValues,
        ...publicationWithoutTypename,
        originalLanguage:
          publication.originalLanguage ?? formValues.originalLanguage,
        content: formValues.content.map(
          (prevContent): PublicationContentInput => {
            if (
              publication.content &&
              prevContent.language === publication.content?.language
            ) {
              return {
                ...prevContent,
                title: publication.content.title,
                abstract: publication.content.abstract,
              };
            }
            return prevContent;
          }
        ),
      };
      reset(updatedPublicationData, { keepDirty: true });
    }
  }, [data, fetchedCrossrefData, reset, formValues]);

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid
        item
        container
        xs={12}
        md={12}
        spacing={LayoutConstants.gridSpacing}
        alignItems="center"
        justify="center"
      >
        <Grid item xs={6} sm={9} lg={10}>
          <InputField
            name="doi"
            defaultValue={doi}
            label={titleCase('doi')}
            required={false}
            errorData={errors.doi}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              startIcon={<Check />}
              onClick={loadingDoiData}
              disabled={Boolean(errors['doi']) || doi === ''}
              fullWidth
            >
              {t('form.autofill')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InputField
          name="externalLink"
          label={titleCase(t('form.linkToArticle'))}
          defaultValue={externalLink}
          required={true}
          errorData={errors.externalLink}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <OriginalLanguageSelect
          name="originalLanguage"
          label={t('phrases.originalLanguage')}
          errorData={errors.originalLanguage}
        />
      </Grid>
      <Grid item xs={6} sm={2} xl={1}>
        <InputField
          name="year"
          type={'number'}
          label={titleCase(t('commons.year'))}
          defaultValue={String(year)}
          required={true}
          errorData={errors.year}
        />
      </Grid>
      <Grid item xs={12} sm xl>
        <InputField
          name="venue"
          label={titleCase(t('commons.venue'))}
          defaultValue={venue}
          required={true}
          errorData={errors.venue}
        />
      </Grid>
      <Grid item xs={12}>
        <AuthorForm />
      </Grid>
      <Grid item xs={12}>
        <TagsAutocompleteInput name="tagIds" />
      </Grid>
      <GenericSnackbar
        message={t('phrases.fetchingDoiError')}
        severity="error"
        isOpen={Boolean(error)}
      />
    </Grid>
  );
};
