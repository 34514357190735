import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { ArticleValidation } from '../../../model/validation/ArticleValidation';
import { ArticleMetaForm } from './ArticleMetaForm';
import {
  ArticleDataFragment,
  useCreateArticleMutation,
  useUpdateArticleMutation,
} from '../../../graphql/models';
import { QUERY_ARTICLES } from '../../../graphql/queries/article/articles';
import { QUERY_ARTICLE_DOIS } from '../../../graphql/queries/article/article-dois';
import { ArticleDois } from '../../../common/types';
import { ApiRequestErrorAndLoadingWrapper } from '../../common/ApiRequestWrapper';
import {
  yupAuthorsSchema,
  yupContentSchema,
  yupLinkSchema,
  yupOriginalLanguageSchema,
  yupTagIdsSchema,
} from '../../../common/CommonSchemas';
import { useSetupResourceForm } from '../../common/hooks/useSetupResourceForm';
import { ResourceForm } from '../../common/form/ResourceForm';
import { ResourceContentForm } from '../../common/form/ResourceContentForm';

interface Props {
  article?: ArticleDataFragment;
}

export const Article = ({ article }: Props): JSX.Element => {
  const { t } = useTranslation();

  const formTitle = article
    ? t('articles.form.title.edit')
    : t('articles.form.title.new');

  const additionalInitialValues = {
    doi: article?.doi || '',
    year: article?.year || new Date().getFullYear(),
    venue: article?.venue || '',
    externalLink: article?.externalLink || '',
  };

  const {
    data: articleDoisData,
    loading,
    error,
  } = useQuery<ArticleDois>(QUERY_ARTICLE_DOIS);

  const existingDois =
    articleDoisData?.articles
      .map((existingArticle) => existingArticle.doi)
      .filter((doi) => doi && (!article?.doi || doi !== article?.doi)) ?? [];

  const validationSchema = Yup.object().shape({
    doi: ArticleValidation.yupDoiSchema(existingDois, t),
    year: ArticleValidation.yupYearSchema(),
    venue: ArticleValidation.yupVenueSchema(),
    authors: yupAuthorsSchema(),
    content: yupContentSchema('abstract', t),
    originalLanguage: yupOriginalLanguageSchema(),
    externalLink: yupLinkSchema(),
    tagIds: yupTagIdsSchema(),
  });

  const [createMutation] = useCreateArticleMutation();
  const [updateMutation] = useUpdateArticleMutation();

  const [form, handleSubmit, handleReset, errorMessage] = useSetupResourceForm({
    resource: article,
    resourceName: 'articles',
    validationSchema,
    createMutation,
    updateMutation,
    query: QUERY_ARTICLES,
    additionalInitialValues,
  });

  if (loading || error) {
    return <ApiRequestErrorAndLoadingWrapper loading={loading} error={error} />;
  }

  return (
    <ResourceForm
      formTitle={formTitle}
      form={form}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      metaForm={<ArticleMetaForm />}
      contentForm={<ResourceContentForm contentKey="abstract" />}
      errorMessage={errorMessage}
    />
  );
};
