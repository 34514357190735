import { TFunction } from 'i18next';

import { SupportedLanguage } from '../../../../graphql/models';

export const translatedTitleLabel = (
  t: TFunction,
  language: SupportedLanguage
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t('resources.content.englishTitle');
    case SupportedLanguage.It:
      return t('resources.content.italianTitle');
    case SupportedLanguage.De:
      return t('resources.content.germanTitle');
  }
};

export const translatedContentLabel = (
  t: TFunction,
  language: SupportedLanguage
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t('resources.content.englishContent');
    case SupportedLanguage.It:
      return t('resources.content.italianContent');
    case SupportedLanguage.De:
      return t('resources.content.germanContent');
  }
};

export const translatedAbstractLabel = (
  t: TFunction,
  language: SupportedLanguage
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t('resources.content.englishAbstract');
    case SupportedLanguage.It:
      return t('resources.content.italianAbstract');
    case SupportedLanguage.De:
      return t('resources.content.germanAbstract');
  }
};

export const translatedContentSummaryLabel = (
  t: TFunction,
  language: SupportedLanguage
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t('resources.content.englishContentSummary');
    case SupportedLanguage.It:
      return t('resources.content.italianContentSummary');
    case SupportedLanguage.De:
      return t('resources.content.germanContentSummary');
  }
};

export const translatedDescriptionLabel = (
  t: TFunction,
  language: SupportedLanguage
): string => {
  switch (language) {
    case SupportedLanguage.En:
      return t('resources.content.englishDescription');
    case SupportedLanguage.It:
      return t('resources.content.italianDescription');
    case SupportedLanguage.De:
      return t('resources.content.germanDescription');
  }
};
