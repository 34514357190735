import gql from 'graphql-tag';
import { AUTHOR_FRAGMENT } from './author.fragment';
import { TAG_FRAGMENT } from './tag.fragment';

export const SUMMARY_FRAGMENT = gql`
  fragment SummaryData on Narrative {
    id
    dateCreated
    dateUpdated
    originalLanguage
    authors {
      ...AuthorData
    }
    content {
      content
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AUTHOR_FRAGMENT}
  ${TAG_FRAGMENT}
`;
