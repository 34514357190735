import {
  AppBar,
  makeStyles,
  Grid,
  Link,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LayoutConstants } from '../../../theme/LayoutConstants';

import CopyrightIcon from '@material-ui/icons/Copyright';
import { CodeLoungeCredits } from './footer/CodeLoungeCredits';
import { useRoutes } from '../../../common/routing/routes-hook';

interface StyleProps {
  isMinimal: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  appBar: {
    position: 'static',
    zIndex: theme.zIndex.drawer + 1,
    flex: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxSizing: 'content-box',
    borderWidth: '1px 0',
    minHeight: ({ isMinimal }) =>
      isMinimal ? LayoutConstants.minimalToolbarHeight : '70px',
    [theme.breakpoints.up('sm')]: {
      minHeight: ({ isMinimal }) =>
        isMinimal
          ? LayoutConstants.minimalToolbarHeight
          : LayoutConstants.toolbarHeight,
    },
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ({ isMinimal }) => (isMinimal ? 'center' : 'default'),
    minHeight: ({ isMinimal }) =>
      isMinimal
        ? LayoutConstants.minimalToolbarHeight
        : LayoutConstants.toolbarHeight,
  },
  pages: {
    ...theme.typography.body1,
    textAlign: 'center',
    '* > &': {
      padding: theme.spacing(1),
    },
  },
  branding: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: `calc(${LayoutConstants.toolbarHeight}/2)`,
    flexWrap: 'wrap',
    justifyContent: 'center',
    ...theme.typography.body2,
    lineHeight: 0,
  },
  stringsArtBranding: {
    marginRight: theme.spacing(1),
  },
  miniIcon: {
    ...theme.typography.body2,
    margin: `0 ${theme.spacing(1) / 2}px`,
  },
  contactBrandingContainer: {
    paddingTop: ({ isMinimal }) =>
      isMinimal ? '0' : theme.spacing(LayoutConstants.gridSpacing),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface Props {
  showPageLinks?: boolean;
}

export const FullWidthFooter = ({ showPageLinks }: Props): JSX.Element => {
  const { routes } = useRoutes();
  const pathname = useLocation().pathname;
  const isTagVisualizationPath = pathname.includes(
    routes.tagVisualization.path
  );

  const classes = useStyles({
    isMinimal: isTagVisualizationPath,
  });
  const { t } = useTranslation();

  const currentYear = new Date().getUTCFullYear();
  const currentVersion = process.env.REACT_APP_VERSION ?? 'dev';

  return (
    <AppBar
      elevation={LayoutConstants.elevation}
      variant="outlined"
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Grid container direction="column">
          {!isTagVisualizationPath && (
            <Grid item className={classes.contactBrandingContainer}>
              <Typography variant="h6">
                Conservatorio della Svizzera Italiana
              </Typography>
              <p style={{ textAlign: 'center' }}>
                T. +41 91 960 30 40
                <br />
                <Link
                  href="mailto:anna.modesti@conservatorio.ch"
                  underline="always"
                  color="inherit"
                >
                  Anna Modesti
                </Link>
                ,{' '}
                <Link
                  href="mailto:hubert.eiholzer@conservatorio.ch"
                  underline="always"
                  color="inherit"
                >
                  Hubert Eiholzer
                </Link>
              </p>
            </Grid>
          )}
          <Grid item>
            {showPageLinks && !isTagVisualizationPath && (
              <div className={classes.pages}>
                <Link
                  component={RouterLink}
                  to={routes.aboutUs.path}
                  color="inherit"
                >
                  {t('aboutUs.title')}
                </Link>{' '}
                <span>| </span>
                <Link
                  component={RouterLink}
                  to={routes.tagVisualization.path}
                  color="inherit"
                >
                  {t('tagVisualization.title')}
                </Link>
                <span>| </span>
                <Link
                  component={RouterLink}
                  to={routes.glossary.path}
                  color="inherit"
                >
                  {t('glossary.title')}
                </Link>
              </div>
            )}
            <div className={classes.branding}>
              <CopyrightIcon className={classes.miniIcon} />
              <span className={classes.stringsArtBranding}>
                StringsART {currentYear} ({currentVersion}).
              </span>
              <CodeLoungeCredits colorMode="dark" />
            </div>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
