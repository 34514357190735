import { Theme } from '@material-ui/core';
import { LayoutConstants } from '../../theme/LayoutConstants';
import { DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

export const legendContainerStyle = (
  theme: Theme,
  isDraggingOver: boolean
): React.CSSProperties =>
  ({
    width: 400,
    backgroundColor: isDraggingOver
      ? theme.palette.grey[100]
      : theme.palette.background.paper,
    border: '1px solid',
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.divider,
    position: 'absolute',
    zIndex: 100,
    top: '40px',
    padding: 4 * LayoutConstants.gridSpacing,
    maxHeight: '85%',
    overflow: 'auto',
  } as React.CSSProperties);

export const legendItemStyle = (
  theme: Theme,
  color: string,
  isActive: boolean
): React.CSSProperties =>
  ({
    backgroundColor: isActive ? color : 'transparent',
    width: 25,
    height: 15,
    borderRadius: theme.shape.borderRadius,
    marginRight: '4px',
    border: `1px solid ${color}`,
    cursor: 'pointer',
  } as React.CSSProperties);

export const draggableItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  theme: Theme
): React.CSSProperties =>
  ({
    ...draggableStyle,
    padding: '2px',
    backgroundColor: isDragging ? theme.palette.grey[400] : 'transparent',
  } as React.CSSProperties);
