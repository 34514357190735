import { ServerError, ServerParseError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import React from 'react';
import { useErrorHandler } from 'react-error-boundary';

export interface GraphqlClientContextValue {
  error?: Error | GraphQLError | ServerError | ServerParseError;
  setError: (error?: Error) => void;
}

export const GraphqlClientContext = React.createContext<
  GraphqlClientContextValue | undefined
>(undefined);

export function GraphqlClientContextProvider({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element | null {
  const handleError = useErrorHandler();

  const [error, setError] =
    React.useState<GraphqlClientContextValue['error']>(undefined);

  const value = { error, setError };

  if (error) {
    handleError(error);
  }

  return (
    <GraphqlClientContext.Provider value={value}>
      {children}
    </GraphqlClientContext.Provider>
  );
}
