import * as Yup from 'yup';
import * as Apollo from '@apollo/client';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Resource, ResourceInput } from '../types';
import { Exact } from '../../../graphql/models';
import { ObjectShape } from 'yup/lib/object';
import { useRoutes } from '../../../common/routing/routes-hook';
import { ResourceType } from '../../../pages/app/search/types';

type GenericContent = Record<string, unknown> & { title: string };

const filterBy = (cnt: GenericContent): boolean => cnt.title !== '';

const cleanData = <ContentInputType extends Array<GenericContent>>(
  content: ContentInputType
) => {
  return content.filter(filterBy);
};

export const useAfterFormSubmitHandler = <
  CreateDataType,
  UpdateDataType,
  InputType,
  ValidationObjectType extends ObjectShape
>(
  resource: Resource | undefined,
  resourceName: ResourceType,
  validationSchema: Yup.ObjectSchema<ValidationObjectType>,
  createMutation: Apollo.MutationFunction<
    CreateDataType,
    Exact<{ input: InputType }>
  >,
  updateMutation: Apollo.MutationFunction<
    UpdateDataType,
    Exact<{ id: number; input: InputType }>
  >,
  query: Apollo.DocumentNode
): SubmitHandler<ResourceInput> => {
  const history = useHistory();
  const { routes } = useRoutes();

  return async (data) => {
    data.content = cleanData(data.content) as typeof data.content;

    const validatedInput = validationSchema.cast(data, {
      stripUnknown: true,
    }) as InputType;

    if (resource?.id) {
      await updateMutation({
        variables: {
          id: resource?.id,
          input: validatedInput,
        },
        refetchQueries: [{ query }],
      });
    } else {
      await createMutation({
        variables: { input: validatedInput },
        refetchQueries: [{ query }],
      });
    }

    history.push(routes[resourceName].path);
  };
};
