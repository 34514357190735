import gql from 'graphql-tag';
import { AUTHOR_FRAGMENT } from './author.fragment';
import { TAG_FRAGMENT } from './tag.fragment';

export const ARTICLE_FRAGMENT = gql`
  fragment ArticleData on Publication {
    id
    doi
    externalLink
    originalLanguage
    venue
    year
    authors {
      ...AuthorData
    }
    content {
      abstract
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AUTHOR_FRAGMENT}
  ${TAG_FRAGMENT}
`;
