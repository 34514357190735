import { Grid } from '@material-ui/core';
import { BookForm } from '../../../../components/book/form/BookForm';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { FormBreadCrumbs } from '../../../../components/common/navigation/breadcrumbs/FormBreadcrumbs';

export const NewBookPage = (): JSX.Element => {
  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={12}>
        <FormBreadCrumbs />
      </Grid>
      <Grid item xs={12}>
        <BookForm />
      </Grid>
    </Grid>
  );
};
