import gql from 'graphql-tag';
import { SUMMARY_FRAGMENT } from '../../fragments/summary.fragment';

export const QUERY_SUMMARIES = gql`
  query summaries {
    summaries: narratives {
      ...SummaryData
    }
  }
  ${SUMMARY_FRAGMENT}
`;
