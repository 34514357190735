import gql from 'graphql-tag';
import { LINK_FRAGMENT } from '../../fragments/link.fragment';

export const QUERY_LINKS = gql`
  query links {
    links: videoLinks {
      ...LinkData
    }
  }
  ${LINK_FRAGMENT}
`;
