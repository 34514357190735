import gql from 'graphql-tag';
import { TAG_CATEGORY_FRAGMENT } from './tag-category.fragment';

export const TAG_FRAGMENT = gql`
  fragment TagData on Tag {
    id
    category {
      ...TagCategoryData
    }
    content {
      name
      description
      language
    }
    references {
      text
      url
    }
    articleIds: publicationIds
    summaryIds: narrativeIds
    linkIds: videoLinkIds
    bookIds
    academicWorkIds: summaryIds
  }
  ${TAG_CATEGORY_FRAGMENT}
`;
