import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  SubmitErrorHandler,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { buildYupLocaleObject } from '../../../common/yup-validation';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { SUPPORTED_LANGUAGES_OBJECT } from '../../../common/supported-languages';
import { FormHeaderWithSubmit } from '../../common/form/FormHeaderWithSubmit';
import {
  TagContentInput,
  TagDataFragment,
  TagInput,
  useCreateTagMutation,
  useTagCategoriesQuery,
  useUpdateTagMutation,
} from '../../../graphql/models';
import { TagValidation } from '../../../model/validation/TagValidation';
import { QUERY_TAGS } from '../../../graphql/queries/tag/tags';
import { ApiRequestErrorAndLoadingWrapper } from '../../common/ApiRequestWrapper';
import { GenericSnackbar } from '../../common/feedback/GenericSnackbar';
import { TagContentForm } from './TagContentForm';
import { TagCategorySelectFormField } from './TagCategorySelectFormField';
import { titleCase } from '../../../common/StringUtils';
import { useFormStyles } from '../../common/form/styles';
import {
  yupReferencesSchema,
  yupTranslatedContentSchema,
} from '../../../common/CommonSchemas';
import { useRoutes } from '../../../common/routing/routes-hook';
import { ReferenceForm } from '../../common/form/ReferenceForm';

interface Props {
  tag?: TagDataFragment;
}

export const TagForm = ({ tag }: Props): JSX.Element => {
  const classes = useFormStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { routes } = useRoutes();

  const [atLeastOneLanguageFilled, setAtLeastOneLanguageFilled] =
    React.useState(false);

  Yup.setLocale(buildYupLocaleObject(t));

  const defaultContents: TagContentInput[] = SUPPORTED_LANGUAGES_OBJECT.map(
    (language) => {
      return {
        name: '',
        description: '',
        language: language.locale,
      };
    }
  );

  const content = defaultContents.map(
    (contentTranslation) =>
      tag?.content.find(
        (value) => value.language === contentTranslation.language
      ) ?? contentTranslation
  );

  const { data: tagCategoriesData, loading } = useTagCategoriesQuery();

  const initialReferences =
    tag && (tag.references.length ?? 0) > 0
      ? tag.references
      : [{ text: '', url: '' }];

  const initialValues: Omit<TagInput, 'id'> = {
    categoryId: tag?.category.id || tagCategoriesData?.tagCategories[0].id || 1,
    content,
    references: initialReferences,
  };

  const validationSchema = Yup.object().shape({
    categoryId: TagValidation.yupTagCategoryIdSchema(),
    content: yupTranslatedContentSchema(t),
    references: yupReferencesSchema(t),
  });

  const tagForm = useForm<typeof initialValues>({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    context: { atLeastOneLanguageFilled },
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = tagForm;

  const tagContent = useWatch({
    control,
    name: 'content',
  });

  React.useEffect(() => {
    setAtLeastOneLanguageFilled(tagContent.some((val) => val.name !== ''));
  }, [tagContent]);

  const [createTagMutation] = useCreateTagMutation();
  const [updateTagMutation] = useUpdateTagMutation();

  const [afterSubmitErrors, setAfterSubmitErrors] = useState(false);

  const [beforeSubmitError, setBeforeSubmitError] = useState<string | null>(
    null
  );

  const handleFormReset = (): void => reset(initialValues);

  const errorHandler: SubmitErrorHandler<typeof initialValues> = (_): void => {
    setBeforeSubmitError(null);
    const errorMessage = `${t('validation.beforeSubmit')}`;
    setBeforeSubmitError(errorMessage);
  };

  const submitHandler: SubmitHandler<typeof initialValues> = async (data) => {
    data.content = data.content.filter((cnt) => cnt.name !== '');
    data.references = data.references.filter((ref) => ref.text !== '');

    const validatedData = validationSchema.cast(data, {
      stripUnknown: true,
    }) as typeof initialValues;

    if (tag?.id) {
      const { errors } = await updateTagMutation({
        variables: {
          id: tag?.id,
          input: validatedData,
        },
        refetchQueries: [{ query: QUERY_TAGS }],
      });
      setAfterSubmitErrors(Boolean(errors));
    } else {
      const { errors } = await createTagMutation({
        variables: { input: validatedData },
        refetchQueries: [{ query: QUERY_TAGS }],
      });
      setAfterSubmitErrors(Boolean(errors));
    }
    if (!afterSubmitErrors) {
      history.push(routes.tags.path);
    }
  };

  const formTitle = tag ? t('tags.form.title.edit') : t('tags.form.title.new');

  return (
    <ApiRequestErrorAndLoadingWrapper loading={loading}>
      <FormProvider {...tagForm}>
        <form onSubmit={handleSubmit(submitHandler, errorHandler)}>
          <Grid
            container
            spacing={LayoutConstants.gridSpacing}
            className={classes.root}
          >
            <Grid item xs={12}>
              <FormHeaderWithSubmit
                title={formTitle}
                handleFormReset={handleFormReset}
              />
            </Grid>
            <Grid item xs={12} className={classes.noMarginBottom}>
              <TagCategorySelectFormField
                name="categoryId"
                label={titleCase(t('commons.tagCategory'))}
                errorData={errors.categoryId}
              />
            </Grid>
            <Grid item xs={12} className={classes.noMarginTop}>
              <TagContentForm />
            </Grid>
            <Grid item xs={12} className={classes.noMarginTop}>
              <ReferenceForm />
            </Grid>
          </Grid>
        </form>
        <GenericSnackbar
          message={beforeSubmitError ?? ''}
          isOpen={beforeSubmitError !== null}
          severity={'error'}
        />
        <GenericSnackbar
          message={t('phrases.submitError')}
          isOpen={afterSubmitErrors}
          severity={'error'}
        />
      </FormProvider>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
