import { Box, Grid } from '@material-ui/core';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { SABreadcrumbs } from '../../../components/common/navigation/breadcrumbs/Breadcrumbs';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';
import { TagCategoriesTable } from '../../../components/tag-category/table/TagCategoriesTable';
import { useTagCategoriesQuery } from '../../../graphql/models';

export const TagCategoriesTablePage = (): JSX.Element => {
  const { data, error, loading } = useTagCategoriesQuery();

  const tagCategories = data?.tagCategories ?? [];

  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <SABreadcrumbs translateLastPathName />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TagCategoriesTable tagCategories={tagCategories} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
