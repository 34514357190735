import { useEffect } from 'react';

/*
 * This reusable hook mimics the componentDidMount function of React class components.
 * Whenever you want to run a certain function only once after a component mounted, you can use this hook.
 * useMountEffect also takes care of ESLint warnings, therefore we have this mount functionality
 * without having to repeatedly ignore ESLint warnings.
 */

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (run: () => void): void => useEffect(run, []);
