import { Color, toColor } from 'react-color-palette';

// https://24ways.org/2010/calculating-color-contrast/
export const getContrastYIQ = (color: Color): Color => {
  const formattedHexColor = color.hex.replace('#', '');

  const r = parseInt(formattedHexColor.substr(0, 2), 16);
  const g = parseInt(formattedHexColor.substr(2, 2), 16);
  const b = parseInt(formattedHexColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? toColor('hex', '#000') : toColor('hex', '#FFF');
};
