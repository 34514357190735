import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';

import { RowsToBeDeleted } from '../../common/table/types';
import {
  TagCategoriesTableQuery,
  useUpdateTagCategoryPrioritiesMutation,
} from '../../../graphql/models';
import { QUERY_TAG_CATEGORIES } from '../../../graphql/queries/tag-category/tag-categories';
import { swapElement } from '../../../common/ListUtils';

export const tagCategoriesTableToolbarSelect =
  (
    setRowsToBeDeleted: (rows: RowsToBeDeleted) => void,
    tagCategories: TagCategoriesTableQuery['tagCategories']
  ): MUIDataTableOptions['customToolbarSelect'] =>
  (selectedRows): JSX.Element => {
    const TagCategoriesTableToolbarSelect = (): JSX.Element => {
      const { t } = useTranslation();
      const initialPriorityList = [...tagCategories]
        .sort((a, b) => a.priority - b.priority)
        .map((tagCategory) => tagCategory.id);

      const selectedCategories = selectedRows.data.map(
        (selectedRow) => tagCategories[selectedRow.dataIndex]
      );

      const [updateTagCategoryPrioritiesMutation] =
        useUpdateTagCategoryPrioritiesMutation();

      const handleIncreasePriorityClick = async (): Promise<void> => {
        let priorityList = [...initialPriorityList];
        [...selectedCategories]
          .sort((a, b) => a.priority - b.priority)
          .forEach((category, currentIndex) => {
            const index = priorityList.indexOf(category.id);
            priorityList = swapElement(priorityList, index, true, {
              minIndex: currentIndex,
            });
          });

        await updateTagCategoryPrioritiesMutation({
          variables: { priorityList },
          refetchQueries: [{ query: QUERY_TAG_CATEGORIES }],
        });
      };

      const handleDecreasePriorityClick = async (): Promise<void> => {
        let priorityList = [...initialPriorityList];

        [...selectedCategories]
          .sort((a, b) => b.priority - a.priority)
          .forEach((category, currentIndex) => {
            const index = priorityList.indexOf(category.id);
            priorityList = swapElement(priorityList, index, false, {
              maxIndex: priorityList.length - 1 - currentIndex,
            });
          });

        await updateTagCategoryPrioritiesMutation({
          variables: { priorityList },
        });
      };

      const handleDeleteClick = (): void => {
        setRowsToBeDeleted(selectedRows);
      };

      return (
        <div className={'custom-toolbar-select'}>
          <Tooltip title={`${t('tagCategories.table.increasePriority')}`}>
            <IconButton onClick={handleIncreasePriorityClick}>
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${t('tagCategories.table.decreasePriority')}`}>
            <IconButton onClick={handleDecreasePriorityClick}>
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`${t('commons.delete')}`}>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
    };

    return <TagCategoriesTableToolbarSelect />;
  };
