import gql from 'graphql-tag';
import { TAG_CATEGORY_FRAGMENT } from '../../fragments/tag-category.fragment';

export const QUERY_TAG_CATEGORIES = gql`
  query tagCategories {
    tagCategories {
      ...TagCategoryData
    }
  }
  ${TAG_CATEGORY_FRAGMENT}
`;
