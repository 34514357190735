import gql from 'graphql-tag';
import { BOOK_FRAGMENT } from '../../fragments/book.fragment';

export const QUERY_BOOKS = gql`
  query books {
    books {
      ...BookData
    }
  }
  ${BOOK_FRAGMENT}
`;
