import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

import { EditUserForm } from './edit-user-dialog/EditUserForm';
import { KeycloakUserWithRole } from './hooks/get-users-hook';
import { useState } from 'react';
import { DeleteUserForm } from './edit-user-dialog/DeleteUserForm';

interface Props {
  user?: KeycloakUserWithRole;
  handleClose: () => void;
}

const StyledDialogActions = withStyles((theme) => {
  return {
    root: {
      margin: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  };
})(DialogActions);

export const EditUserDialog = ({ user, handleClose }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const editUserFormId = 'edit-user-form';
  const deleteUserFormId = 'delete-user-form';

  return (
    <Dialog
      open={user !== undefined}
      aria-labelledby="alert-dialog-edit-user"
      aria-describedby="alert-dialog-edit-user"
    >
      <DialogTitle id="alert-dialog-title">
        {t('users.dialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {user && (
            <EditUserForm
              user={user}
              formId={editUserFormId}
              handleFormSubmitted={handleClose}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <Button
          onClick={() => setShowDeleteConfirmation(true)}
          variant="contained"
          color="default"
          startIcon={<DeleteIcon />}
          disabled={showDeleteConfirmation}
        >
          {t('commons.delete')}
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          disabled={showDeleteConfirmation}
        >
          {t('users.dialog.cancel')}
        </Button>
        <Button
          type="submit"
          form={editUserFormId}
          color="primary"
          autoFocus
          disabled={showDeleteConfirmation}
        >
          {t('users.dialog.update')}
        </Button>
      </StyledDialogActions>
      {/* Delete Confirmation */}
      <div hidden={!showDeleteConfirmation}>
        <DialogContent>
          {user && (
            <DeleteUserForm
              user={user}
              formId={deleteUserFormId}
              handleFormSubmitted={handleClose}
            />
          )}
        </DialogContent>
        <StyledDialogActions>
          <div style={{ flexGrow: 1 }} />
          <Button
            onClick={() => setShowDeleteConfirmation(false)}
            variant="contained"
            color="primary"
          >
            {t('dialog.no')}
          </Button>
          <Button type="submit" form={deleteUserFormId} color="primary">
            {t('dialog.yes')}
          </Button>
          <div style={{ flexGrow: 1 }} />
        </StyledDialogActions>
      </div>
    </Dialog>
  );
};
