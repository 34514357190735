import { InMemoryCache } from '@apollo/client';

// see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
export const cache = new InMemoryCache({
  typePolicies: {
    Author: {
      merge: false,
    },
    NarrativeContent: {
      merge: false,
    },
    Narrative: {
      fields: {
        content: {
          merge: false,
        },
      },
    },
    PublicationContent: {
      merge: false,
    },
    Publication: {
      fields: {
        content: {
          merge: false,
        },
      },
    },
    TagCategory: {
      fields: {
        name: {
          merge: false,
        },
      },
    },
    Tag: {
      fields: {
        content: {
          merge: false,
        },
      },
    },
  },
});
