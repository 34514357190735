import { TFunction, i18n } from 'i18next';
import {
  SupportedLanguage,
  TagCategory,
  TagCategoryDataFragment,
  TagDataFragment,
} from '../graphql/models';
import { TagDescriptionTooltipProps } from '../components/tag/TagTooltipHelpers';
import { languageCompare } from '../common/supported-languages';

export const getTranslatedTagCategoryContentOrDefault = (
  tagCategory: TagCategory | TagDataFragment['category'],
  field: 'name' | 'description',
  language: SupportedLanguage
): string => {
  const translatedContent = tagCategory.content.find(
    (content) => content.language === language
  );
  if (translatedContent) {
    return translatedContent[field] ?? '';
  }
  return tagCategory.content[0][field] ?? '';
};

export const getTagCategoryDescription = (
  tagCategory: TagCategoryDataFragment,
  i18n: i18n,
  t?: TFunction
): TagDescriptionTooltipProps => {
  const description =
    tagCategory.content.find(({ language }) => languageCompare(language, i18n))
      ?.description ||
    tagCategory.content.find((value) => Boolean(value.description))
      ?.description ||
    '';
  return {
    description,
    references: tagCategory.references.map((reference) => ({
      text: reference.text,
      url: reference.url ?? '',
    })),
    descriptionTitle: t ? t('commons.form.description') : '',
    referenceTitle: t ? t('commons.form.references') : '',
  };
};

export type TagCategoryWithTags = TagCategoryDataFragment & {
  tags: TagDataFragment[];
};

export const groupTagsByCategory = (
  tags: TagDataFragment[]
): TagCategoryWithTags[] => {
  return tags.reduce((collectedCategories, tag) => {
    const currentGroupIndex = collectedCategories.findIndex(
      (collectedCategory) => collectedCategory.id === tag.category.id
    );
    if (currentGroupIndex === -1) {
      collectedCategories.push({
        ...tag.category,
        tags: [tag],
      });
    } else {
      collectedCategories[currentGroupIndex] = {
        ...collectedCategories[currentGroupIndex],
        tags: [...collectedCategories[currentGroupIndex].tags, tag],
      };
    }

    return collectedCategories;
  }, [] as TagCategoryWithTags[]);
};
