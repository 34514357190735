import {
  AccordionDetails as MuiAccordionDetails,
  Grid,
  withStyles,
} from '@material-ui/core';
import React from 'react';

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

interface Props {
  children: React.ReactNode;
}

export const GenericAccordionDetails = ({ children }: Props): JSX.Element => {
  return (
    <AccordionDetails>
      <Grid container>{children}</Grid>
    </AccordionDetails>
  );
};
