import gql from 'graphql-tag';
import { ARTICLE_FRAGMENT } from '../../fragments/article.fragment';

export const QUERY_ARTICLES = gql`
  query articles {
    articles: publications {
      ...ArticleData
    }
  }
  ${ARTICLE_FRAGMENT}
`;
