/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { isISBN } from 'class-validator';

import { SupportedLanguage } from '../../graphql/models';
import {
  editorSchema,
  titleSchema,
  translatorSchema,
} from '../../common/CommonSchemas';

const bookSchema = (t?: TFunction) => {
  return Yup.object().shape(
    {
      translator: translatorSchema('summary', t),
      title: titleSchema('summary', t),
      summary: editorSchema(t ? t('book.contentSummary') : '', t),
      language: Yup.mixed().oneOf(Object.values(SupportedLanguage)).required(),
    },
    [
      ['translator', 'title'],
      ['title', 'summary'],
      ['translator', 'summary'],
    ]
  );
};
export class BookValidation {
  static yupISBNSchema(t?: TFunction) {
    const message = t ? t('validation.error.custom.validISBN') : '';
    return Yup.string()
      .trim()
      .test('is-Empty-or-ISBN', message, (value) => {
        return !value || isISBN(value);
      })
      .default('');
  }

  static yupYearSchema() {
    return Yup.number()
      .min(1400)
      .max(new Date().getFullYear() + 1)
      .required()
      .default(1400);
  }

  static yupPublisherSchema() {
    return Yup.string().trim().required().default('');
  }

  static yupContentSchema(t?: TFunction) {
    return Yup.array(bookSchema(t)).default([]).defined();
  }
}
