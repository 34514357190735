import { Description } from '@material-ui/icons';

import { EditArticlePage } from '../../../../../pages/app/admin/articles/EditArticlePage';
import { NewArticlePage } from '../../../../../pages/app/admin/articles/NewArticlePage';
import { ArticlesTablePage } from '../../../../../pages/app/admin/ArticlesTablePage';
import { Route } from '../../../Route';

export type ArticleRoutesType = {
  articles: Route<unknown>;
  articleNew: Route<unknown>;
  articleEdit: Route<unknown>;
};

export const articleRoutes = (parent: Route<unknown>): ArticleRoutesType => {
  const articles = new Route({
    path: '/articles',
    component: ArticlesTablePage,
    icon: Description,
    parent,
    exact: true,
  });

  const articleNew = new Route({
    path: '/new',
    component: NewArticlePage,
    parent: articles,
    exact: true,
  });

  const articleEdit = new Route({
    path: '/:id',
    component: EditArticlePage,
    parent: articles,
    exact: true,
  });

  return {
    articleNew,
    articleEdit,
    articles,
  };
};
