import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { handleContentClick } from '../../../../common/resources';
import { Author, SupportedLanguage } from '../../../../graphql/models';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { GenericPaper } from '../../layout/GenericPaper';

const useStyles = makeStyles((theme) => ({
  documentContainer: {
    padding: theme.spacing(2),
    '& p': {
      ...theme.typography.body1,
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
}));

export type GenericResourceContent = Array<{
  language: SupportedLanguage;
  title: string;
  body: string;
}>;

interface Props {
  authors: Author[];
  beforeBody?: boolean;
  children?: React.ReactNode;
  content: GenericResourceContent;
}

export const ResourcePageContent = ({
  authors,
  beforeBody = false,
  children,
  content,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const { lang } = useParams<{ id: string; lang: SupportedLanguage }>();

  const documentIncludesLanguage = content
    .map((content) => content.language)
    .includes(lang);

  if (!documentIncludesLanguage) {
    history.push('/404');
  }

  const languageContent = content.find((cnt) => cnt.language === lang);

  return (
    <Grid item xs={12} md={6} className={classes.contentContainer}>
      <GenericPaper className={classes.documentContainer}>
        <Grid container spacing={LayoutConstants.gridSpacing}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {languageContent?.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {authors.map((author, index) => {
              return (
                <Typography
                  variant="body2"
                  className={classes.italic}
                  key={index}
                >
                  {author.name}
                </Typography>
              );
            })}
          </Grid>
          {beforeBody && children}
          <Grid item xs={12}>
            <div
              onClick={handleContentClick(history)}
              dangerouslySetInnerHTML={{
                __html: languageContent?.body ?? '',
              }}
            ></div>
          </Grid>
          {!beforeBody && children}
        </Grid>
      </GenericPaper>
    </Grid>
  );
};
