import { MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AddEntityToolbarButton } from '../../common/table/AddEntityToolbarButton';
import {
  SummaryDataFragment,
  SupportedLanguage,
  useDeleteSummaryMutation,
} from '../../../graphql/models';
import { QUERY_SUMMARIES } from '../../../graphql/queries/summary/summaries';
import { getTranslatedTagNameOrDefault } from '../../../model/Tag';
import { QUERY_SUMMARIES_TABLE } from '../../../graphql/queries/summary/summaries-table';
import { GenericTable } from '../../common/table/GenericTable';
import React from 'react';
import DeleteConfirmationDialog from '../../common/table/DeleteConfirmationDialog';
import { RowsToBeDeleted } from '../../common/table/types';
import {
  ResourcesTableRow,
  ResourcesTableRowWithTags,
  useResourcesTableColumns,
} from '../../common/table/resources/resources-table-columns';
import { NavigationUtil } from '../../../common/navigation/NavigationUtil';
import { ResourcePath } from '../../../common/navigation/path-types';
import { useRoutes } from '../../../common/routing/routes-hook';
import { languageCompare } from '../../../common/supported-languages';

interface Props {
  summaries: SummaryDataFragment[];
}

export const SummariesTable = ({ summaries }: Props): JSX.Element => {
  const { i18n, t } = useTranslation();
  const { routes } = useRoutes();
  const history = useHistory();

  const [rowsToBeDeleted, setRowsToBeDeleted] = React.useState<
    RowsToBeDeleted | undefined
  >();

  const handleDeleteConfirmation = async () => {
    if (rowsToBeDeleted) {
      await deleteRows();
    }
    setRowsToBeDeleted(undefined);
  };

  const handleDeleteCancellation = () => {
    setRowsToBeDeleted(undefined);
  };

  const onAddNewClick = (): void => history.push(routes.summaryNew.path);

  const AddButton = (): JSX.Element => (
    <AddEntityToolbarButton
      tooltipText={t('phrases.addNewSummary')}
      onClick={onAddNewClick}
    />
  );

  const resourcesTableDataWithTags: ResourcesTableRowWithTags[] = summaries.map(
    ({ id, content, originalLanguage, tags, authors }) => {
      const title =
        content.find(({ language }) => languageCompare(language, i18n))
          ?.title ??
        content.find((value) => value.language === originalLanguage)?.title ??
        '-';

      const formattedAuthors = authors.map((author) => author.name);
      const languages = content
        .filter(({ title: translatedTitle }) => translatedTitle.length > 0)
        .map((translation) => translation.language)
        .sort();

      const publicLink = NavigationUtil.publicResourceLinkFrom(
        ResourcePath.summary,
        id,
        i18n.language as SupportedLanguage,
        languages
      );

      return {
        id,
        title,
        tags: tags || [],
        authors: formattedAuthors,
        languages,
        publicLink,
      };
    }
  );

  const tableData: ResourcesTableRow[] = resourcesTableDataWithTags.map(
    ({ tags, ...restData }) => {
      const tagsRender = tags.map((tag) => {
        return getTranslatedTagNameOrDefault(
          tag,
          i18n.language as SupportedLanguage
        );
      });

      return {
        ...restData,
        tags: tagsRender,
      };
    }
  );

  const columns = useResourcesTableColumns(resourcesTableDataWithTags);

  const [deleteMutation] = useDeleteSummaryMutation();

  const deleteRows = async () => {
    for (const row of rowsToBeDeleted?.data ?? []) {
      const id = summaries[row.dataIndex].id;
      await deleteMutation({
        variables: { id },
        refetchQueries: [
          { query: QUERY_SUMMARIES },
          { query: QUERY_SUMMARIES_TABLE },
        ],
      });
    }
  };

  const options: MUIDataTableOptions = {
    onRowsDelete: (rowsDeleted) => {
      setRowsToBeDeleted(rowsDeleted);
    },
    onRowClick(_: string[], rowMeta: { dataIndex: number; rowIndex: number }) {
      history.push(
        routes.summaryEdit.pathWithArguments({
          id: String(summaries[rowMeta.dataIndex].id),
        })
      );
    },
    customToolbar: AddButton,
    sortOrder: {
      name: 'id',
      direction: 'asc',
    },
  };

  return (
    <>
      <DeleteConfirmationDialog
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleDeleteCancellation={handleDeleteCancellation}
        open={rowsToBeDeleted !== undefined}
      />
      <GenericTable
        title={t('navigation.summaries')}
        data={tableData}
        columns={columns}
        options={options}
      />
    </>
  );
};
