import { List, ListItem, Typography } from '@material-ui/core';
import { TagCategoryReference, TagReference } from '../../graphql/models';

interface Props {
  references: TagReference[] | TagCategoryReference[];
}

export const GlossaryReferences = ({ references }: Props): JSX.Element => {
  if (references.length === 0 || !references) {
    return <Typography>-</Typography>;
  }

  return (
    <List style={{ listStyleType: 'disc', padding: '0 10px' }}>
      {references.map((reference, index) => (
        <ListItem
          key={`${reference.text}-${index}`}
          style={{ display: 'list-item', padding: 0 }}
        >
          <Typography color="inherit">
            {reference.url ? (
              <a href={reference.url} target="_blank" rel="noreferrer">
                {reference.text}
              </a>
            ) : (
              reference.text
            )}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};
