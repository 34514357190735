import React, { useCallback } from 'react';
import { Divider } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { TextEditorBlockStyleControls } from './toolbar/TextEditorBlockStyleControls';
import { TextEditorInlineStyleControls } from './toolbar/TextEditorInlineStyleControls';
import { createLinkDecorator } from './link/text-editor-link/link-decorator';
import { TextEditorContainer } from './TextEditorContainer';
import { customEntityTransform } from './commons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: `${theme.spacing(2)}px 0`,
    },
  })
);

interface Props {
  defaultValue?: string;
  placeholder?: string;
  onBlur?: () => void;
  onChange?: (...event: unknown[]) => void;
  hide?: boolean;
}

export const TextEditor = ({
  placeholder,
  defaultValue,
  hide,
  onBlur,
  onChange,
}: Props): JSX.Element => {
  const classes = useStyles();

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(createLinkDecorator())
  );

  const getDefaultEditorState = useCallback(() => {
    if (defaultValue) {
      // workaround for issue that href's not starting with a protocol are not
      // identified as links (https://github.com/facebook/draft-js/issues/237)
      // used to display internal links properly
      const defaultValueWithLinkFix = defaultValue.replace(
        'href="/',
        `href="${window.location.origin}/`
      );

      const blocksFromHTML = convertFromHTML(defaultValueWithLinkFix.trim());
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      return EditorState.createWithContent(contentState, createLinkDecorator());
    } else {
      return EditorState.createEmpty(createLinkDecorator());
    }
  }, [defaultValue]);

  // Setup initial state
  React.useEffect(() => {
    setEditorState(getDefaultEditorState());
  }, [getDefaultEditorState]);

  const onChangeState = (state: EditorState): void => {
    const newValue = draftToHtml(
      convertToRaw(state.getCurrentContent()),
      {},
      false,
      customEntityTransform
    );
    if (onChange) {
      onChange(newValue.trim());
    }
    setEditorState(state);
  };

  const toggleBlockType = (blockType: string): void => {
    onChangeState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle: string): void => {
    onChangeState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  return (
    <TextEditorContainer hide={hide}>
      <TextEditorBlockStyleControls
        editorState={editorState}
        setEditorState={setEditorState}
        onToggle={toggleBlockType}
        onChangeState={onChangeState}
      />
      <TextEditorInlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <Divider className={classes.divider} />
      <Editor
        editorState={editorState}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={(s) => onChangeState(s)}
      />
    </TextEditorContainer>
  );
};
