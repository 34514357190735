import { Person } from '@material-ui/icons';

import { UsersTablePage } from '../../../../../pages/app/admin/UsersTablePage';
import { UserRole } from '../../../../access-control/has-role-hook';
import { Route } from '../../../Route';

export type UserRoutesType = {
  users: Route<unknown>;
};

export const userRoutes = (parent: Route<unknown>): UserRoutesType => {
  const users = new Route({
    icon: Person,
    component: UsersTablePage,
    path: '/users',
    parent,
    exact: true,
    roles: [UserRole.admin],
  });

  return { users };
};
