import { Grid, useTheme } from '@material-ui/core';

import { TagVisualization } from '../../components/dashboard/TagVisualization';
import { LayoutConstants } from '../../theme/LayoutConstants';

export const TagVisualizationPage = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={LayoutConstants.gridSpacing}
      style={{ height: `calc(100% - ${theme.spacing(5)}px)` }}
      direction="column"
      alignItems="stretch"
      justify="flex-start"
    >
      <Grid item xs={12} style={{ maxWidth: '100%' }}>
        <TagVisualization />
      </Grid>
    </Grid>
  );
};
