import { Typography } from '@material-ui/core';

import { titleCase } from '../../../common/StringUtils';
import { SupportedLanguage } from '../../../graphql/models';
import i18n from '../../../i18n';
import {
  TagCategoryWithTags,
  getTranslatedTagCategoryContentOrDefault,
} from '../../../model/TagCategory';

interface Props {
  category: TagCategoryWithTags;
}

export const TagCategoryHeader = ({ category }: Props): JSX.Element => (
  <Typography variant="h5" component="div" style={{ cursor: 'default' }}>
    {titleCase(
      getTranslatedTagCategoryContentOrDefault(
        category,
        'name',
        i18n.language as SupportedLanguage
      )
    )}
  </Typography>
);
