import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab';
import {
  SupportedLanguage,
  TagDataFragment,
  useTagsQuery,
} from '../../../../graphql/models';
import { getTranslatedTagCategoryContentOrDefault } from '../../../../model/TagCategory';
import { titleCase } from '../../../../common/StringUtils';
import { useTranslation } from 'react-i18next';
import { ApiRequestErrorAndLoadingWrapper } from '../../ApiRequestWrapper';
import { TagsAutocompleteTaglist } from './TagsAutocompleteTagList';
import { TagsAutocompleteTextField } from './TagsAutocompleteTextField';
import { getTagDescriptionTooltipProps } from '../../../../model/Tag';
import { languageCompare } from '../../../../common/supported-languages';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    autocomplete: {
      '& .MuiAutocomplete-tag': {
        margin: '1px 3px',
      },
      '& .MuiAutocomplete-input': {
        minWidth: 'unset',
      },
    },
  })
);

interface Props {
  onChange: (item: number[]) => void;
  value: number[];
  label?: string;
  size?: 'medium' | 'small';
  tagsListClassName?: string;
  inputRef: React.Ref<HTMLInputElement>;
}

export const TagsAutocomplete = ({
  onChange,
  value,
  label,
  size,
  tagsListClassName,
  inputRef,
}: Props): JSX.Element => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();

  const tagQuery = useTagsQuery();
  const tags = tagQuery?.data?.tags ?? [];

  // https://github.com/mui-org/material-ui/issues/21967#issuecomment-665006321
  const sortedByPriorityTagIds = tags
    .slice()
    .sort((a, b) => a.category.priority - b.category.priority)
    .map((tag) => tag.id);

  const getTagName = (tag: TagDataFragment) => {
    return (
      tag.content.find(({ language }) => languageCompare(language, i18n))
        ?.name ?? tag.content[0].name
    );
  };

  const getTagsFromIds = (tagIds: number[]) => {
    return tags?.filter((tag) => tagIds.includes(tag.id));
  };

  const getTagId = (tag: TagDataFragment) => tag.id;

  const getGroupCategoryName = (tagId: number) => {
    const tag = getTagsFromIds([tagId])[0];
    return titleCase(
      getTranslatedTagCategoryContentOrDefault(
        tag.category,
        'name',
        i18n.language as SupportedLanguage
      )
    );
  };

  const getTagNameFromId = (tagId: number) =>
    getTagName(getTagsFromIds([tagId])[0]);

  return (
    <ApiRequestErrorAndLoadingWrapper
      error={tagQuery.error}
      loading={tagQuery.loading}
    >
      <Autocomplete
        onChange={(_, item) => {
          onChange(item);
        }}
        value={value}
        className={classes.autocomplete}
        fullWidth
        multiple
        options={sortedByPriorityTagIds}
        groupBy={getGroupCategoryName}
        getOptionLabel={getTagNameFromId}
        renderInput={(params) => (
          <TagsAutocompleteTextField
            params={params}
            label={label}
            size={size}
            inputRef={inputRef}
          />
        )}
        renderTags={(
          selectedTagIds: number[],
          getTagProps: AutocompleteGetTagProps
        ) => (
          <TagsAutocompleteTaglist
            selectedTags={getTagsFromIds(selectedTagIds)}
            getTagId={getTagId}
            getTagProps={getTagProps}
            getTagName={getTagName}
            getTagDescription={(tag) =>
              getTagDescriptionTooltipProps(tag, i18n, t)
            }
            className={tagsListClassName}
          />
        )}
      />
    </ApiRequestErrorAndLoadingWrapper>
  );
};
