export const swapElement = (
  originalList: number[],
  index: number,
  shouldIncrease: boolean,
  options?: {
    minIndex?: number;
    maxIndex?: number;
  }
): number[] => {
  const list = [...originalList];
  const { minIndex = 0, maxIndex = Number.MAX_SAFE_INTEGER } = options || {};

  if (shouldIncrease && index > minIndex) {
    [list[index], list[index - 1]] = [list[index - 1], list[index]];
  }

  if (!shouldIncrease && index < Math.min(list.length - 1, maxIndex)) {
    [list[index], list[index + 1]] = [list[index + 1], list[index]];
  }

  return list;
};

export const sortOutOfPlace = <T>(
  list: Array<T>,
  compareFn: (a: T, b: T) => number
): Array<T> => {
  return [...list].sort(compareFn);
};
