import { TagsAutocompleteInput } from '../tags-autocomplete/TagsAutocompleteInput';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tagsList: {
    overflow: 'auto',
    display: 'flex',
    '& .MuiChip-label': {
      textOverflow: 'unset',
    },
  },
}));

interface Props {
  size?: 'small' | 'medium';
}

export const SearchTagFilterInputField = ({ size }: Props): JSX.Element => {
  const { t } = useTranslation();
  const label = t('commons.tagFiltering');
  const classes = useStyles();

  return (
    <TagsAutocompleteInput
      tagsListClassName={classes.tagsList}
      name="tagFilter"
      label={label}
      size={size}
    />
  );
};
