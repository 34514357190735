interface Props {
  color: string;
  width?: number | string;
  height?: number | string;
  margin?: number | string;
  onClick?: () => void;
}

export const ColorPreview = (props: Props): JSX.Element => {
  const { color, width, height, margin, onClick } = props;

  return (
    <div
      style={{
        backgroundColor: color,
        width,
        height,
        margin,
        borderRadius: 40,
        boxShadow: '0 0 1px black',
        border: 'solid 2px white',
        cursor: onclick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    ></div>
  );
};
