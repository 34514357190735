import React from 'react';

import { Box, Grid } from '@material-ui/core';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { AcademicWorksTable } from '../../../components/academic-work/table/AcademicWorksTable';
import { SABreadcrumbs } from '../../../components/common/navigation/breadcrumbs/Breadcrumbs';
import { useAcademicWorksQuery } from '../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';

export const AcademicWorksTablePage = (): JSX.Element => {
  const { data, error, loading } = useAcademicWorksQuery();

  const academicWorks = data?.academicWorks ?? [];

  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <SABreadcrumbs translateLastPathName />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AcademicWorksTable academicWorks={academicWorks} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
