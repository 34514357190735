import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { VisibleTotalSearchResultCount } from '../../../../pages/app/search/types';

interface Props {
  countSearchResults: VisibleTotalSearchResultCount;
  checked: boolean;
  handleFilterChange: (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  label: string;
  name: string;
}

export const SearchResultsFilterAccordionCheckbox = ({
  checked,
  countSearchResults,
  handleFilterChange,
  label,
  name,
}: Props): JSX.Element => {
  const visibleSearchCount = countSearchResults.visibleCount;
  const totalSearchCount = countSearchResults.totalCount;

  const noResults = totalSearchCount < 1;

  return (
    <Grid item xs={12} container justify="space-between" alignItems="center">
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            onChange={handleFilterChange}
            name={name}
            disabled={noResults}
            checked={checked}
          />
        }
        label={label}
        labelPlacement="end"
      />
      <Box color={noResults ? 'text.disabled' : ''}>
        {visibleSearchCount}/{totalSearchCount}
      </Box>
    </Grid>
  );
};
