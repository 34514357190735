import { SupportedLanguage } from '../../graphql/models';
import { ResourcePath } from './path-types';

export class NavigationUtil {
  public static publicResourceLinkFrom(
    resource: ResourcePath,
    id: number,
    preferredLanguage: SupportedLanguage,
    availableLanguages: SupportedLanguage[]
  ): string {
    const language = availableLanguages.includes(preferredLanguage)
      ? preferredLanguage
      : availableLanguages[0];

    return ['', resource, id, language].join('/');
  }

  public static resourceResultRoute(resource: ResourcePath): string {
    return resource + '/:id/:lang';
  }
}
