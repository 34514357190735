import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(6),
      },
    },
  })
);

interface Props {
  children?: React.ReactNode;
}

export const DevelopmentWarningBanner = ({ children }: Props): JSX.Element => {
  const classes = useStyles();

  const open = Boolean(
    window.location.pathname?.match(/\/staging\/|\/review\//g)
  );

  return (
    <>
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
        >
          <Alert severity="error">
            This website is for testing only! The data can be deleted at any
            time.
          </Alert>
        </Snackbar>
      </div>
      {children}
    </>
  );
};
