/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createMuiTheme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import FranklinGothicRegular from '../assets/fonts/Franklin-Gothic-book-normal.woff';

// https://material-ui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MUIDataTable: unknown;
    MUIDataTableBodyCell: unknown;
  }
}

const franklinGothicRegular: CSSProperties['@font-face'] = {
  fontFamily: 'Franklin Gothic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
  url(${FranklinGothicRegular}) format('woff')
  `,
};

const backgroundColorDefault = '#FFF';
const backgroundColorPaper = '#F8F8F8';

export const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Franklin Gothic"', 'Helvetica', 'Arial', 'sans-serif'].join(
      ', '
    ),
  },
  palette: {
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#FFFFFF',
    },
    background: {
      default: backgroundColorDefault,
      paper: backgroundColorPaper,
    },
    error: {
      main: '#ED6A5A',
    },
    success: {
      main: '#679436',
    },
    info: {
      main: '#5CA4A9',
    },
    warning: {
      main: '#FEC601',
    },
    text: {
      primary: '#000000',
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: backgroundColorDefault,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [franklinGothicRegular],
      },
    },
    MUIDataTable: {
      root: {
        backgroundColor: backgroundColorDefault,
      },
      paper: {
        backgroundColor: backgroundColorDefault,
        boxShadow: 'none',
      },
    },
    MUIDataTableBodyCell: {
      root: { cursor: 'pointer' },
    },
  },
});
