import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { handleContentClick } from '../../../common/resources';
import { useRoutes } from '../../../common/routing/routes-hook';
import {
  SearchResultDataFragment,
  SupportedLanguage,
} from '../../../graphql/models';
import {
  getTagDescriptionTooltipProps,
  getTranslatedTagNameOrDefault,
} from '../../../model/Tag';
import { ResourceType } from '../../../pages/app/search/types';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { TagChip } from '../../tag/TagChip';

const useStyles = makeStyles((theme) => ({
  metaData: {
    fontStyle: 'italic',
  },
  resourceTypeChip: {
    backgroundColor: theme.palette.info.light,
    color: 'white',
  },
  summary: {
    display: 'flex-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& p ': {
      margin: 0,
      padding: 0,
    },
  },
  chip: {
    marginRight: theme.spacing(LayoutConstants.tagSpacing),
    marginBottom: theme.spacing(LayoutConstants.tagSpacing),
  },
}));

interface Props {
  result: SearchResultDataFragment;
  resourceType: ResourceType;
  isFirst: boolean;
}

export const SearchResultEntry = ({
  result,
  resourceType,
  isFirst,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { routes } = useRoutes();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const resolveEntryUrl = (
    entryId: number,
    entryType: ResourceType
  ): { url: string; label: string } => {
    switch (entryType) {
      case 'summaries': {
        return {
          label: t('commons.summary'),
          url: routes.summaryResult.pathWithArguments({
            id: String(entryId),
            lang: result.language,
          }),
        };
      }
      case 'articles': {
        return {
          label: t('commons.article'),
          url: routes.articleResult.pathWithArguments({
            id: String(entryId),
            lang: result.language,
          }),
        };
      }
      case 'links': {
        return {
          label: t('commons.links'),
          url: routes.linkResult.pathWithArguments({
            id: String(entryId),
            lang: result.language,
          }),
        };
      }
      case 'books': {
        return {
          label: t('commons.books'),
          url: routes.bookResult.pathWithArguments({
            id: String(entryId),
            lang: result.language,
          }),
        };
      }
      case 'academicWorks': {
        return {
          label: t('commons.academicWorks'),
          url: routes.academicWorkResult.pathWithArguments({
            id: String(entryId),
            lang: result.language,
          }),
        };
      }
    }
  };

  const { label, url } = resolveEntryUrl(result.id, resourceType);

  return (
    <Box mb={2} mt={!isFirst ? 2 : 0}>
      <Box mb={1} style={{ width: '100%' }} display="flex">
        <Box flexGrow={1} className={classes.metaData}>
          {result?.metadata}
        </Box>
        <Box>
          <Chip
            label={label}
            className={classes.resourceTypeChip}
            size="small"
          />
        </Box>
      </Box>
      <Box>
        {result.tags
          ?.sort((a, b) => a.category.priority - b.category.priority)
          .map((tag, index) => {
            return (
              <TagChip
                key={index}
                className={classes.chip}
                label={getTranslatedTagNameOrDefault(
                  tag,
                  i18n.language as SupportedLanguage
                )}
                hoverValue={getTagDescriptionTooltipProps(tag, i18n, t)}
                color={tag.category.color}
              />
            );
          })}
      </Box>
      <Link to={url} color={'info'}>
        <Typography
          variant="subtitle1"
          align="left"
          color="inherit"
          gutterBottom
        >
          <Box fontWeight="fontWeightMedium" display="inline">
            {result?.title}
          </Box>
        </Typography>
      </Link>
      <div
        className={classes.summary}
        onClick={handleContentClick(history)}
        dangerouslySetInnerHTML={{
          __html: result?.summary ?? '',
        }}
      />
    </Box>
  );
};
