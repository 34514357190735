import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { LayoutConstants } from '../../../theme/LayoutConstants';

export const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    chip: {
      marginRight: theme.spacing(LayoutConstants.tagSpacing),
      marginBottom: theme.spacing(LayoutConstants.tagSpacing),
    },
  })
);
