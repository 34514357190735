import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { LayoutConstants } from '../../../theme/LayoutConstants';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const GenericPaper = ({ children, className }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Paper
      variant="outlined"
      elevation={LayoutConstants.elevation}
      className={`${className ?? ''} ${classes.paperRoot}`}
    >
      {children}
    </Paper>
  );
};
