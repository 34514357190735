import React from 'react';
import { useTranslation } from 'react-i18next';
import { Exact, SupportedLanguage } from '../../../graphql/models';
import * as Yup from 'yup';
import * as Apollo from '@apollo/client';
import { Resource, ResourceInput } from '../types';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { buildYupLocaleObject } from '../../../common/yup-validation';
import { SUPPORTED_LANGUAGES_OBJECT } from '../../../common/supported-languages';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBeforeFormSubmitErrorHandler } from './useBeforeFormSubmitErrorHandler';
import { useAfterFormSubmitHandler } from './useAfterFormSubmitHandler';
import { ResourceType } from '../../../pages/app/search/types';

interface Props<CreateDataType, UpdateDataType, InputType> {
  resource?: Resource;
  resourceName: ResourceType;
  validationSchema: Yup.AnyObjectSchema;
  createMutation: Apollo.MutationFunction<
    CreateDataType,
    Exact<{ input: InputType }>
  >;
  updateMutation: Apollo.MutationFunction<
    UpdateDataType,
    Exact<{ id: number; input: InputType }>
  >;
  query: Apollo.DocumentNode;
  additionalInitialValues?: Omit<
    InputType,
    'originalLanguage' | 'content' | 'authors' | 'tagIds'
  >;
}

export const useSetupResourceForm = <
  InputType extends ResourceInput,
  CreateDataType,
  UpdateDataType
>({
  resource,
  resourceName,
  validationSchema,
  createMutation,
  updateMutation,
  query,
  additionalInitialValues,
}: Props<CreateDataType, UpdateDataType, InputType>): [
  UseFormReturn<ResourceInput>,
  (e?: React.BaseSyntheticEvent) => Promise<void>,
  () => void,
  string
] => {
  const { t } = useTranslation();
  const defaultOriginalLanguage =
    resource?.originalLanguage || SupportedLanguage.En;
  const [selectedOriginalLanguage, setSelectedOriginalLanguage] =
    React.useState(defaultOriginalLanguage);
  const firstUpdate = React.useRef(true);

  Yup.setLocale(buildYupLocaleObject(t));

  const defaultContents: ResourceInput['content'] =
    SUPPORTED_LANGUAGES_OBJECT.map((language) => {
      return {
        title: '',
        abstract: '',
        content: '',
        description: '',
        summary: '',
        text: '',
        language: language.locale,
        translator: '',
      };
    });

  const content = defaultContents.map((contentTranslation) => {
    return (
      findTranslation(contentTranslation, resource?.content) ??
      contentTranslation
    );
  }) as Resource['content'];

  const initialValues: Omit<ResourceInput, 'id'> = {
    authors: resource?.authors.map((author) => ({ name: author.name })) || [
      { name: '' },
    ],
    originalLanguage: selectedOriginalLanguage,
    content,
    tagIds: resource?.tags?.map((tag) => tag.id) || [],
    ...additionalInitialValues,
  };

  const form = useForm<ResourceInput>({
    mode: 'onTouched',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    context: { originalLanguage: selectedOriginalLanguage },
  });

  const { handleSubmit, control } = form;
  const { trigger } = form;

  const newSelectedOriginalLanguage = useWatch({
    control,
    name: 'originalLanguage' as const,
  });

  React.useEffect(() => {
    setSelectedOriginalLanguage(newSelectedOriginalLanguage);
  }, [newSelectedOriginalLanguage]);

  const contentKeys =
    [] as Array<`content.${number}.${keyof typeof initialValues.content[0]}`>;

  initialValues.content.forEach((cnt, index) => {
    Object.keys(cnt).forEach((contentKey) => {
      if (contentKey !== '__typename') {
        const typedKey = `content.${index}.${
          contentKey as keyof Omit<Resource['content'][0], '__typename'>
        }` as const;

        contentKeys.push(typedKey);
      }
    });
  });

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    //We want to revalidate the whole content form whenever the original language changes.
    void trigger(contentKeys);

    // We don't want to rerender, just because contentKeys gets a new reference.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOriginalLanguage, trigger]);

  const [beforeSubmitErrorMessage, handleBeforeSubmitError] =
    useBeforeFormSubmitErrorHandler();

  const handleAfterFormSubmit = useAfterFormSubmitHandler(
    resource,
    resourceName,
    validationSchema,
    createMutation,
    updateMutation,
    query
  );

  return [
    form,
    handleSubmit(handleAfterFormSubmit, handleBeforeSubmitError),
    () =>
      form.reset({
        ...initialValues,
        originalLanguage: defaultOriginalLanguage,
      }),
    beforeSubmitErrorMessage,
  ];
};

type GenericContent = Record<string, unknown> & { language: SupportedLanguage };

const findTranslation = <ContentInputType extends Array<GenericContent>>(
  defaultValue: GenericContent,
  content?: ContentInputType
) => {
  const findBy = (value: GenericContent) =>
    value.language === defaultValue.language;
  return content?.find(findBy) ?? defaultValue;
};
