import * as React from 'react';

interface CodeLoungeIconProps {
  mode?: 'dark' | 'light';
  className?: string;
}

export const CodeLoungeIcon = (props: CodeLoungeIconProps): JSX.Element => {
  const secondaryColor = props.mode === 'dark' ? '#FFFFFF' : '#222446';

  return (
    <svg
      id="prefix__logo"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="-50 189.4 398.1 122.6"
      xmlSpace="preserve"
      {...props}
    >
      <style>{`.prefix__st0{fill:#58b947}.prefix__st1{fill:${secondaryColor}}`}</style>
      <g id="prefix__typography">
        <g id="prefix__lounge">
          <path
            id="prefix__e_1_"
            className="prefix__st0"
            d="M345.1 241.7c2 2.3 3 5.6 3 9.8v.8c0 .4-.4.7-.7.7h-19.6c.4 2.3 1.3 4.1 2.9 5.5 1.5 1.4 3.5 2 5.8 2 1.5 0 2.9-.2 4.2-.8 1.1-.5 2-1.1 2.8-1.8.4-.4.8-.2 1.2 0l1.3 1.4c.4.4.4.9 0 1.3-1.2 1.1-2.5 1.9-4 2.6-1.8.7-3.7 1.2-6 1.2-2.6 0-4.8-.6-6.8-1.6-2-1.1-3.5-2.6-4.6-4.6-1.1-2-1.6-4.2-1.6-6.8 0-2.6.6-4.8 1.6-6.8 1.1-2 2.6-3.5 4.6-4.7 2-1.2 4.2-1.6 6.7-1.6 4.1-.1 7.2 1.1 9.2 3.4zm-1.1 7.8c-.1-2.3-.9-4.2-2.3-5.5-1.4-1.4-3.3-2-5.6-2-2.2 0-4.2.7-5.6 2-1.5 1.4-2.5 3.2-2.7 5.5H344z"
          />
          <path
            id="prefix__g"
            className="prefix__st0"
            d="M319.9 238.4c.5 0 .8.4.8.8v22.2c0 2.5-.6 4.7-1.6 6.4-1.1 1.9-2.6 3.3-4.6 4.2-2 .9-4.3 1.5-6.9 1.5-3.5 0-6.9-1.1-9.9-3.2-.4-.2-.5-.8-.2-1.2l1.1-1.8c.2-.5.8-.6 1.3-.2 1.1.7 2.1 1.3 3.2 1.8 1.4.5 2.8.7 4.4.7 2.7 0 4.8-.7 6.3-2.2 1.6-1.5 2.3-3.5 2.3-6v-3.6c-.9 1.5-2.1 2.8-3.7 3.6-1.5.8-3.4 1.3-5.4 1.3-2.3 0-4.3-.6-6.1-1.6s-3.2-2.6-4.1-4.4c-.9-1.9-1.4-4.1-1.4-6.4 0-2.5.5-4.6 1.4-6.3.9-1.9 2.3-3.3 4.1-4.3s3.9-1.5 6.1-1.5c2 0 3.9.5 5.4 1.3s2.8 2 3.6 3.6v-3.9c0-.5.4-.8.8-.8h3.1zm-7.7 19.8c1.3-.7 2.2-1.8 2.9-3 .7-1.3 1.1-2.8 1.1-4.4 0-1.6-.4-3.2-1.1-4.4-.7-1.3-1.6-2.3-2.9-3-1.3-.7-2.7-1.2-4.2-1.2-1.6 0-3 .4-4.2 1.2-1.3.7-2.2 1.8-2.9 3-.7 1.3-1.1 2.8-1.1 4.4 0 2.6.8 4.6 2.3 6.2 1.5 1.6 3.5 2.5 6 2.5 1.5-.3 2.9-.6 4.1-1.3z"
          />
          <path
            id="prefix__n"
            className="prefix__st0"
            d="M290.2 240.9c1.8 1.8 2.6 4.2 2.6 7.4v15c0 .5-.4.8-.8.8h-2.8c-.5 0-.8-.4-.8-.8v-13.8c0-2.2-.6-3.9-1.9-5-1.2-1.2-2.9-1.9-5-1.9-2.3 0-4.2.8-5.6 2.2-1.4 1.4-2.2 3.3-2.5 5.6v12.9c0 .5-.4.8-.8.8h-2.8c-.5 0-.8-.4-.8-.8v-24c0-.5.4-.8.8-.8h2.8c.5 0 .8.4.8.8v4.6c1.8-3.6 5-5.5 9.8-5.6 3.1-.1 5.4.8 7 2.6z"
          />
          <path
            id="prefix__u"
            className="prefix__st0"
            d="M264.4 239.2v24c0 .5-.4.8-.8.8h-2.9c-.5 0-.8-.4-.8-.8v-4.6c-1.6 3.6-4.9 5.5-9.6 5.6-3 0-5.3-.9-7-2.7-1.6-1.8-2.6-4.2-2.6-7.4v-15c0-.5.4-.8.8-.8h2.9c.5 0 .8.4.8.8V253c0 2.2.6 3.9 1.8 5s2.8 1.9 4.9 1.9c2.5 0 4.3-.9 5.7-2.6 1.4-1.6 2.1-3.9 2.1-6.4v-11.7c0-.5.4-.8.8-.8h2.9c.7 0 1 .4 1 .8z"
          />
          <path
            id="prefix__o_1_"
            className="prefix__st0"
            d="M231.5 239.8c2 1.1 3.5 2.7 4.7 4.6 1.2 2 1.6 4.2 1.6 6.8 0 2.6-.6 4.8-1.6 6.8-1.2 2-2.7 3.5-4.7 4.7-2 1.1-4.3 1.6-6.8 1.6-2.6 0-4.9-.6-6.9-1.6-2-1.1-3.5-2.7-4.7-4.6-1.2-2-1.6-4.2-1.6-6.8 0-2.6.6-4.8 1.6-6.8 1.2-2 2.7-3.5 4.7-4.6s4.3-1.6 6.9-1.6c2.5-.1 4.8.5 6.8 1.5zm-11.4 3.5c-1.3.8-2.3 1.9-3 3.3-.7 1.4-1.1 2.9-1.1 4.7 0 1.8.4 3.4 1.1 4.8.7 1.4 1.8 2.5 3 3.3s2.8 1.2 4.4 1.2c1.6 0 3.2-.4 4.4-1.2 1.3-.8 2.3-1.9 3-3.3.7-1.4 1.1-3 1.1-4.8s-.4-3.3-1.1-4.7c-.7-1.4-1.8-2.5-3-3.3-1.3-.8-2.8-1.2-4.4-1.2-1.6.1-3 .4-4.4 1.2z"
          />
          <path
            id="prefix__l"
            className="prefix__st0"
            d="M203.9 228h2.8c.5 0 .8.4.8.8v34.4c0 .5-.4.8-.8.8h-2.8c-.5 0-.8-.4-.8-.8v-34.4c-.1-.4.3-.8.8-.8z"
          />
        </g>
        <g id="prefix__code">
          <path
            id="prefix__e"
            className="prefix__st1"
            d="M199 251.4c0-4.2-1.1-7.6-3.2-9.8-2.1-2.3-5.1-3.5-9.2-3.5-2.6 0-4.8.6-6.8 1.6-2 1.1-3.5 2.7-4.7 4.7-1.1 2-1.6 4.3-1.6 6.8 0 2.6.6 4.8 1.6 6.8 1.1 2 2.7 3.5 4.7 4.6 2 1.1 4.3 1.6 7 1.6 2.2 0 4.3-.4 6.2-1.2 1.6-.7 3-1.5 4.2-2.7.2-.2.2-.7 0-.9l-2.3-2.5c-.2-.2-.7-.2-.9 0-.8.7-1.6 1.3-2.7 1.6-1.2.5-2.6.8-3.9.8-2 0-3.6-.6-4.9-1.6-1.3-1.1-2.2-2.6-2.7-4.4v-.1H198c.6 0 1.1-.5 1.1-1.1-.1-.2-.1-.5-.1-.7zm-19.4-2.2v-.1c.4-2 1.1-3.5 2.3-4.7 1.3-1.2 2.8-1.8 4.8-1.8s3.6.6 4.9 1.8c1.2 1.2 1.9 2.7 2 4.7v.1h-14z"
          />
          <path
            id="prefix__d"
            className="prefix__st1"
            d="M165.1 263.9h4.7c.4 0 .7-.4.7-.7v-34.4c0-.4-.4-.7-.7-.7h-4.7c-.4 0-.7.4-.7.7V242s-2.1-4.1-9-4.1c-2.3 0-4.4.6-6.3 1.6-1.9 1.1-3.3 2.6-4.3 4.6-1.1 2-1.5 4.2-1.5 6.8 0 2.6.5 4.9 1.5 6.9 1.1 2 2.5 3.5 4.3 4.7 1.9 1.1 4 1.6 6.4 1.6 1.9 0 3.6-.4 5-1.2 1.4-.7 2.7-1.9 3.6-3.3l.2-.4v3.9c.2.6.4.8.8.8zm-2.7-6.9c-1.4 1.5-3.3 2.2-5.4 2.2-2.2 0-4-.8-5.4-2.2-1.4-1.5-2.1-3.5-2.1-5.8s.7-4.3 2.1-5.8c1.4-1.5 3.2-2.2 5.4-2.2s4 .7 5.4 2.2c1.4 1.5 2.1 3.5 2.1 5.8s-.7 4.3-2.1 5.8z"
          />
          <path
            id="prefix__o"
            className="prefix__st1"
            d="M139.1 257.9c1.2-2 1.8-4.3 1.8-6.9 0-2.6-.6-4.8-1.8-6.8-1.2-2-2.7-3.5-4.8-4.6-2-1.1-4.4-1.6-7-1.6-2.7 0-5.1.6-7.1 1.6-2 1.1-3.6 2.6-4.8 4.6-1.2 2-1.8 4.2-1.8 6.8 0 2.6.6 4.9 1.8 6.9 1.2 2 2.7 3.5 4.8 4.6 2 1.1 4.4 1.6 7.1 1.6 2.7 0 5-.6 7-1.6 2.1-1.1 3.8-2.6 4.8-4.6zm-6.4-1c-1.4 1.5-3.2 2.2-5.4 2.2s-4.1-.7-5.5-2.2c-1.4-1.5-2.1-3.4-2.1-5.7 0-2.3.7-4.3 2.1-5.7 1.4-1.5 3.3-2.2 5.5-2.2s4 .7 5.4 2.2c1.4 1.5 2.1 3.4 2.1 5.7 0 2.3-.7 4.3-2.1 5.7z"
          />
          <path
            id="prefix__c"
            className="prefix__st1"
            d="M112.2 259.6c.2-.2.1-.7-.1-.9l-2.7-2.2c-.2-.2-.7-.2-.9.1-1.5 1.6-3.7 2.5-6.5 2.5-2.1 0-3.9-.7-5.3-2.2-1.3-1.4-2-3.4-2-5.7 0-2.3.7-4.2 2-5.6 1.3-1.4 3-2.2 5.3-2.2 2.6 0 4.7.8 6.3 2.3.2.2.7.2.9 0l2.7-2.6c.2-.2.2-.7.1-.9-1.1-1.2-2.5-2.2-4-2.9-1.9-.8-4-1.2-6.3-1.2-2.6 0-4.8.6-6.8 1.6-2 1.1-3.5 2.7-4.6 4.6-1.1 2-1.6 4.3-1.6 6.9 0 2.6.6 4.8 1.6 6.8 1.1 2 2.6 3.5 4.6 4.6s4.2 1.6 6.8 1.6c2.5 0 4.7-.5 6.5-1.4 1.6-.8 3-1.8 4-3.2z"
          />
        </g>
      </g>
      <g id="prefix__icon">
        <g id="prefix__brackets">
          <path
            id="prefix__XMLID_842_"
            className="prefix__st1"
            d="M67.7 240.6v-8.9c0-5.8-4.8-10.5-10.5-10.5v5.6c2.7 0 4.9 2.2 4.9 4.9v8.9c0 3.5 1.5 6.7 4 8.8-2.5 2.1-4 5.3-4 8.8v11.5c0 2.7-2.2 4.9-4.9 4.9v5.6c5.8 0 10.5-4.7 10.5-10.5v-11.5c0-3.3 2.7-6 6-6v-5.6c-3.3 0-6-2.7-6-6z"
          />
          <path
            id="prefix__XMLID_843_"
            className="prefix__st1"
            d="M-44 231.8v8.9c0 3.3-2.7 6-6 6v5.6c3.3 0 6 2.7 6 6v11.5c0 5.8 4.7 10.5 10.5 10.5v-5.6c-2.7 0-4.9-2.2-4.9-4.9v-11.5c0-3.5-1.5-6.7-4-8.8 2.5-2.1 4-5.3 4-8.8v-8.9c0-2.7 2.2-4.9 4.9-4.9v-5.6c-5.7-.1-10.5 4.6-10.5 10.5z"
          />
        </g>
        <path
          id="prefix__sofa"
          className="prefix__st0"
          d="M49.9 240.6v-8.9c0-5.8-4.7-10.5-10.5-10.5h-55.2c-5.8 0-10.5 4.8-10.5 10.5v8.9c0 3.3-2.7 6-6 6v5.6c3.3 0 6 2.7 6 6v11.5c0 5.8 4.7 10.5 10.5 10.5h55.2c5.8 0 10.5-4.7 10.5-10.5v-11.5c0-3.3 2.7-6 6-6v-5.6c-3.3 0-6-2.7-6-6zm-70.6 28.9V258c0-2.2-.6-4.2-1.6-6h3c3.3 0 6 2.7 6 6v16.4h-2.3c-2.8.1-5.1-2.1-5.1-4.9zm13-3h39.2v8.1H-7.7v-8.1zm52-8.4v11.5c0 2.7-2.2 4.9-4.9 4.9h-2.3v-16.4c0-3.3 2.7-6 6-6h3c-1.1 1.9-1.8 3.9-1.8 6zm-1.4-11.5c-6.3 0-11.6 5.1-11.6 11.6v2.7h-39v-2.8c0-6.3-5.1-11.6-11.6-11.6h-3c1.1-1.8 1.6-3.7 1.6-6v-8.9c0-2.7 2.2-4.9 4.9-4.9h55.2c2.7 0 4.9 2.2 4.9 4.9v8.9c0 2.2.6 4.2 1.6 6h-3v.1z"
        />
      </g>
    </svg>
  );
};
