import React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: (props: { fullPage: boolean }): string =>
        props.fullPage ? '100vh' : '',
    },
  })
);

interface Props {
  message?: string;
  fullPage?: boolean;
}

export const ProgressBox = ({
  message,
  fullPage = false,
}: Props): JSX.Element => {
  const classes = useStyles({ fullPage });

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
      {message ? (
        <Typography variant="h6" gutterBottom align="center">
          {message}
        </Typography>
      ) : null}
    </Box>
  );
};
