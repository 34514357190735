import { useParams } from 'react-router-dom';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';
import { AcademicWorkCard } from '../../../components/academic-work/AcademicWorkCard';
import {
  SupportedLanguage,
  useAcademicWorkQuery,
} from '../../../graphql/models';
import {
  GenericResourceContent,
  ResourcePageContent,
} from '../../../components/common/resources/page/ResourcePageContent';
import { ResourcePageCard } from '../../../components/common/resources/page/ResourcePageCard';
import { ResourcePageLayout } from '../../../components/common/resources/page/ResourcePageLayout';

export const AcademicWorkPage = (): JSX.Element => {
  const { id } = useParams<{ id: string; lang: SupportedLanguage }>();
  const idNumber = Number(id);

  const { data, error, loading } = useAcademicWorkQuery({
    variables: { id: idNumber },
  });

  if (!data) {
    return <ApiRequestErrorAndLoadingWrapper error={error} loading={loading} />;
  }

  const content: GenericResourceContent = data.academicWork.content.map(
    (cnt) => ({
      language: cnt.language,
      title: cnt.title,
      body: cnt.text,
    })
  );

  return (
    <ResourcePageLayout>
      <ResourcePageContent
        authors={data.academicWork.authors ?? []}
        content={content}
      />
      <ResourcePageCard>
        <AcademicWorkCard academicWork={data.academicWork} />
      </ResourcePageCard>
    </ResourcePageLayout>
  );
};
