import {
  Container,
  Grid,
  GridDirection,
  GridItemsAlignment,
  GridSize,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { LayoutConstants } from '../../theme/LayoutConstants';
import { SITE_NAME } from '../../config';
import { SearchForm } from '../../components/common/form/search/SearchForm';
import { SearchInstructions } from '../../components/search/SearchInstructions';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(8, 0, 6),
  },
  // expand input fields over full width
  fullWidth: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .searchButton': {
      width: '100%',
    },
  },
  searchInstructions: {
    marginTop: theme.spacing(5),
  },
}));

export const SearchPage = (): JSX.Element => {
  const classes = useStyles();

  const searchFormProps = {
    inputSize: 'medium' as const,
    gridItemProps: {
      className: classes.fullWidth,
    },
    searchButtonGridProps: {
      xs: 6 as GridSize,
    },
    searchButtonProps: {
      size: 'large' as const,
    },
    gridContainerProps: {
      spacing: LayoutConstants.gridSpacing,
      direction: 'column' as GridDirection,
      alignItems: 'center' as GridItemsAlignment,
    },
    resetFormOnMount: true,
  };

  return (
    <Container className={classes.heroContent}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {SITE_NAME}
          </Typography>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Grid item md={6} sm={8} xs={10}>
            <SearchForm {...searchFormProps} />
          </Grid>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Grid
            item
            md={6}
            sm={8}
            xs={10}
            className={classes.searchInstructions}
          >
            <SearchInstructions />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
