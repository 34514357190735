import { Controller, useFormContext } from 'react-hook-form';
import { TagsAutocomplete } from './TagsAutocomplete';
interface Props {
  name: string;
  label?: string;
  size?: 'medium' | 'small';
  tagsListClassName?: string;
}

export const TagsAutocompleteInput = ({
  name,
  label,
  size,
  tagsListClassName,
}: Props): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ref, ...rest } }) => (
        <TagsAutocomplete
          {...rest}
          tagsListClassName={tagsListClassName}
          value={value as number[]}
          label={label}
          size={size}
          inputRef={ref}
        />
      )}
    />
  );
};
