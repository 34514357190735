export const titleCase = (str: string, separator = ' '): string => {
  return str
    .toLowerCase()
    .split(separator)
    .map((wrd) => wrd.charAt(0).toUpperCase() + wrd.substring(1))
    .join(' ');
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const kebabCaseToCamelCase = (str: string): string => {
  return str
    .split('-')
    .map((value, index) => (index > 0 ? capitalizeFirstLetter(value) : value))
    .join('');
};
