import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import { useRoutes } from '../../../common/routing/routes-hook';

export const BackToResults = (): JSX.Element => {
  const { t } = useTranslation();
  const { routes } = useRoutes();
  const history = useHistory();

  const goToResults = (): void => {
    history.push(routes.search.path);
  };

  return (
    <Button size="small" startIcon={<ArrowBackIosIcon />} onClick={goToResults}>
      {t('commons.results')}
    </Button>
  );
};
