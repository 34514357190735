import { Redirect, RouteProps, Route as ReactRoute } from 'react-router-dom';
import {
  useHasRole,
  UserRole,
} from '../../common/access-control/has-role-hook';

interface Props extends Omit<RouteProps, 'render' | 'component'> {
  roles: UserRole[];
}

export const AuthorizedRoute = ({
  children,
  roles,
  ...restProps
}: Props): JSX.Element => {
  const userHasRole = useHasRole(roles);

  const render: RouteProps['render'] = ({ location }) =>
    userHasRole ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );

  return <ReactRoute {...restProps} render={render} />;
};
