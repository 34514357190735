import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import { ArticleCard } from '../../../components/article/ArticleCard';
import { SupportedLanguage, useArticleQuery } from '../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';
import { ResourcePageCard } from '../../../components/common/resources/page/ResourcePageCard';
import {
  GenericResourceContent,
  ResourcePageContent,
} from '../../../components/common/resources/page/ResourcePageContent';
import { ResourcePageLayout } from '../../../components/common/resources/page/ResourcePageLayout';

export const ArticlePage = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string; lang: SupportedLanguage }>();
  const idNumber = Number(id);

  const { data, error, loading } = useArticleQuery({
    variables: { id: idNumber },
  });

  if (!data || !data.article) {
    return <ApiRequestErrorAndLoadingWrapper error={error} loading={loading} />;
  }

  const content: GenericResourceContent = data.article.content.map((cnt) => ({
    language: cnt.language,
    title: cnt.title,
    body: cnt.abstract,
  }));

  return (
    <ResourcePageLayout>
      <ResourcePageContent
        authors={data.article.authors ?? []}
        content={content}
      >
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            target="_blank"
            href={data.article.externalLink ?? '#'}
          >
            {t('phrases.readFullText')}
          </Button>
        </Grid>
      </ResourcePageContent>
      <ResourcePageCard>
        <ArticleCard article={data.article} />
      </ResourcePageCard>
    </ResourcePageLayout>
  );
};
