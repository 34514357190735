import React from 'react';

import { Box, Grid } from '@material-ui/core';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { SummariesTable } from '../../../components/summary/table/SummariesTable';
import { SABreadcrumbs } from '../../../components/common/navigation/breadcrumbs/Breadcrumbs';
import { useSummariesQuery } from '../../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';

export const SummariesTablePage = (): JSX.Element => {
  const { data, error, loading } = useSummariesQuery();

  const summaries = data?.summaries ?? [];
  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <SABreadcrumbs translateLastPathName />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <SummariesTable summaries={summaries} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
