import { Paper } from '@material-ui/core';
import { TFunction } from 'i18next';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableProps,
} from 'mui-datatables';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTableStyles } from './styles';
import { tableOptions } from './table-options';

export const GenericTable = ({
  title,
  data,
  columns,
  options = {},
}: MUIDataTableProps): JSX.Element => {
  const { t } = useTranslation();
  const [tableHeight, setTableHeight] = useState('0px');
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const classes = useTableStyles();

  const localizationOptions = getLocalizationOptions(t);

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    const currentNodeOffsetTop = tableContainerRef.current?.offsetTop ?? 0;
    setTableHeight(`${dimensions.height - currentNodeOffsetTop - 90}px`);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dimensions.height]);

  options = {
    ...tableOptions,
    ...localizationOptions,
    ...options,
    tableBodyHeight: tableHeight,
    setFilterChipProps: () => {
      return {
        className: classes.chip,
      };
    },
  };

  return (
    <div ref={tableContainerRef}>
      <Paper variant="outlined">
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </Paper>
    </div>
  );
};

const getLocalizationOptions = (t: TFunction) => {
  return {
    textLabels: {
      body: {
        noMatch: t('table.noMatch'),
        toolTip: t('table.tooltip'),
        columnHeaderTooltip: (column: MUIDataTableColumn) =>
          t('table.sortFor', { columnLabel: column.label }),
      },
      pagination: {
        next: t('table.nextPage'),
        previous: t('table.previousPage'),
        rowsPerPage: t('table.rowsPerPage'),
        displayRows: t('table.of'),
      },
      toolbar: {
        search: t('table.search'),
        downloadCsv: t('table.downloadCSV'),
        print: t('table.print'),
        viewColumns: t('table.viewColumns'),
        filterTable: t('table.filterTable'),
      },
      filter: {
        all: t('table.all'),
        title: t('table.filters'),
        reset: t('table.reset'),
      },
      viewColumns: {
        title: t('table.showColumns'),
        titleAria: t('table.showHideColumns'),
      },
      selectedRows: {
        text: t('table.rowsSelected'),
        delete: t('table.delete'),
        deleteAria: t('table.deleteSelectedRows'),
      },
    },
  };
};
