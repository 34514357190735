import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import ReactMarkdown from 'markdown-to-jsx';
import { SupportedLanguage } from '../../graphql/models';
import { useIntroductionTranslations } from './introduction/useIntroductionText';

export const Introduction = (): JSX.Element => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.split('-')[0] as SupportedLanguage;

  const { translations } = useIntroductionTranslations();

  const translation =
    translations.get(currentLanguage) ??
    translations.get(SupportedLanguage.En) ??
    '';

  return (
    <ReactMarkdown
      options={{
        overrides: {
          p: {
            component: Typography,
            props: {
              variant: 'body1',
              paragraph: true,
              align: 'justify',
            },
          },
        },
      }}
    >
      {translation}
    </ReactMarkdown>
  );
};
