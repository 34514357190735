import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { ExitToApp, Person } from '@material-ui/icons';

import { AdminSwitcherMenuItem } from './user-dropdown/AdminSwitcherMenuItem';
import { useCurrentUser } from '../../../user/hooks/current-user-hook';

interface Props {
  menuSize: number;
}

const UserMenuDropdown = ({ menuSize }: Props): JSX.Element => {
  const { userProfile, keycloak } = useCurrentUser();

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const handleClick = (event: React.MouseEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = async (): Promise<void> => {
    await keycloak.logout();
  };

  return (
    <>
      <Button onClick={handleClick} startIcon={<Person />} color="inherit">
        {userProfile?.username}
      </Button>
      <Menu
        anchorEl={anchorEl}
        onBackdropClick={(): void => {
          setAnchorEl(null);
        }}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: menuSize,
          },
        }}
      >
        <div>
          <AdminSwitcherMenuItem handleClose={handleClose} />
        </div>
        <MenuItem onClick={handleLogout} value={1}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

export { UserMenuDropdown };
