import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { FormHeaderWithSubmit } from '../../common/form/FormHeaderWithSubmit';
import { GenericSnackbar } from '../../common/feedback/GenericSnackbar';
import { useFormStyles } from '../../common/form/styles';
import { ResourceInput } from '../types';

interface Props<InputType> {
  formTitle: string;
  form: UseFormReturn<InputType>;
  handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  handleReset: () => void;
  metaForm: React.ReactElement;
  contentForm: React.ReactElement;
  errorMessage: string;
}

export const ResourceForm = <InputType extends ResourceInput>({
  formTitle,
  form,
  handleSubmit,
  handleReset,
  metaForm,
  contentForm,
  errorMessage,
}: Props<InputType>): JSX.Element => {
  const classes = useFormStyles();

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={LayoutConstants.gridSpacing}
          className={classes.root}
        >
          <Grid item xs={12}>
            <FormHeaderWithSubmit
              title={formTitle}
              handleFormReset={handleReset}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} className={classes.noMarginBottom}>
            {metaForm}
          </Grid>
          <Grid item xs={12} className={classes.noMarginTop}>
            {contentForm}
          </Grid>
        </Grid>
      </form>
      <GenericSnackbar
        message={errorMessage}
        isOpen={errorMessage !== ''}
        severity={'error'}
      />
    </FormProvider>
  );
};
