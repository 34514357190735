import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextEditor } from '../../editor/TextEditor';

interface Props {
  name: string;
  label: string;
  defaultValue: string;
  hide?: boolean;
}

export const TextEditorInput = ({
  hide,
  name,
  label,
  defaultValue,
}: Props): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <TextEditor
            defaultValue={defaultValue}
            onChange={field.onChange}
            onBlur={field.onBlur}
            placeholder={label}
            hide={hide}
          />
        );
      }}
    />
  );
};
