import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';

import { DEPLOYMENT_ROOT_PATH } from '../config';
import { useGraphqlClient } from '../components/common/graphql/useGraphqlClient';

import { ProgressBox } from '../components/ProgressBox';
import { SearchContext } from '../components/search/SearchContext';
import { DevelopmentWarningBanner } from '../components/common/banner/DevelopmentWarningBanner';
import { IndexPage } from './IndexPage';

import '../i18n';

export function App(): JSX.Element {
  const [graphqlClient] = useGraphqlClient();

  return (
    <React.Suspense fallback={<ProgressBox fullPage={true} />}>
      <ApolloProvider client={graphqlClient}>
        <SearchContext>
          <BrowserRouter basename={DEPLOYMENT_ROOT_PATH}>
            <CssBaseline />
            <DevelopmentWarningBanner>
              <IndexPage />
            </DevelopmentWarningBanner>
          </BrowserRouter>
        </SearchContext>
      </ApolloProvider>
    </React.Suspense>
  );
}
