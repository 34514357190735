import {
  Accordion as MuiAccordion,
  AccordionProps,
  withStyles,
} from '@material-ui/core';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { theme } from '../../../theme/theme';

const borderStyle = `1px solid ${theme.palette.divider}`;

const Accordion = withStyles({
  root: {
    border: borderStyle,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: '0',
    },
  },
})(MuiAccordion);

interface Props {
  children: AccordionProps['children'];
  className?: string;
}

export const GenericAccordion = ({
  className,
  children,
}: Props): JSX.Element => {
  return (
    <Accordion
      className={className}
      elevation={LayoutConstants.elevation}
      variant="outlined"
    >
      {children}
    </Accordion>
  );
};
