import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AddEntityToolbarButton } from '../../common/table/AddEntityToolbarButton';
import {
  BooksTableQuery,
  SupportedLanguage,
  useDeleteBookMutation,
} from '../../../graphql/models';
import { QUERY_BOOKS } from '../../../graphql/queries/book/books';
import { getTranslatedTagNameOrDefault } from '../../../model/Tag';
import { QUERY_BOOKS_TABLE } from '../../../graphql/queries/book/books-table';
import { GenericTable } from '../../common/table/GenericTable';
import DeleteConfirmationDialog from '../../common/table/DeleteConfirmationDialog';
import React from 'react';
import { RowsToBeDeleted } from '../../common/table/types';
import {
  ResourcesTableRow,
  ResourcesTableRowWithTags,
  useResourcesTableColumns,
} from '../../common/table/resources/resources-table-columns';
import { NavigationUtil } from '../../../common/navigation/NavigationUtil';
import { ResourcePath } from '../../../common/navigation/path-types';
import { useRoutes } from '../../../common/routing/routes-hook';
import { languageCompare } from '../../../common/supported-languages';

interface Props {
  books: BooksTableQuery['books'];
}

interface BookTableRow extends ResourcesTableRow {
  ISBN: string;
  year: number;
}

type BookTableRowWithTags = Omit<BookTableRow, 'tags'> &
  ResourcesTableRowWithTags;

export const BooksTable = ({ books }: Props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { routes } = useRoutes();
  const history = useHistory();

  const [rowsToBeDeleted, setRowsToBeDeleted] = React.useState<
    RowsToBeDeleted | undefined
  >();

  const handleDeleteConfirmation = () => {
    if (rowsToBeDeleted) {
      deleteRows();
    }
    setRowsToBeDeleted(undefined);
  };

  const handleDeleteCancellation = () => {
    setRowsToBeDeleted(undefined);
  };

  const onAddNewBookClick = (): void => history.push(routes.booksNew.path);

  const AddBookButton = (): JSX.Element => (
    <AddEntityToolbarButton
      tooltipText={t('phrases.addNewBook')}
      onClick={onAddNewBookClick}
    />
  );

  const tableDataWithTags = books.map(
    ({ id, authors, content, ISBN, year, tags }): BookTableRowWithTags => {
      const title =
        content.find(({ language }) => languageCompare(language, i18n))
          ?.title ?? content[0]?.title;

      const formattedAuthors = authors.map((author) => author.name);
      const languages = content
        .map((translation) => translation.language)
        .sort();

      const publicLink = NavigationUtil.publicResourceLinkFrom(
        ResourcePath.book,
        id,
        i18n.language as SupportedLanguage,
        languages
      );

      return {
        id,
        title,
        tags: tags || [],
        authors: formattedAuthors,
        ISBN,
        year,
        languages,
        publicLink,
      };
    }
  );

  const tableData: BookTableRow[] = tableDataWithTags.map(
    ({ tags, ...restData }) => {
      const tagsRender = tags.map((tag) => {
        return getTranslatedTagNameOrDefault(
          tag,
          i18n.language as SupportedLanguage
        );
      });

      return {
        ...restData,
        tags: tagsRender,
      };
    }
  );

  const [
    idColumn,
    titleColumn,
    tagsColumn,
    authorsColumn,
    languagesColumn,
    publicLinkColumn,
  ] = useResourcesTableColumns(tableDataWithTags);

  const columns: MUIDataTableColumn[] = [
    idColumn,
    titleColumn,
    tagsColumn,
    authorsColumn,
    {
      name: 'ISBN',
      label: 'ISBN',
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (filterValue: string) => `ISBN: ${filterValue}`,
        },
      },
    },
    {
      name: 'year',
      label: t('commons.year'),
      options: {
        customFilterListOptions: {
          render: (filterValue: string) =>
            `${t('commons.year')}: ${filterValue}`,
        },
      },
    },
    languagesColumn,
    publicLinkColumn,
  ];

  const [deleteBookMutation] = useDeleteBookMutation();

  const deleteRows = () => {
    for (const row of rowsToBeDeleted?.data ?? []) {
      const id = books[row.dataIndex].id;
      void deleteBookMutation({
        variables: { id },
        refetchQueries: [{ query: QUERY_BOOKS }, { query: QUERY_BOOKS_TABLE }],
      });
    }
  };

  const options: MUIDataTableOptions = {
    onRowsDelete: (rowsDeleted) => {
      setRowsToBeDeleted(rowsDeleted);
    },

    onRowClick(
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) {
      history.push(
        routes.booksEdit.pathWithArguments({
          id: String(books[rowMeta.dataIndex].id),
        })
      );
    },
    customToolbar: AddBookButton,
    sortOrder: {
      name: 'id',
      direction: 'asc',
    },
  };

  return (
    <>
      <DeleteConfirmationDialog
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleDeleteCancellation={handleDeleteCancellation}
        open={rowsToBeDeleted !== undefined}
      />
      <GenericTable
        title={t('navigation.books')}
        data={tableData}
        columns={columns}
        options={options}
      />
    </>
  );
};
