import axios from 'axios';
import { useState, useEffect } from 'react';
import { SupportedLanguage } from '../../../graphql/models';

type IntroductionTranslationData = Map<SupportedLanguage, string>;

export const useIntroductionTranslations = (): {
  translations: IntroductionTranslationData;
} => {
  const [introductionTranslations, setIntroductionTranslations] =
    useState<IntroductionTranslationData>(new Map());

  useEffect(() => {
    async function fetchIntroductionTranslations(): Promise<void> {
      const translations: Map<SupportedLanguage, string> = new Map();

      for (const language of Object.values(SupportedLanguage)) {
        try {
          const response = await axios.get<string>(
            `homepage/introduction/${language}.md`
          );
          const translation = response.data;
          translations.set(language, translation);
        } catch (error) {
          // no translation
        }
      }

      setIntroductionTranslations(translations);
    }

    void fetchIntroductionTranslations();
  }, []);

  return { translations: introductionTranslations };
};
