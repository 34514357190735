import React from 'react';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AddEntityToolbarButton } from '../../common/table/AddEntityToolbarButton';
import { GenericTable } from '../../common/table/GenericTable';
import DeleteConfirmationDialog from '../../common/table/DeleteConfirmationDialog';
import { RowsToBeDeleted } from '../../common/table/types';
import {
  TagCategoriesTableQuery,
  useDeleteTagCategoryMutation,
} from '../../../graphql/models';
import { ColorPreview } from '../../common/form/color-picker/ColorPreview';
import { tagCategoriesTableToolbarSelect } from './TagCategoriesTableToolbarSelect';
import { QUERY_TAG_CATEGORIES } from '../../../graphql/queries/tag-category/tag-categories';
import { QUERY_SUMMARIES } from '../../../graphql/queries/summary/summaries';
import { QUERY_SUMMARIES_TABLE } from '../../../graphql/queries/summary/summaries-table';
import { QUERY_ARTICLES } from '../../../graphql/queries/article/articles';
import { QUERY_ARTICLES_TABLE } from '../../../graphql/queries/article/articles-table';
import { QUERY_TAGS } from '../../../graphql/queries/tag/tags';
import { useRoutes } from '../../../common/routing/routes-hook';
import { languageCompare } from '../../../common/supported-languages';

interface Props {
  tagCategories: TagCategoriesTableQuery['tagCategories'];
}

interface TagCategoryTableRow {
  priority: number;
  id: number;
  name: string;
  color: string;
  languages: string[];
}

export const TagCategoriesTable = ({ tagCategories }: Props): JSX.Element => {
  const { i18n, t } = useTranslation();
  const { routes } = useRoutes();

  const history = useHistory();

  const [rowsToBeDeleted, setRowsToBeDeleted] = React.useState<RowsToBeDeleted>(
    { lookup: {}, data: [] }
  );

  const handleDeleteConfirmation = async () => {
    if (rowsToBeDeleted) {
      await deleteRowsToBeDeleted();
    }
    setRowsToBeDeleted({ lookup: {}, data: [] });
  };

  const handleDeleteCancellation = () => {
    setRowsToBeDeleted({ lookup: {}, data: [] });
  };

  const onAddNewTagCategoryClick = (): void =>
    history.push(routes.tagCategoriesNew.path);

  const AddTagCategoryButton = (): JSX.Element => (
    <AddEntityToolbarButton
      tooltipText={t('phrases.addNewTagCategory')}
      onClick={onAddNewTagCategoryClick}
    />
  );

  const dataTable = tagCategories.map(
    ({ content, id, priority, color }): TagCategoryTableRow => {
      const categoryName =
        content.find(({ language }) => languageCompare(language, i18n))?.name ??
        content[0]?.name;

      const languages = content
        .map((translation) => translation.language)
        .sort((a, b) => a.localeCompare(b));

      return {
        id,
        name: categoryName,
        priority,
        color,
        languages,
      };
    }
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'priority',
      label: t('commons.priority'),
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (filterValue: string) => `Priority: ${filterValue}`,
        },
      },
    },
    {
      name: 'name',
      label: t('commons.name'),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'color',
      label: t('commons.color'),
      options: {
        filterType: 'textField',
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return (
            <ColorPreview
              color={dataTable[dataIndex].color}
              width={25}
              height={25}
            />
          );
        },
      },
    },
    {
      name: 'languages',
      label: t('commons.languages'),
      options: {
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return <div>{dataTable[dataIndex].languages.join(', ')}</div>;
        },
      },
    },
  ];

  const [deleteTagCategoryMutation] = useDeleteTagCategoryMutation();

  const deleteRowsToBeDeleted = async () => {
    for (const row of rowsToBeDeleted.data) {
      const id = tagCategories[row.dataIndex].id;

      await deleteTagCategoryMutation({
        variables: { id },
        refetchQueries: [
          { query: QUERY_TAG_CATEGORIES },
          { query: QUERY_TAGS },
          { query: QUERY_ARTICLES_TABLE },
          { query: QUERY_SUMMARIES_TABLE },
          { query: QUERY_ARTICLES },
          { query: QUERY_SUMMARIES },
        ],
      });
    }
  };

  const options: MUIDataTableOptions = {
    onRowsDelete: (rowsDeleted) => {
      setRowsToBeDeleted(rowsDeleted);
    },
    onRowClick(_: string[], rowMeta: { dataIndex: number; rowIndex: number }) {
      history.push(
        routes.tagCategoriesEdit.pathWithArguments({
          id: String(tagCategories[rowMeta.dataIndex].id),
        })
      );
    },
    customToolbar: AddTagCategoryButton,
    customToolbarSelect: tagCategoriesTableToolbarSelect(
      setRowsToBeDeleted,
      tagCategories
    ),
    sort: false,
    sortOrder: {
      name: 'priority',
      direction: 'asc',
    },
  };

  return (
    <>
      <DeleteConfirmationDialog
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleDeleteCancellation={handleDeleteCancellation}
        open={rowsToBeDeleted.data.length > 0}
        additionalDescription={t('dialog.delete.tagCategory')}
      />
      <GenericTable
        title={t('navigation.tagCategories')}
        data={dataTable}
        columns={columns}
        options={options}
      />
    </>
  );
};
