import { useParams } from 'react-router-dom';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';
import { SummaryCard } from '../../../components/summary/SummaryCard';
import { SupportedLanguage, useSummaryQuery } from '../../../graphql/models';
import { ResourcePageLayout } from '../../../components/common/resources/page/ResourcePageLayout';
import { ResourcePageCard } from '../../../components/common/resources/page/ResourcePageCard';
import {
  GenericResourceContent,
  ResourcePageContent,
} from '../../../components/common/resources/page/ResourcePageContent';

export const SummaryPage = (): JSX.Element => {
  const { id } = useParams<{ id: string; lang: SupportedLanguage }>();
  const idNumber = Number(id);

  const { data, error, loading } = useSummaryQuery({
    variables: { id: idNumber },
  });

  if (!data) {
    return <ApiRequestErrorAndLoadingWrapper error={error} loading={loading} />;
  }

  const content: GenericResourceContent = data.summary.content.map((cnt) => ({
    language: cnt.language,
    title: cnt.title,
    body: cnt.content,
  }));

  return (
    <ResourcePageLayout>
      <ResourcePageContent
        authors={data.summary.authors ?? []}
        content={content}
      />
      <ResourcePageCard>
        <SummaryCard summary={data.summary} />
      </ResourcePageCard>
    </ResourcePageLayout>
  );
};
