import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface ButtonProps {
  style?: string;
  onToggle: (value: string) => void;
  active: boolean;
  label: string;
  icon?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      backgroundColor: theme.palette.info.main,
      color: 'white',
      '&:hover': {
        color: 'black',
      },
    },
  })
);

export const StyleButton = ({
  style = '',
  onToggle,
  active,
  label,
  icon,
}: ButtonProps): JSX.Element => {
  const classes = useStyles();
  const onMouseDown = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    onToggle(style);
  };

  const activeClass = active ? classes.active : '';
  const activeVariant = active ? 'contained' : 'outlined';

  return (
    <Button
      variant={activeVariant}
      className={activeClass}
      onMouseDown={onMouseDown}
      startIcon={icon}
      size="small"
    >
      {label}
    </Button>
  );
};
