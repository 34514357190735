import { useState } from 'react';
import { SubmitErrorHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ResourceInput } from '../types';

export const useBeforeFormSubmitErrorHandler = (): [
  string,
  SubmitErrorHandler<ResourceInput>
] => {
  const { t } = useTranslation();

  const [beforeSubmitErrorMessage, setBeforeSubmitErrorMessage] =
    useState<string>('');

  const handleBeforeSubmitError: SubmitErrorHandler<ResourceInput> = (
    validationErrors
  ) => {
    setBeforeSubmitErrorMessage('');
    let errorMessage = '';

    if (validationErrors) {
      errorMessage = t('validation.beforeSubmit');
    }

    if (validationErrors.content) {
      const contentErrors = validationErrors.content
        .map((foundErrors) =>
          Object.values(foundErrors ?? {}).reduce((prev, curr) => {
            prev += ` ${curr.message ?? ''}.`;
            return prev;
          }, '')
        )
        .join('\n');
      errorMessage += `: ${contentErrors}`;
    }
    setBeforeSubmitErrorMessage(errorMessage);
  };

  return [beforeSubmitErrorMessage, handleBeforeSubmitError];
};
