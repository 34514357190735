import React from 'react';
import { useTranslation } from 'react-i18next';
import { titleCase } from '../../../../common/StringUtils';
import { SupportedLanguage } from '../../../../graphql/models';
import { getTranslatedTagNameOrDefault } from '../../../../model/Tag';
import {
  getTagCategoryDescription,
  getTranslatedTagCategoryContentOrDefault,
} from '../../../../model/TagCategory';
import { TagsCountMapType } from '../../../../pages/app/search/SearchResultsPage';
import {
  SearchResultsTagFilter,
  TagCategoryWithTags,
} from '../../../../pages/app/search/types';
import { GenericAccordion } from '../../../common/layout/GenericAccordion';
import { GenericAccordionDetails } from '../../../common/layout/GenericAccordionDetails';
import { GenericAccordionSummary } from '../../../common/layout/GenericAccordionSummary';
import { SearchResultsFilterAccordionCheckbox } from './SearchResultsFilterAccordionCheckbox';
import { SearchResultsFilterAccordionHeading } from './SearchResultsFilterAccordionHeading';

interface Props {
  accordionClassName: string;
  headingClassName: string;
  category: TagCategoryWithTags;
  tagsCountMap: TagsCountMapType;
  handleTagFilterChange: (
    tagId: number
  ) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  searchResultsTagFilter: SearchResultsTagFilter;
}

export const TagSearchResultsFilterAccordion = ({
  accordionClassName,
  category,
  headingClassName,
  tagsCountMap,
  handleTagFilterChange,
  searchResultsTagFilter,
}: Props): JSX.Element => {
  const { i18n, t } = useTranslation();

  const getTotalCategorySearchResultCounts = () => {
    return category.tags.reduce(
      (prev, tag) => {
        const tagCount = tagsCountMap.get(tag.id);
        prev = [
          prev[0] + (tagCount?.visibleCount ?? 0),
          prev[1] + (tagCount?.totalCount ?? 0),
        ];
        return prev;
      },
      [0, 0]
    );
  };

  const [visibleCategoryTotal, totalCategoryTotal] =
    getTotalCategorySearchResultCounts();

  return (
    <GenericAccordion className={accordionClassName}>
      <GenericAccordionSummary>
        <SearchResultsFilterAccordionHeading
          titleClassName={headingClassName}
          title={titleCase(
            getTranslatedTagCategoryContentOrDefault(
              category,
              'name',
              i18n.language as SupportedLanguage
            )
          )}
          countSearchResults={{
            visibleCount: visibleCategoryTotal,
            totalCount: totalCategoryTotal,
          }}
          color={category.color}
          hoverValue={getTagCategoryDescription(category, i18n, t)}
          useChip
        />
      </GenericAccordionSummary>
      <GenericAccordionDetails>
        {category.tags.map((tag) => {
          return (
            <SearchResultsFilterAccordionCheckbox
              key={tag.id}
              checked={
                searchResultsTagFilter[tag.id] !== undefined
                  ? searchResultsTagFilter[tag.id]
                  : true
              }
              countSearchResults={{
                visibleCount: tagsCountMap.get(tag.id)?.visibleCount ?? 0,
                totalCount: tagsCountMap.get(tag.id)?.totalCount ?? 0,
              }}
              handleFilterChange={handleTagFilterChange(tag.id)}
              label={titleCase(
                getTranslatedTagNameOrDefault(
                  tag,
                  i18n.language as SupportedLanguage
                )
              )}
              name={tag.content[0].name}
            />
          );
        })}
      </GenericAccordionDetails>
    </GenericAccordion>
  );
};
