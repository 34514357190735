import { Box, Grid } from '@material-ui/core';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { SABreadcrumbs } from '../../../components/common/navigation/breadcrumbs/Breadcrumbs';
import { UserTable } from '../../../components/user/UserTable';
import { useFetchKeycloakUsers } from '../../../components/user/hooks/get-users-hook';
import { ApiRequestErrorAndLoadingWrapper } from '../../../components/common/ApiRequestWrapper';

export const UsersTablePage = (): JSX.Element => {
  const { users, loading, error, refreshUsers } = useFetchKeycloakUsers();

  return (
    <ApiRequestErrorAndLoadingWrapper error={error} loading={loading}>
      <Grid container spacing={LayoutConstants.gridSpacing}>
        <Grid item xs={12}>
          <Box mb={2} mt={2}>
            <SABreadcrumbs translateLastPathName />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UserTable keycloakUsers={users ?? []} refreshUsers={refreshUsers} />
        </Grid>
      </Grid>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
