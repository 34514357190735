import React from 'react';
import { EditorState } from 'draft-js';
import { StyleButton } from './TextEditorStyledButton';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import {
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  Subject,
  Title,
} from '@material-ui/icons';
import { TextEditorLink } from '../link/TextEditorLink';
interface BlockProps {
  editorState: EditorState;
  setEditorState: (value: React.SetStateAction<EditorState>) => void;
  onToggle: (value: string) => void;
  onChangeState: (state: EditorState) => void;
}

const blockTypes = (
  t: TFunction
): { label: string; style: string; icon: JSX.Element }[] => {
  return [
    { label: t('editor.block.body'), style: '', icon: <Subject /> },
    { label: t('editor.block.h2'), style: 'header-two', icon: <Title /> },
    {
      label: t('editor.block.blockquote'),
      style: 'blockquote',
      icon: <FormatQuote />,
    },
    {
      label: t('editor.block.ul'),
      style: 'unordered-list-item',
      icon: <FormatListBulleted />,
    },
    {
      label: t('editor.block.ol'),
      style: 'ordered-list-item',
      icon: <FormatListNumbered />,
    },
  ];
};
export const TextEditorBlockStyleControls = ({
  editorState,
  setEditorState,
  onToggle,
  onChangeState,
}: BlockProps): JSX.Element => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const { t } = useTranslation();

  return (
    <Grid container className="RichEditor-controls">
      {blockTypes(t).map((type, index) => (
        <Grid item key={index}>
          <Box mr={1} mt={1}>
            <StyleButton
              key={type.label}
              active={type.style === blockType}
              label={type.label}
              onToggle={onToggle}
              style={type.style}
              icon={type.icon}
            />
          </Box>
        </Grid>
      ))}
      <Grid item>
        <Box mr={1} mt={1}>
          <TextEditorLink
            editorState={editorState}
            setEditorState={setEditorState}
            onChangeState={onChangeState}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
