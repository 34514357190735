import { makeStyles, Theme, createStyles, Link } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { CodeLoungeIcon } from './CodeLoungeIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    codeLoungeLink: {
      display: 'flex',
    },
    codeLoungeIcon: {
      width: theme.spacing(12),
      height: '2em',
      fontSize: 'h5.fontSize',
      marginLeft: theme.spacing(1) / 2,
    },
    miniIcon: {
      ...theme.typography.body2,
      margin: `0 ${theme.spacing(1) / 2}px`,
    },
  })
);

interface Props {
  colorMode: 'light' | 'dark';
}

export const CodeLoungeCredits = ({ colorMode }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      Developed with <FavoriteIcon className={classes.miniIcon} /> by
      <Link
        href="https://codelounge.si.usi.ch/"
        target="_blank"
        rel="noreferrer"
        className={classes.codeLoungeLink}
        color="inherit"
      >
        <CodeLoungeIcon className={classes.codeLoungeIcon} mode={colorMode} />
      </Link>
    </>
  );
};
