import axios from 'axios';
import { useState, useEffect } from 'react';
import { SupportedLanguage } from '../../../../graphql/models';
import { TeamMemberData, TeamMemberName } from '../../AboutUs';

export const useTeamMembers = (
  teamMemberNames: TeamMemberName[]
): {
  teamMembers: TeamMemberData[];
} => {
  const [teamMembers, setTeamMembers] = useState<TeamMemberData[]>([]);

  useEffect(() => {
    async function fetchTeamMembersData(): Promise<void> {
      const teamMembersData: TeamMemberData[] = [];

      for (const member of teamMemberNames) {
        const id = `${member.firstName} ${member.lastName}`
          .replaceAll(' ', '-')
          .toLowerCase();

        const curricula: Map<SupportedLanguage, string> = new Map();

        for (const language of Object.values(SupportedLanguage)) {
          try {
            const response = await axios.get<string>(
              `team/curricula/${id}/${language}.md`
            );
            const curriculum = response.data;
            curricula.set(language, curriculum);
          } catch (error) {
            // no curriculum for this language
          }
        }

        teamMembersData.push({
          firstName: member.firstName,
          lastName: member.lastName,
          photoUrl: `team/photos/${id}.jpg`,
          curricula: curricula,
        });
      }

      setTeamMembers(teamMembersData);
    }
    void fetchTeamMembersData();
  }, [teamMemberNames]);

  return { teamMembers };
};
