import React, { useEffect } from 'react';
import * as Yup from 'yup';
import {
  Grid,
  TextField,
  makeStyles,
  Theme,
  createStyles,
  Button,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkPopover: {
      padding: theme.spacing(2, 2, 2, 2),
      maxWidth: 250,
    },
    linkTextField: {
      width: '100%',
    },
  })
);

interface Props {
  urlValue: string;
  setUrlValue: (value: string) => void;
  handleConfirmLink: () => void;
  handleRemoveLink: () => void;
}

export const TextEditorLinkInput = (props: Props): JSX.Element => {
  const { urlValue, setUrlValue, handleConfirmLink, handleRemoveLink } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const urlInputRef = React.useRef<HTMLDivElement>(null);

  const [urlIsValid, setUrlIsValid] = React.useState(false);

  const validateUrl = (url: string): void => {
    const isValid =
      Yup.string().trim().url().isValidSync(url) ||
      // yup url validation does not support localhost uris, adding exception
      Yup.string()
        .trim()
        .matches(/^http:\/\/localhost:/)
        .isValidSync(url);
    setUrlIsValid(isValid);
  };

  useEffect(() => {
    // trigger validation on any URL change (also from parent)
    validateUrl(urlValue);
  }, [urlValue]);

  const handleOnInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setUrlValue(value);
  };

  const showError = Boolean(urlValue) && !urlIsValid;

  return (
    <div className={classes.linkPopover}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            inputRef={urlInputRef}
            className={classes.linkTextField}
            label="URL"
            defaultValue={urlValue}
            autoFocus={true}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleOnInputChange}
            error={showError}
            helperText={showError && t('validation.error.mixed.default')}
          />
        </Grid>
        <Grid item xs={12} container direction="row" justify="flex-end">
          <Button onClick={handleRemoveLink} disabled={!urlIsValid}>
            <DeleteIcon />
          </Button>
          <Button onClick={handleConfirmLink} disabled={!urlIsValid}>
            <CheckIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
