type LogLevel = 'INFO' | 'WARNING' | 'ERROR';

// TODO - agree on how to handle logs in production
export class Logger {
  static info(message: string): void {
    const logMessage = Logger.buildLogMessage('INFO', message);
    console.info(logMessage);
  }

  static warn(message: string): void {
    const logMessage = Logger.buildLogMessage('WARNING', message);
    console.warn(logMessage);
  }

  static error(message: string): void {
    const logMessage = Logger.buildLogMessage('ERROR', message);
    console.error(logMessage);
  }

  static debug(message: string): void {
    if (process.env.NODE_ENV === 'development') {
      this.info(message);
    }
  }

  private static buildLogMessage(logLevel: LogLevel, message: string): string {
    const timeStamp = new Date().toLocaleString();
    return `${timeStamp} ${logLevel}: ${message}`;
  }
}
