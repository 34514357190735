import Keycloak from 'keycloak-js';
import {
  DEPLOYMENT_ROOT_PATH,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
} from './config';

// based on suggestion for SPAs in Keycloak docs (https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter)
export const keycloakInitOptions: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}${DEPLOYMENT_ROOT_PATH}/silent-check-sso.html`,
};

const config: Keycloak.KeycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

export const keycloak = Keycloak(config);
