import { Box, FormHelperText, Grid } from '@material-ui/core';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES_OBJECT } from '../../../common/supported-languages';
import { ContentErrors, Optional } from '../../../common/types';
import { SupportedLanguage } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { ResourceContentInput, ResourceInput } from '../types';
import { TextEditorInput } from './editor/TextEditorInput';
import { InputField } from './InputField';
import { LabelledOutline } from './LabelledOutline';
import { LanguageTabs } from './LanguageTabs';
import {
  translatedTitleLabel,
  translatedDescriptionLabel,
  translatedAbstractLabel,
  translatedContentLabel,
  translatedContentSummaryLabel,
} from './resources/labels';

type GenericContent = Record<string, string> & ResourceContentInput;

interface Props {
  contentKey: 'content' | 'abstract' | 'summary' | 'description' | 'text';
}

export const ResourceContentForm = ({ contentKey }: Props): JSX.Element => {
  const { t } = useTranslation();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<ResourceInput>();

  const supportedLanguages = SUPPORTED_LANGUAGES_OBJECT;

  const [tabValue, setTabValue] = React.useState(0);

  const contentErrors = errors.content as Optional<ContentErrors>;

  const { fields } = useFieldArray({
    control,
    name: 'content',
  });

  const originalLanguage = useWatch({
    control,
    name: 'originalLanguage',
  });

  const getTranslatedLabel = (targetLanguage: SupportedLanguage) => {
    switch (contentKey) {
      case 'content':
        return translatedContentLabel(t, targetLanguage);
      case 'abstract':
        return translatedAbstractLabel(t, targetLanguage);
      case 'summary':
        return translatedContentSummaryLabel(t, targetLanguage);
      case 'description':
        return translatedDescriptionLabel(t, targetLanguage);
      default:
        return translatedContentLabel(t, targetLanguage);
    }
  };

  return (
    <>
      <LanguageTabs
        value={tabValue}
        setValue={setTabValue}
        originalLanguage={originalLanguage}
      />
      <Box mt={3}>
        {fields.map(
          (content: GenericContent & { id: string }, index: number) => {
            const isOriginalLanguage = content.language === originalLanguage;

            const translatorErrorMessage =
              contentErrors?.[index]?.translator?.message ?? '';
            const titleErrorMessage =
              contentErrors?.[index]?.title?.message ?? '';
            const contentErrorMessage =
              contentErrors?.[index]?.[contentKey as keyof ContentErrors[0]]
                ?.message ?? '';

            return (
              <Grid
                key={content.id}
                style={{ display: index === tabValue ? '' : 'none' }}
                container
                spacing={LayoutConstants.gridSpacing}
              >
                <input
                  type="hidden"
                  defaultValue={content.language}
                  {...register(`content.${index}.language` as const)}
                />
                <Grid item xs={12}>
                  <InputField
                    name={`content[${index}].translator`}
                    translationField={!isOriginalLanguage}
                    label={
                      isOriginalLanguage
                        ? t('phrases.originalLanguage')
                        : t('commons.translator')
                    }
                    error={
                      Boolean(translatorErrorMessage) && !isOriginalLanguage
                    }
                    defaultValue={isOriginalLanguage ? '' : content.translator}
                    disabled={isOriginalLanguage}
                  />
                  <FormHelperText error={Boolean(translatorErrorMessage)}>
                    {translatorErrorMessage}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <InputField
                    name={`content[${index}].title`}
                    label={translatedTitleLabel(
                      t,
                      supportedLanguages[index].locale
                    )}
                    error={Boolean(titleErrorMessage)}
                    defaultValue={content.title}
                  />
                  <FormHelperText error={Boolean(titleErrorMessage)}>
                    {titleErrorMessage}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                  <LabelledOutline
                    error={Boolean(contentErrorMessage)}
                    id={`abstract-id-${index}`}
                    label={`${getTranslatedLabel(
                      supportedLanguages[index].locale
                    )}`}
                    hide={index !== tabValue}
                  >
                    <TextEditorInput
                      name={`content[${index}].${contentKey}`}
                      defaultValue={content[contentKey]}
                      label={`${getTranslatedLabel(
                        supportedLanguages[index].locale
                      )} ...`}
                      hide={index !== tabValue}
                    />
                  </LabelledOutline>
                  <FormHelperText error={Boolean(contentErrorMessage)}>
                    {contentErrorMessage}
                  </FormHelperText>
                </Grid>
              </Grid>
            );
          }
        )}
      </Box>
    </>
  );
};
