/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { SupportedLanguage } from '../graphql/models';
import { AuthorValidation } from '../model/validation/AuthorValidation';
import { ReferenceValidation } from '../model/validation/ReferenceValidation';

export const MUIRTE_DEFAULT_VALUE = draftToHtml(
  convertToRaw(EditorState.createEmpty().getCurrentContent())
);

export const translatorSchema = (contentKey: string, t?: TFunction) => {
  return Yup.string()
    .defined()
    .default('')
    .when(['$originalLanguage', 'title', contentKey, 'language'], {
      is: (
        originalLanguage: string,
        title: string,
        content: string,
        language: string
      ) =>
        language !== originalLanguage &&
        (title || (content && content.length > MUIRTE_DEFAULT_VALUE.length)),
      then: Yup.string().required(
        t ? t('validation.error.translator.required') : 'required'
      ),
    });
};

export const titleSchema = (contentKey: string, t?: TFunction) => {
  return Yup.string()
    .defined()
    .default('')
    .when(['$originalLanguage', 'language'], {
      is: (originalLanguage: string, language: string) =>
        language === originalLanguage,
      then: Yup.string().required(
        t
          ? t('validation.error.title.requiredOriginalLanguage', {
              content: contentKey,
            })
          : 'required'
      ),
      otherwise: Yup.string().when(['translator', contentKey], {
        is: (translator: string, content: string) =>
          translator ||
          (content && content.length > MUIRTE_DEFAULT_VALUE.length),
        then: Yup.string().required(
          t ? t('validation.error.title.required') : 'required'
        ),
      }),
    });
};

export const editorSchema = (contentKey: string, t?: TFunction) => {
  return Yup.string()
    .when(['$originalLanguage', 'language'], {
      is: (originalLanguage: string, language: string) =>
        language === originalLanguage,
      then: editorTextSchema(
        t
          ? t('validation.error.content.requiredOriginalLanguage', {
              content: contentKey,
            })
          : 'required'
      ),
      otherwise: Yup.string().when(['translator', 'title'], {
        is: (translator: string, title: string) => translator || title,
        then: editorTextSchema(
          t
            ? t('validation.error.content.required', { content: contentKey })
            : 'required'
        ),
      }),
    })
    .defined()
    .default(MUIRTE_DEFAULT_VALUE);
};

const editorTextSchema = (msg: string) => {
  return Yup.string()
    .required(msg)
    .min(MUIRTE_DEFAULT_VALUE.length, msg)
    .test('only-whitespaces', msg, (value) => {
      return (
        !value ||
        value?.replace(/\s|&nbsp;/g, '')?.length >= MUIRTE_DEFAULT_VALUE.length
      );
    });
};

const nameSchema = (t?: TFunction) => {
  return Yup.string()
    .defined()
    .default('')
    .when(['description'], {
      is: (description: string) => description,
      then: Yup.string().required(
        t ? t('validation.error.name.required') : 'required'
      ),
    });
};

export const yupOriginalLanguageSchema = () => {
  return Yup.mixed()
    .oneOf(Object.values(SupportedLanguage))
    .required()
    .default(SupportedLanguage.En);
};

export const yupAuthorsSchema = () => {
  return Yup.array()
    .defined()
    .of(AuthorValidation.yupAuthorSchema())
    .min(1)
    .ensure()
    .default([]);
};

export const yupReferencesSchema = (t?: TFunction) => {
  return Yup.array(ReferenceValidation.yupReferenceSchema(t))
    .defined()
    .default([]);
};

export const yupTagIdsSchema = () => {
  return Yup.array()
    .defined()
    .of(Yup.number().defined().required().default(-1))
    .ensure()
    .default([]);
};

export const yupLinkSchema = () => {
  return Yup.string().trim().url().required().default('');
};

export const yupContentSchema = (contentKey: string, t?: TFunction) => {
  return Yup.array(individualContentSchema(contentKey, t))
    .default([])
    .defined();
};

const individualContentSchema = (contentKey: string, t?: TFunction) => {
  return Yup.object().shape(
    {
      translator: translatorSchema(contentKey, t),
      title: titleSchema(contentKey, t),
      [contentKey]: editorSchema(t ? t(contentKey) : '', t),
      language: Yup.mixed().oneOf(Object.values(SupportedLanguage)).required(),
    },
    [
      ['translator', 'title'],
      ['title', contentKey],
      ['translator', contentKey],
    ]
  );
};

export const yupTranslatedContentSchema = (t?: TFunction) => {
  const message = t ? t('validation.error.custom.minimumContent') : '';

  return Yup.array(individualTranslatedContentSchema(t))
    .default([])
    .test('hasAtLeastOneLanguageDefined', message, (value): boolean => {
      return (
        value !== undefined &&
        value !== null &&
        value.some((content) => content.name && content.name.length > 1)
      );
    });
};

const individualTranslatedContentSchema = (t?: TFunction) => {
  return Yup.object().shape({
    language: Yup.mixed()
      .oneOf(Object.values(SupportedLanguage))
      .required()
      .default(SupportedLanguage.En),
    name: nameSchema(t),
    description: Yup.string().lowercase().default(''),
  });
};
