import { Breadcrumbs, Typography } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  kebabCaseToCamelCase,
  titleCase,
} from '../../../../common/StringUtils';

export interface SABreadcrumbsProps {
  translateLastPathName?: boolean;
}

export const SABreadcrumbs = ({
  translateLastPathName = false,
}: SABreadcrumbsProps): JSX.Element => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const pathNames = pathname.split('/').filter((value) => value.length > 0);

  const mapPathValueToTranslationKey = (pathValue: string): string =>
    kebabCaseToCamelCase(pathValue);

  return (
    <Breadcrumbs>
      {pathNames.map((value, index) => {
        const last = index === pathNames.length - 1;
        const to = `/${pathNames.slice(0, index + 1).join('/')}`;
        const name = translateLastPathName
          ? t(`navigation.${mapPathValueToTranslationKey(value)}`)
          : decodeURIComponent(value);

        return last ? (
          <Typography key={index} color="textPrimary">
            {titleCase(name, '-')}
          </Typography>
        ) : (
          <RouterLink key={index} color="inherit" to={to}>
            {titleCase(name, '-')}
          </RouterLink>
        );
      })}
    </Breadcrumbs>
  );
};
