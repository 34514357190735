import {
  SupportedLanguage,
  TagDataFragment,
  TagsQuery,
} from '../../../../graphql/models';
import { getTranslatedTagNameOrDefault } from '../../../../model/Tag';
import { NetworkData, Node, Link, Category } from './network.type';

export class TagNetworkBuilder {
  private static maxCategoryPriorityDiff = 1;

  private static initialNodeXValue = 50;
  private static initialNodeYValueMultiplier = 25;
  private static minimumNodeSymbolSize = 10;
  private static linkWithMultiplier = 0.5;

  public buildNetworkData(
    tags: TagsQuery['tags'],
    tagCategoryIdToUpdatedTagCategory: Map<number, Category>,
    language: SupportedLanguage
  ): NetworkData {
    const filteredTags = tags
      .filter((tag) => this.numLinkedResources(tag) > 0)
      .sort((a, b) => a.category.priority - b.category.priority);

    const nodes: Node[] = filteredTags.map((tag) => {
      const count = this.numberOfResources(tag);
      const newCategory =
        tagCategoryIdToUpdatedTagCategory.get(tag.category.id) ?? tag.category;

      return {
        id: tag.id.toString(),
        name: getTranslatedTagNameOrDefault(tag, language),
        category: newCategory.id,
        value: count,
        symbolSize: Math.max(count, TagNetworkBuilder.minimumNodeSymbolSize),
        x: TagNetworkBuilder.initialNodeXValue,
        y: newCategory.priority * TagNetworkBuilder.initialNodeYValueMultiplier,
      };
    });

    const links: Link[] = [];

    for (let i = 0; i < filteredTags.length; i++) {
      const tagA = filteredTags[i];
      for (let j = i + 1; j < filteredTags.length; j++) {
        const tagB = filteredTags[j];

        if (this.hasIntersectingResources(tagA, tagB)) {
          const sharedResourcesCount = this.numberOfSharedResources(tagA, tagB);
          links.push({
            source: tagA.id.toString(),
            target: tagB.id.toString(),
            value: sharedResourcesCount,
            lineStyle: {
              width:
                sharedResourcesCount * TagNetworkBuilder.linkWithMultiplier,
            },
          });
        }
      }
    }

    return { nodes, links };
  }

  private numLinkedResources(tag: TagDataFragment): number {
    return [
      ...tag.summaryIds,
      ...tag.articleIds,
      ...tag.linkIds,
      ...tag.bookIds,
      ...tag.academicWorkIds,
    ].length;
  }

  private hasIntersectingResources(
    tagA: TagDataFragment,
    tagB: TagDataFragment
  ): boolean {
    const priorityA = tagA.category.priority;
    const priorityB = tagB.category.priority;
    return (
      priorityA < priorityB &&
      priorityB - priorityA <= TagNetworkBuilder.maxCategoryPriorityDiff &&
      (this.hasIntersection(tagA.summaryIds, tagB.summaryIds) ||
        this.hasIntersection(tagA.articleIds, tagB.articleIds) ||
        this.hasIntersection(tagA.linkIds, tagB.linkIds) ||
        this.hasIntersection(tagA.bookIds, tagB.bookIds) ||
        this.hasIntersection(tagA.academicWorkIds, tagB.academicWorkIds))
    );
  }

  private hasIntersection(arrayA: number[], arrayB: number[]): boolean {
    const arrayASet = new Set(arrayA);

    for (const element of arrayB) {
      if (arrayASet.has(element)) {
        return true;
      }
    }

    return false;
  }

  private numberOfResources(tag: TagDataFragment): number {
    return (
      tag.summaryIds.length +
      tag.articleIds.length +
      tag.linkIds.length +
      tag.bookIds.length +
      tag.academicWorkIds.length
    );
  }

  private numberOfSharedResources(
    tagA: TagDataFragment,
    tagB: TagDataFragment
  ): number {
    const sharedSummaries = tagA.summaryIds.filter((summaryId) =>
      tagB.summaryIds.includes(summaryId)
    );

    const sharedArticles = tagA.articleIds.filter((articleId) =>
      tagB.articleIds.includes(articleId)
    );

    const sharedLinks = tagA.linkIds.filter((linkId) =>
      tagB.linkIds.includes(linkId)
    );

    const sharedBooks = tagA.bookIds.filter((bookId) =>
      tagB.bookIds.includes(bookId)
    );

    const sharedAcademicWorks = tagA.academicWorkIds.filter((academicWorkId) =>
      tagB.academicWorkIds.includes(academicWorkId)
    );

    return (
      sharedSummaries.length +
      sharedArticles.length +
      sharedLinks.length +
      sharedBooks.length +
      sharedAcademicWorks.length
    );
  }
}
