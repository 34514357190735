import React from 'react';
import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  title: string;
  errorMessage: string;
  handleDismiss?: () => void;
}

export const ErrorDialog: React.FC<Props> = ({
  open,
  title,
  errorMessage,
  handleDismiss,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      {handleDismiss ? (
        <DialogActions>
          <Button onClick={handleDismiss} color="primary" autoFocus>
            {t('error.dialog.dismiss')}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};
