import React from 'react';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AddEntityToolbarButton } from '../../common/table/AddEntityToolbarButton';
import {
  SupportedLanguage,
  TagCategory,
  TagsTableQuery,
  useDeleteTagMutation,
} from '../../../graphql/models';
import { QUERY_TAGS } from '../../../graphql/queries/tag/tags';
import { TagChip } from '../TagChip';
import { GenericTable } from '../../common/table/GenericTable';
import { QUERY_ARTICLES_TABLE } from '../../../graphql/queries/article/articles-table';
import { QUERY_ARTICLES } from '../../../graphql/queries/article/articles';
import { QUERY_SUMMARIES_TABLE } from '../../../graphql/queries/summary/summaries-table';
import { QUERY_SUMMARIES } from '../../../graphql/queries/summary/summaries';
import DeleteConfirmationDialog from '../../common/table/DeleteConfirmationDialog';
import { RowsToBeDeleted } from '../../common/table/types';
import { getTranslatedTagCategoryContentOrDefault } from '../../../model/TagCategory';
import { getTranslatedTagNameOrDefault } from '../../../model/Tag';
import { useRoutes } from '../../../common/routing/routes-hook';

interface Props {
  tags: TagsTableQuery['tags'];
}

interface TagTableRow {
  id: number;
  name: string;
  category: TagCategory;
  languages: string[];
}

export const TagsTable = ({ tags }: Props): JSX.Element => {
  const { i18n, t } = useTranslation();
  const { routes } = useRoutes();

  const history = useHistory();

  const [rowsToBeDeleted, setRowsToBeDeleted] = React.useState<
    RowsToBeDeleted | undefined
  >();

  const handleDeleteConfirmation = async () => {
    if (rowsToBeDeleted) {
      await deleteRows();
    }
    setRowsToBeDeleted(undefined);
  };

  const handleDeleteCancellation = () => {
    setRowsToBeDeleted(undefined);
  };

  const onAddNewTagClick = (): void => history.push(routes.tagsNew.path);

  const AddTagButton = (): JSX.Element => (
    <AddEntityToolbarButton
      tooltipText={t('phrases.addNewTag')}
      onClick={onAddNewTagClick}
    />
  );

  const dataTable = tags
    .map((tag): TagTableRow => {
      const { content, id, category } = tag;
      const userLanguage = i18n.language as SupportedLanguage;
      const name = getTranslatedTagNameOrDefault(tag, userLanguage);

      const languages = content
        .map((translation) => translation.language)
        .sort((a, b) => a.localeCompare(b));

      return {
        id,
        name,
        category,
        languages,
      };
    })
    // sort by name since MUIDataTable doesn't support multiple column sort in options
    .sort((a, b) => a.name.localeCompare(b.name));

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: t('commons.name'),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'category',
      label: t('commons.tagCategory'),
      options: {
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return (
            <TagChip
              label={getTranslatedTagCategoryContentOrDefault(
                dataTable[dataIndex].category,
                'name',
                i18n.language as SupportedLanguage
              )}
              color={dataTable[dataIndex].category.color}
            />
          );
        },
        sortCompare: (order) => {
          return (a: { data: TagCategory }, b: { data: TagCategory }) => {
            return (
              (a.data.priority - b.data.priority) * (order === 'asc' ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: 'languages',
      label: t('commons.languages'),
      options: {
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return <div>{dataTable[dataIndex].languages.join(', ')}</div>;
        },
      },
    },
  ];

  const [deleteTagMutation] = useDeleteTagMutation();

  const deleteRows = async () => {
    for (const row of rowsToBeDeleted?.data ?? []) {
      const id = dataTable[row.dataIndex].id;

      await deleteTagMutation({
        variables: { id },
        refetchQueries: [
          { query: QUERY_TAGS },
          { query: QUERY_ARTICLES_TABLE },
          { query: QUERY_SUMMARIES_TABLE },
          { query: QUERY_ARTICLES },
          { query: QUERY_SUMMARIES },
        ],
      });
    }
  };

  const options: MUIDataTableOptions = {
    onRowsDelete: (rowsDeleted) => {
      setRowsToBeDeleted(rowsDeleted);
    },
    onRowClick(_: string[], rowMeta: { dataIndex: number; rowIndex: number }) {
      history.push(
        routes.tagsEdit.pathWithArguments({
          id: String(dataTable[rowMeta.dataIndex].id),
        })
      );
    },
    customToolbar: AddTagButton,
    sortOrder: {
      name: 'category',
      direction: 'asc',
    },
  };

  return (
    <>
      <DeleteConfirmationDialog
        handleDeleteConfirmation={handleDeleteConfirmation}
        handleDeleteCancellation={handleDeleteCancellation}
        open={rowsToBeDeleted !== undefined}
      />
      <GenericTable
        title={t('navigation.tags')}
        data={dataTable}
        columns={columns}
        options={options}
      />
    </>
  );
};
