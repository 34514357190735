import { Grid, Typography } from '@material-ui/core';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { ApiRequestErrorAndLoadingWrapper } from '../../common/ApiRequestWrapper';
import { useDeleteUser } from '../hooks/delete-user-hook';
import { KeycloakUserWithRole } from '../hooks/get-users-hook';
import { useTranslation } from 'react-i18next';

interface Props {
  user: KeycloakUserWithRole;
  formId: string;
  handleFormSubmitted: () => void;
}

export const DeleteUserForm = ({
  user,
  formId,
  handleFormSubmitted,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { deleteUser, loading, error } = useDeleteUser();

  const submitHandler = async () => {
    await deleteUser(user);
    handleFormSubmitted();
  };

  return (
    <ApiRequestErrorAndLoadingWrapper loading={loading} error={error}>
      <form id={formId} onSubmit={submitHandler}>
        <Grid container spacing={LayoutConstants.gridSpacing}>
          <Grid item xs={12}>
            <Typography>
              {t('users.dialog.delete.confirmation', {
                name: user.firstName + ' ' + user.lastName,
              })}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </ApiRequestErrorAndLoadingWrapper>
  );
};
