import { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { Category, NetworkData } from './data/network.type';
import { useTranslation } from 'react-i18next';
import { Grid, useTheme } from '@material-ui/core';
import { EChartsOption } from 'echarts';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../../common/routing/routes-hook';
import {
  defaultSearchInput,
  useSearchDataContext,
} from '../../search/SearchContext';
import { TagLegend } from './legend/TagLegend';
import { createEchartsOption } from './legend/helpers';
import { buildCategoryIdToUpdatedCategories } from './data/tag-category-utils';

export interface ChartState {
  showLabels: boolean;
  showLegend: boolean;
}

interface Props {
  data: NetworkData;
  categoriesLegendState: Category[];
  chartState: ChartState;
  setCategoriesLegendState: (categoriesLegendState: Category[]) => void;
}

export const TagGraphChart = ({
  data,
  categoriesLegendState,
  chartState,
  setCategoriesLegendState,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { routes } = useRoutes();
  const searchPath = routes.search.path;
  const { searchInputData, setSearchInputData } = useSearchDataContext();
  const defaultValues = searchInputData || defaultSearchInput;

  const onEvents = {
    click: (params: {
      data: { name?: string; id: string; source: string; target: string };
    }) => {
      const isNode = Boolean(params.data.name);

      if (isNode) {
        // Go to search with tag
        setSearchInputData({
          ...defaultValues,
          tagFilter: [parseInt(params.data.id)],
        });
      } else {
        // Go to search with the two linked tags
        setSearchInputData({
          ...defaultValues,
          tagFilter: [
            parseInt(params.data.source),
            parseInt(params.data.target),
          ],
        });
      }
      history.push(searchPath);
    },
  };

  const chartRef = useRef<ReactECharts>(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartRef]);

  const { nodes, links } = data;

  const tagCategoryIdToUpdatedTagCategory = buildCategoryIdToUpdatedCategories(
    categoriesLegendState
  );

  const option: EChartsOption = createEchartsOption(
    t,
    theme,
    nodes,
    links,
    tagCategoryIdToUpdatedTagCategory,
    chartState
  );

  return (
    <Grid style={{ position: 'relative', height: '100%' }}>
      <TagLegend
        categories={categoriesLegendState}
        setCategoryLegendState={setCategoriesLegendState}
        showLegend={chartState.showLegend}
      />
      <ReactECharts
        style={{
          width: '100%',
          height: '100%',
        }}
        option={option}
        ref={chartRef}
        onEvents={onEvents}
      />
    </Grid>
  );
};
