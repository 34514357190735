import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../../common/StringUtils';
import { getTranslatedLanguage } from '../../../../common/supported-languages';
import { TranslationLink } from './types';

interface Props {
  originalLanguageLink: TranslationLink;
  isAccessible: boolean;
}

export const ResourceCardOriginalLanguageSection = ({
  originalLanguageLink,
  isAccessible,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { url, language } = originalLanguageLink;

  return (
    <Grid container item xs={12} justify="space-between" alignItems="center">
      <Typography color="textSecondary">
        {t('phrases.originalLanguage')}
      </Typography>
      <Typography color="textSecondary">
        {isAccessible ? (
          <Link to={url}>
            {capitalizeFirstLetter(getTranslatedLanguage(t, language))}
          </Link>
        ) : (
          <span>
            {capitalizeFirstLetter(getTranslatedLanguage(t, language))}
          </span>
        )}
      </Typography>
    </Grid>
  );
};
