import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface Props {
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  count: number;
}

export const SearchResultsPagination = ({
  handlePageChange,
  count,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Pagination count={count} shape="rounded" onChange={handlePageChange} />
    </Grid>
  );
};
