import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { SummaryMetaForm } from './SummaryMetaForm';
import {
  SummaryDataFragment,
  useCreateSummaryMutation,
  useUpdateSummaryMutation,
} from '../../../graphql/models';
import { QUERY_SUMMARIES } from '../../../graphql/queries/summary/summaries';
import {
  yupAuthorsSchema,
  yupContentSchema,
  yupOriginalLanguageSchema,
  yupTagIdsSchema,
} from '../../../common/CommonSchemas';
import { ResourceForm } from '../../common/form/ResourceForm';
import { useSetupResourceForm } from '../../common/hooks/useSetupResourceForm';
import { ResourceContentForm } from '../../common/form/ResourceContentForm';

interface Props {
  summary?: SummaryDataFragment;
}

export const SummaryForm = ({ summary }: Props): JSX.Element => {
  const { t } = useTranslation();

  const formTitle = summary
    ? t('summaries.form.title.edit')
    : t('summaries.form.title.new');

  const validationSchema = Yup.object().shape({
    authors: yupAuthorsSchema(),
    content: yupContentSchema('content', t),
    originalLanguage: yupOriginalLanguageSchema(),
    tagIds: yupTagIdsSchema(),
  });

  const [createMutation] = useCreateSummaryMutation();
  const [updateMutation] = useUpdateSummaryMutation();

  const [form, handleSubmit, handleReset, errorMessage] = useSetupResourceForm({
    resource: summary,
    resourceName: 'summaries',
    validationSchema,
    createMutation,
    updateMutation,
    query: QUERY_SUMMARIES,
  });

  const dateCreated = summary?.dateCreated
    ? new Date(summary.dateCreated)
    : new Date();
  const dateUpdated = summary?.dateUpdated
    ? new Date(summary.dateUpdated)
    : new Date();

  return (
    <ResourceForm
      formTitle={formTitle}
      form={form}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      metaForm={
        <SummaryMetaForm dateCreated={dateCreated} dateUpdated={dateUpdated} />
      }
      contentForm={<ResourceContentForm contentKey="content" />}
      errorMessage={errorMessage}
    />
  );
};
