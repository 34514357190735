import { Grid } from '@material-ui/core';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { SummaryForm } from '../../../../components/summary/form/SummaryForm';
import { FormBreadCrumbs } from '../../../../components/common/navigation/breadcrumbs/FormBreadcrumbs';

export const NewSummaryPage = (): JSX.Element => {
  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={12}>
        <FormBreadCrumbs />
      </Grid>
      <Grid item xs={12}>
        <SummaryForm />
      </Grid>
    </Grid>
  );
};
