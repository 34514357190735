import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

interface DeleteConfirmationDialogProps {
  handleDeleteConfirmation: () => void;
  handleDeleteCancellation: () => void;
  open: boolean;
  additionalDescription?: string;
}

export default function DeleteConfirmationDialog({
  handleDeleteConfirmation,
  handleDeleteCancellation,
  open,
  additionalDescription,
}: DeleteConfirmationDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleDeleteCancellation}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('dialog.delete.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('dialog.delete.contentText')}
          {additionalDescription && <p>{additionalDescription}</p>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDeleteCancellation}
          variant="contained"
          color="primary"
        >
          {t('dialog.delete.cancel')}
        </Button>
        <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
          {t('dialog.delete.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
