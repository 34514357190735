import { useTranslation } from 'react-i18next';

import {
  Chip,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';

import {
  LatestEntriesInput,
  useLatestEntriesQuery,
} from '../../graphql/models';
import { ApiRequestErrorAndLoadingWrapper } from '../common/ApiRequestWrapper';
import { getSupportedLanguageFrom } from '../../common/supported-languages';
import { formatDate } from '../../common/date';
import { Fragment } from 'react';
import { resourceTypeToTranslationKey } from '../../common/resources';

const useStyles = makeStyles((theme) => ({
  metaData: {
    fontStyle: 'italic',
  },
  resourceTypeChip: {
    backgroundColor: theme.palette.info.light,
    color: 'white',
  },
}));

export const LatestEntries = (): JSX.Element => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const currentLanguage = getSupportedLanguageFrom(i18n);
  const theme = useTheme();

  const latestEntriesInput: LatestEntriesInput = {
    limit: 3,
    preferredLanguage: currentLanguage,
  };

  const { data, loading, error } = useLatestEntriesQuery({
    variables: { latestEntriesInput: { ...latestEntriesInput } },
    fetchPolicy: 'network-only', // the cache doesn't always return the right language
  });

  if (!data || loading || error) {
    return <ApiRequestErrorAndLoadingWrapper loading={loading} error={error} />;
  }

  if (data.latestEntries.entries.length === 0) {
    return <div />;
  }

  return (
    <div>
      <h2>{t('homepage.latestEntries.title')}</h2>
      {data.latestEntries.entries.map(
        ({ id, metadata, type, title, dateCreated }) => (
          <Fragment key={id}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8}>
                <Typography variant="subtitle2" align="left" color="inherit">
                  <em>
                    {t('phrases.added')} {formatDate(new Date(dateCreated))}.
                  </em>
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Chip
                  label={t(resourceTypeToTranslationKey(type))}
                  className={classes.resourceTypeChip}
                  size="small"
                />
              </Grid>
            </Grid>
            <Typography
              variant="subtitle2"
              align="justify"
              color="inherit"
              gutterBottom
            >
              <b>{title}</b>
            </Typography>

            <Typography
              variant="body2"
              align="justify"
              color="inherit"
              gutterBottom
            >
              {metadata}
            </Typography>
            <Divider style={{ marginBottom: theme.spacing(1) }} />
          </Fragment>
        )
      )}
    </div>
  );
};
