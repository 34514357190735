import {
  Divider,
  List,
  ListItem,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';

export interface TagDescriptionTooltipProps {
  description?: string;
  references?: { text: string; url?: string }[];
  descriptionTitle?: string;
  referenceTitle?: string;
}

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: theme.spacing(1),
  },
}))(Tooltip);

export const Description = ({
  description,
  descriptionTitle,
}: TagDescriptionTooltipProps): JSX.Element => (
  <>
    <Typography color="inherit" style={{ fontWeight: 'bold' }}>
      {descriptionTitle}
    </Typography>
    <Typography variant="body2" color="inherit">
      {description}
    </Typography>
  </>
);

export const References = (
  hoverValue: TagDescriptionTooltipProps
): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <Divider style={{ margin: `${theme.spacing(1)}px 0px` }} />
      <Typography color="inherit" style={{ fontWeight: 'bold' }}>
        {hoverValue?.referenceTitle}
      </Typography>
      {hoverValue?.references?.map((reference, index) => (
        <List
          key={reference.text}
          style={{
            listStyleType: 'disc',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          }}
          disablePadding
        >
          <ListItem style={{ display: 'list-item', padding: 0 }}>
            <Typography variant="body2" color="inherit">
              {reference.url ? (
                <a href={reference.url} target="_blank" rel="noreferrer">
                  {reference.text}
                </a>
              ) : (
                reference.text
              )}
            </Typography>
          </ListItem>
        </List>
      ))}
    </>
  );
};
