import React, { useState } from 'react';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  SubmitErrorHandler,
  useWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { LayoutConstants } from '../../../theme/LayoutConstants';
import { buildYupLocaleObject } from '../../../common/yup-validation';
import { SUPPORTED_LANGUAGES_OBJECT } from '../../../common/supported-languages';
import { FormHeaderWithSubmit } from '../../common/form/FormHeaderWithSubmit';
import {
  TagCategory,
  TagCategoryContentInput,
  TagCategoryInput,
  useCreateTagCategoryMutation,
  useUpdateTagCategoryMutation,
} from '../../../graphql/models';
import { GenericSnackbar } from '../../common/feedback/GenericSnackbar';
import { TagCategoryValidation } from '../../../model/validation/TagCategoryValidation';
import { QUERY_TAG_CATEGORIES } from '../../../graphql/queries/tag-category/tag-categories';
import { TagCategoryColorFormField } from './TagCategoryColorFormField';
import { TagCategoryContentForm } from './TagCategoryContentForm';
import { useFormStyles } from '../../common/form/styles';
import {
  yupReferencesSchema,
  yupTranslatedContentSchema,
} from '../../../common/CommonSchemas';
import { useRoutes } from '../../../common/routing/routes-hook';
import { ReferenceForm } from '../../common/form/ReferenceForm';

interface Props {
  tagCategory?: TagCategory;
}

export const TagCategoryForm = ({ tagCategory }: Props): JSX.Element => {
  const classes = useFormStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { routes } = useRoutes();

  const [atLeastOneLanguageFilled, setAtLeastOneLanguageFilled] =
    React.useState(false);

  Yup.setLocale(buildYupLocaleObject(t));

  const defaultContents: TagCategoryContentInput[] =
    SUPPORTED_LANGUAGES_OBJECT.map((language) => {
      return {
        name: '',
        description: '',
        language: language.locale,
      };
    });

  const content = defaultContents.map(
    (contentTranslation) =>
      tagCategory?.content.find(
        (value) => value.language === contentTranslation.language
      ) ?? contentTranslation
  );

  const initialReferences =
    tagCategory && (tagCategory.references.length ?? 0) > 0
      ? tagCategory.references
      : [{ text: '', url: '' }];

  const initialValues: Omit<TagCategoryInput, 'id'> = {
    color: tagCategory?.color ?? '#000000',
    content,
    references: initialReferences,
  };

  const validationSchema = Yup.object().shape({
    color: TagCategoryValidation.yupColorSchema(),
    content: yupTranslatedContentSchema(t),
    references: yupReferencesSchema(t),
  });

  const tagCategoryForm = useForm<typeof initialValues>({
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    context: { atLeastOneLanguageFilled },
  });

  const { reset, handleSubmit, control } = tagCategoryForm;

  const tagCategoryContent = useWatch({
    control,
    name: 'content',
  });

  React.useEffect(() => {
    setAtLeastOneLanguageFilled(
      tagCategoryContent.some((val) => val.name !== '')
    );
  }, [tagCategoryContent]);

  const [createTagCategoryMutation] = useCreateTagCategoryMutation();
  const [updateTagCategoryMutation] = useUpdateTagCategoryMutation();

  const [afterSubmitErrors, setAfterSubmitErrors] = useState(false);

  const [beforeSubmitError, setBeforeSubmitError] = useState<string | null>(
    null
  );

  const handleFormReset = (): void => reset(initialValues);

  const errorHandler: SubmitErrorHandler<typeof initialValues> = (_): void => {
    setBeforeSubmitError(null);
    const errorMessage = `${t('validation.beforeSubmit')}`;
    setBeforeSubmitError(errorMessage);
  };

  const submitHandler: SubmitHandler<typeof initialValues> = async (data) => {
    data.content = data.content.filter(
      (contentTranslation) => contentTranslation.name !== ''
    );
    data.references = data.references.filter((ref) => ref.text !== '');

    const validatedInput = validationSchema.cast(data, {
      stripUnknown: true,
    }) as typeof initialValues;

    if (tagCategory?.id) {
      const { errors } = await updateTagCategoryMutation({
        variables: {
          id: tagCategory?.id,
          input: validatedInput,
        },
        refetchQueries: [{ query: QUERY_TAG_CATEGORIES }],
      });
      setAfterSubmitErrors(Boolean(errors));
    } else {
      const { errors } = await createTagCategoryMutation({
        variables: { input: validatedInput },
        refetchQueries: [{ query: QUERY_TAG_CATEGORIES }],
      });
      setAfterSubmitErrors(Boolean(errors));
    }

    if (!afterSubmitErrors) {
      history.push(routes.tagCategories.path);
    }
  };

  const formTitle = tagCategory
    ? t('tagCategories.form.title.edit')
    : t('tagCategories.form.title.new');

  return (
    <FormProvider {...tagCategoryForm}>
      <form
        onSubmit={handleSubmit(submitHandler, errorHandler)}
        onReset={handleFormReset}
      >
        <Grid
          container
          spacing={LayoutConstants.gridSpacing}
          className={classes.root}
        >
          <Grid item xs={12}>
            <FormHeaderWithSubmit
              title={formTitle}
              handleFormReset={handleFormReset}
            />
          </Grid>
          <Grid item xs={12} className={classes.noMarginBottom}>
            <TagCategoryContentForm />
          </Grid>
          <Grid item xs={12} className={classes.noMarginTop}>
            <TagCategoryColorFormField />
          </Grid>
          <Grid item xs={12} className={classes.noMarginTop}>
            <ReferenceForm />
          </Grid>
        </Grid>
      </form>
      <GenericSnackbar
        message={beforeSubmitError ?? ''}
        isOpen={beforeSubmitError !== null}
        severity={'error'}
      />
      <GenericSnackbar
        message={t('phrases.submitError')}
        isOpen={afterSubmitErrors}
        severity={'error'}
      />
    </FormProvider>
  );
};
