import { BookPage } from '../../../pages/app/books/BookPage';
import { SummaryPage } from '../../../pages/app/summaries/SummaryPage';
import { ArticlePage } from '../../../pages/app/articles/ArticlePage';
import { AcademicWorkPage } from '../../../pages/app/academic-works/AcademicWorkPage';
import { LinkPage } from '../../../pages/app/links/LinkPage';
import { NavigationUtil } from '../../navigation/NavigationUtil';
import { ResourcePath } from '../../navigation/path-types';
import { Route } from '../Route';

export type ResourceRoutesType = {
  articleResult: Route<unknown>;
  summaryResult: Route<unknown>;
  linkResult: Route<unknown>;
  bookResult: Route<unknown>;
  academicWorkResult: Route<unknown>;
};

export const resourceRoutes = (parent: Route<unknown>): ResourceRoutesType => {
  const articleResult = new Route({
    component: ArticlePage,
    path: NavigationUtil.resourceResultRoute(ResourcePath.article),
    parent,
    exact: true,
  });

  const summaryResult = new Route({
    component: SummaryPage,
    path: NavigationUtil.resourceResultRoute(ResourcePath.summary),
    parent,
    exact: true,
  });

  const linkResult = new Route({
    component: LinkPage,
    path: NavigationUtil.resourceResultRoute(ResourcePath.link),
    parent,
    exact: true,
  });

  const bookResult = new Route({
    component: BookPage,
    path: NavigationUtil.resourceResultRoute(ResourcePath.book),
    parent,
    exact: true,
  });

  const academicWorkResult = new Route({
    component: AcademicWorkPage,
    path: NavigationUtil.resourceResultRoute(ResourcePath.academicWork),
    parent,
    exact: true,
  });

  return {
    articleResult,
    summaryResult,
    linkResult,
    bookResult,
    academicWorkResult,
  };
};
