/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export class ReferenceValidation {
  static yupReferenceSchema(t?: TFunction) {
    return Yup.object().shape({
      text: ReferenceValidation.yupTextSchema(t),
      url: ReferenceValidation.yupUrlSchema(t),
    });
  }

  static yupTextSchema(t?: TFunction) {
    return Yup.string()
      .trim()
      .defined()
      .default('')
      .when(['url'], {
        is: (url: string) => url !== '',
        then: Yup.string().required(
          t ? t('validation.error.reference.text.required') : 'required'
        ),
      });
  }

  static yupUrlSchema(t?: TFunction) {
    return Yup.string()
      .trim()
      .url(t ? t('validation.url.invalid') : 'Must be a valid url.')
      .default('');
  }
}
