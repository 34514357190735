import React, { useState } from 'react';
import { SearchInput, SupportedLanguage } from '../../graphql/models';

export const defaultSearchInput: SearchInput = {
  preferredLanguage: SupportedLanguage.En,
  searchTerm: '',
  tagFilter: [],
};

export interface SearchContextData {
  searchInputData: SearchInput;
  setSearchInputData: (searchInput: SearchInput) => void;
  clearSearchInputData: () => void;
  searchCount: number;
}

const SearchDataContextObject = React.createContext<SearchContextData>({
  searchInputData: defaultSearchInput,
  setSearchInputData: (searchTerm: SearchInput) => {
    return;
  },
  clearSearchInputData: () => {
    return;
  },
  searchCount: 0,
});

export function useSearchDataContext(): SearchContextData {
  return React.useContext(SearchDataContextObject);
}

export const SearchContext = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [memoizedSearchInput, setMemoizedSearchInput] =
    useState(defaultSearchInput);

  const [searchCount, setSearchCount] = useState(0);

  const context = React.useMemo<SearchContextData>(
    () => ({
      searchInputData: memoizedSearchInput,
      setSearchInputData: (newSearchInput: SearchInput): void => {
        setMemoizedSearchInput(newSearchInput);
        setSearchCount(searchCount + 1);
      },
      clearSearchInputData: (): void => {
        setMemoizedSearchInput(defaultSearchInput);
      },
      searchCount,
    }),
    [memoizedSearchInput, searchCount, setMemoizedSearchInput]
  );

  return (
    <SearchDataContextObject.Provider value={context}>
      {children}
    </SearchDataContextObject.Provider>
  );
};
