import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Switch, useLocation } from 'react-router-dom';

import { ResponsiveDrawer } from '../components/common/navigation/ResponsiveDrawer';

import { TopBar } from '../components/common/navigation/TopBar';
import { useKeycloak } from '@react-keycloak/web';
import { ProgressBox } from '../components/ProgressBox';
import { HomePage } from './HomePage';
import { FullWidthFooter } from '../components/common/navigation/FullWidthFooter';
import { LayoutConstants } from '../theme/LayoutConstants';
import { AuthorizedRoute } from '../components/common/AuthorizedRoute';
import { useRoutes } from '../common/routing/routes-hook';
import { Route } from '../common/routing/Route';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export function IndexPage(): JSX.Element {
  const classes = useStyles();

  const { keycloak, initialized } = useKeycloak();

  const { routes } = useRoutes();

  const pathname = useLocation().pathname;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isAdminPath = pathname.includes(routes.admin.path);
  const isSearchPath = pathname.includes(routes.search.path);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  if (!initialized) {
    return <ProgressBox fullPage={true} />;
  }

  if (!keycloak.authenticated) {
    return <HomePage />;
  }

  const contentWidth = isAdminPath
    ? `calc(100% - ${LayoutConstants.drawerWidth})`
    : '100%';

  const flexProp = isAdminPath
    ? { flexWrap: 'wrap' as const }
    : { flexDirection: 'column' as const };

  return (
    <div className={classes.root} style={{ ...flexProp }}>
      <TopBar
        handleDrawerToggle={handleDrawerToggle}
        isSearch={isSearchPath}
        showPageLinks
      />
      {isAdminPath && (
        <ResponsiveDrawer
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}
      <main className={classes.content} style={{ width: contentWidth }}>
        <div className={classes.toolbar} />
        <Switch>
          {Object.values(routes).map((route: Route<unknown>, index) => {
            return (
              <AuthorizedRoute
                exact={route.exact}
                path={route.path}
                roles={route.roles}
                key={index}
              >
                <route.component />
              </AuthorizedRoute>
            );
          })}
        </Switch>
      </main>
      {!isAdminPath && <FullWidthFooter />}
    </div>
  );
}
