import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  getTranslatedLanguage,
  SUPPORTED_LANGUAGES_OBJECT,
} from '../../../common/supported-languages';
import { titleCase } from '../../../common/StringUtils';
import { SelectInputField } from './SelectInputField';

interface Props {
  name: string;
  label: string;
  errorData?: FieldError;
}

export const OriginalLanguageSelect = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const optionData = SUPPORTED_LANGUAGES_OBJECT.map((language) => {
    return {
      value: language.locale,
      displayValue: titleCase(getTranslatedLanguage(t, language.locale)),
    };
  });

  return <SelectInputField {...props} optionData={optionData} />;
};
