import { useState } from 'react';
import { KeycloakUser } from './get-users-hook';
import { useAuthorizationHeader } from './authorization-header-hook';
import { KEYCLOAK_REALM, KEYCLOAK_URL } from '../../../config';
import axios, { AxiosRequestConfig } from 'axios';

export const useDeleteUser = (): {
  deleteUser: (user: KeycloakUser) => Promise<void>;
  loading: boolean;
  error?: Error;
} => {
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);

  const { authorizationHeader } = useAuthorizationHeader();

  const deleteUser = async (user: KeycloakUser) => {
    setLoading(true);

    const userUrl = `${KEYCLOAK_URL}/admin/realms/${KEYCLOAK_REALM}/users/${user.id}`;

    const config: AxiosRequestConfig = {
      headers: {
        ...authorizationHeader,
      },
    };

    try {
      await axios.delete(userUrl, config);
      setLoading(false);
    } catch (axiosError) {
      setError(axiosError as Error);
    }
  };

  return { deleteUser, loading, error };
};
