import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { ReactKeycloakProvider as KeycloakProvider } from '@react-keycloak/web';

import { theme } from './theme/theme';
import { keycloak, keycloakInitOptions } from './keycloak';

import { App } from './pages/App';
import { Logger } from './common/Logger';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/common/error/ErrorFallback';
import { GraphqlClientContextProvider } from './components/common/graphql/client/GraphqlClientContext';

import './index.css';

const eventLogger = (event: unknown, error?: unknown) => {
  Logger.debug(
    `onKeycloakEvent ${JSON.stringify(event)} ${JSON.stringify(error ?? '')}`
  );
};

ReactDOM.render(
  <React.StrictMode>
    {/* Due to issues with i18n initialization and Keycloak reloading the page
        we separate the KeycloakProvider from the i18n init (in App)  
    */}
    <KeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      initOptions={keycloakInitOptions}
    >
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <GraphqlClientContextProvider>
            <App />
          </GraphqlClientContextProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </KeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
