import React, { useEffect } from 'react';
import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { useTranslation } from 'react-i18next';

import { GenericTable } from '../common/table/GenericTable';
import { EditUserDialog } from './EditUserDialog';
import { KeycloakUserWithRole } from './hooks/get-users-hook';
import { useCurrentUser } from './hooks/current-user-hook';

interface Props {
  keycloakUsers: KeycloakUserWithRole[];
  refreshUsers: () => void;
}

interface UserTableRow {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export const UserTable = ({
  keycloakUsers,
  refreshUsers,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { userProfile } = useCurrentUser();

  const [userToEdit, setUserToEdit] = React.useState<
    KeycloakUserWithRole | undefined
  >();

  const [filteredUsers, setFilteredUsers] = React.useState<
    KeycloakUserWithRole[]
  >([]);

  //  Filter out the current user from the list of users
  // to prevent the user from editing their own role or deleting themselves
  useEffect(() => {
    const filteredUsers: KeycloakUserWithRole[] = keycloakUsers.filter(
      (user) => user.email !== userProfile?.email
    );
    setFilteredUsers(filteredUsers);
  }, [keycloakUsers, userProfile]);

  const handleEditUserDialogClosed = () => {
    refreshUsers();
    setUserToEdit(undefined);
  };

  const dataTable: UserTableRow[] = filteredUsers.map(
    ({ firstName, lastName, email, role }) => ({
      firstName,
      lastName,
      email,
      role,
    })
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'lastName',
      label: t('users.table.lastName'),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'firstName',
      label: t('users.table.firstName'),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'email',
      label: t('users.table.email'),
      options: {
        filterType: 'textField',
      },
    },
    {
      name: 'role',
      label: t('users.table.role'),
      options: {
        filterType: 'textField',
      },
    },
  ];

  const options: MUIDataTableOptions = {
    onRowClick(_: string[], rowMeta: { dataIndex: number; rowIndex: number }) {
      const selectedUser = filteredUsers[rowMeta.dataIndex];
      setUserToEdit(selectedUser);
    },
    selectableRows: 'none',
    sortOrder: {
      name: 'lastName',
      direction: 'asc',
    },
  };

  return (
    <>
      <EditUserDialog
        user={userToEdit}
        handleClose={handleEditUserDialogClosed}
      />
      <GenericTable
        title={t('navigation.users')}
        data={dataTable}
        columns={columns}
        options={options}
      />
    </>
  );
};
