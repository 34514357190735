import gql from 'graphql-tag';
import { TAG_FRAGMENT } from '../../fragments/tag.fragment';

export const QUERY_TAGS = gql`
  query tags {
    tags {
      ...TagData
    }
  }
  ${TAG_FRAGMENT}
`;
