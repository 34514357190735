import { Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { titleCase } from '../../../common/StringUtils';
import { TagCategoryInput, TagInput } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { InputField } from './InputField';

const ReferenceForm = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<TagCategoryInput | TagInput>();

  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'references',
  });

  const customOnBlur = () => {
    void trigger('references');
  };

  const isLastElement = (index: number) => index + 1 !== fields.length;

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item>
        <Typography variant="h6">{t('commons.form.references')}</Typography>
      </Grid>
      <Grid container item spacing={LayoutConstants.gridSpacing}>
        {fields.map((reference, index) => (
          <Grid
            item
            container
            spacing={LayoutConstants.gridSpacing}
            key={reference.text}
          >
            <Grid item container lg={4} xs={8}>
              <InputField
                {...register(`references.${index}.text` as const)}
                label={`${titleCase(t('commons.references.text'))}`}
                defaultValue={reference.text}
                error={Boolean(errors.references?.[index]?.text?.message)}
                customOnBlur={customOnBlur}
              />
              <FormHelperText error>
                {errors.references?.[index]?.text?.message}
              </FormHelperText>
            </Grid>
            <Grid item container lg={4} xs={8}>
              <InputField
                {...register(`references.${index}.url` as const)}
                label={`${titleCase(t('commons.references.url'))}`}
                defaultValue={reference.url ?? ''}
                error={Boolean(errors.references?.[index]?.url?.message)}
                customOnBlur={customOnBlur}
              />
              <FormHelperText error>
                {errors.references?.[index]?.url?.message}
              </FormHelperText>
            </Grid>
            <Grid
              item
              xs={4}
              sm={3}
              container
              spacing={LayoutConstants.gridSpacing}
              alignItems="center"
            >
              {isLastElement(index) ? (
                <Grid item xs lg={6}>
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<DeleteIcon />}
                    onClick={(): void => remove(index)}
                    fullWidth
                  >
                    {t('commons.delete')}
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} lg={6}>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={(): void => remove(index)}
                      disabled={fields.length === 1}
                      startIcon={<DeleteIcon />}
                      size="small"
                      fullWidth
                    >
                      {t('commons.delete')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(): void => insert(index + 1, { text: '' })}
                      startIcon={<AddCircle />}
                      size="small"
                      fullWidth
                    >
                      {t('commons.add')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export { ReferenceForm };
