import { Theme } from '@material-ui/core';
import { EChartsOption } from 'echarts';
import { TFunction } from 'i18next';
import { Category, Link, Node } from '../data/network.type';
import { ChartState } from '../TagGraphChart';

export const createEchartsOption = (
  t: TFunction,
  theme: Theme,
  nodes: Node[],
  links: Link[],
  tagCategoryIdToUpdatedTagCategory: Map<number, Category>,
  chartState: ChartState
): EChartsOption => {
  const echartsCategories = Array.from(
    tagCategoryIdToUpdatedTagCategory.values()
  ).filter((category) => category.show);

  const echartNodes = nodes.map((node) => {
    const categoryIndex = echartsCategories.findIndex(
      (category) => category.id === node.category
    );

    return {
      ...node,

      category: categoryIndex,
    };
  });

  return {
    title: {
      text: t('dashboard.tagVisualization.title'),
      backgroundColor: theme.palette.background.paper,
    },
    tooltip: {
      formatter: (params) => {
        // casting because this params type doesn't match the documentation
        // https://echarts.apache.org/en/option.html#tooltip.formatter
        const {
          name,
          value,
          category: echartsCategoryIndex,
        } = (params as unknown as { data: Node }).data;

        const category = echartsCategories[echartsCategoryIndex];

        const categoryName = category?.name ?? '';

        const displayName = name
          ? `<b>${t(
              'dashboard.tagVisualization.tooltip.name'
            )}: ${name}</b></br>`
          : '';

        const displayCategory =
          categoryName &&
          `${t(
            'dashboard.tagVisualization.tooltip.categoryName'
          )}: ${categoryName}</br>
            `;

        return `<div>              
                  ${displayName}
                  ${displayCategory}
                  ${t(
                    'dashboard.tagVisualization.tooltip.resourcesCount'
                  )}: ${value}
                </div>`;
      },
    },
    series: {
      name: 'Tags',
      type: 'graph',
      layout: 'force',
      data: echartNodes,
      links,
      categories: echartsCategories,
      roam: true,
      label: {
        position: 'right',
        show: chartState.showLabels,
      },
      force: {
        repulsion: 200,
      },
      draggable: true,
      zoom: 0.5,
    },
  };
};
