import * as H from 'history';
import { isInternalLinkType } from '../components/common/editor/commons';
import { ResourceType } from '../graphql/models';

export const handleContentClick =
  (history: H.History<unknown>): React.MouseEventHandler<HTMLDivElement> =>
  (event) => {
    const targetLink = (event.target as HTMLElement).closest('a');
    if (!targetLink) {
      return;
    }
    event.preventDefault();

    const isInternal = isInternalLinkType(targetLink.target);

    if (isInternal) {
      history.push(targetLink.pathname);
    } else {
      window.open(targetLink.href);
    }
  };

export const resourceTypeToTranslationKey = (
  resourceType: ResourceType
): string => {
  switch (resourceType) {
    case ResourceType.Narrative:
      return 'commons.summary';
    case ResourceType.Publication:
      return 'commons.article';
    case ResourceType.VideoLink:
      return 'commons.links';
    case ResourceType.Book:
      return 'commons.books';
    case ResourceType.Summary:
      return 'commons.academicWorks';
  }
};
