import {
  AppBar,
  createStyles,
  makeStyles,
  Tabs,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { LayoutConstants } from '../../../theme/LayoutConstants';

interface Props {
  children: React.ReactElement[];
  onTabChange?: (tabIndex: number) => void;
  initialValue?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .Mui-selected p, .Mui-selected': {
        fontWeight: theme.typography.fontWeightBold,
      },
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
      paddingTop: theme.spacing(2),
    },
  })
);

export const GenericTabs = ({
  children,
  initialValue,
  onTabChange,
}: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState(initialValue || 0);
  const classes = useStyles();

  const handleTabChange = (_: unknown, newIndex: number) => {
    setSelectedTab(newIndex);
    if (onTabChange) {
      onTabChange(newIndex);
    }
  };

  return (
    <AppBar
      className={classes.root}
      position="static"
      elevation={LayoutConstants.elevation}
      color="secondary"
    >
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {children}
      </Tabs>
    </AppBar>
  );
};
