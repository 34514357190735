import { Grid } from '@material-ui/core';
import { LayoutConstants } from '../../../../theme/LayoutConstants';
import { BackToResults } from '../../navigation/BackToResults';

interface Props {
  children?: React.ReactNode;
}

export const ResourcePageLayout = ({ children }: Props): JSX.Element => {
  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={1}>
        <BackToResults />
      </Grid>
      {children}
    </Grid>
  );
};
