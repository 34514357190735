import {
  AccordionSummary as MuiAccordionSummary,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { theme } from '../../../theme/theme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const AccordionSummary = withStyles({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: -1,
    minHeight: theme.spacing(7),
    '&expanded': {
      minHeight: theme.spacing(7),
    },
  },
  content: {
    overflow: 'hidden',
  },
})(MuiAccordionSummary);

interface Props {
  children: React.ReactNode;
}

export const GenericAccordionSummary = ({ children }: Props): JSX.Element => {
  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      {children}
    </AccordionSummary>
  );
};
