import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SupportedLanguage, TagDataFragment } from '../../../../graphql/models';
import {
  getTagDescriptionTooltipProps,
  getTranslatedTagNameOrDefault,
} from '../../../../model/Tag';
import { TagChip } from '../../../tag/TagChip';
import { LayoutConstants } from '../../../../theme/LayoutConstants';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(LayoutConstants.tagSpacing),
    marginBottom: theme.spacing(LayoutConstants.tagSpacing),
  },
}));

interface Props {
  tags: TagDataFragment[];
}

export const ResourceCardTagsSection = ({ tags }: Props): JSX.Element => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  return (
    <Grid container item xs={12} justify="flex-end" alignItems="center">
      {[...tags]
        ?.sort((a, b) => a.category.priority - b.category.priority)
        .map((tag, index) => {
          return (
            <TagChip
              className={classes.chip}
              key={index}
              label={getTranslatedTagNameOrDefault(
                tag,
                i18n.language as SupportedLanguage
              )}
              hoverValue={getTagDescriptionTooltipProps(tag, i18n, t)}
              color={tag.category.color}
            />
          );
        })}
    </Grid>
  );
};
