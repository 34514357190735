import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
};

export type Author = {
  __typename: 'Author';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AuthorInput = {
  name: Scalars['String'];
};

export type Book = {
  __typename: 'Book';
  ISBN: Scalars['String'];
  authors: Array<Author>;
  content: Array<BookContent>;
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  externalLink: Scalars['String'];
  id: Scalars['Int'];
  originalLanguage: SupportedLanguage;
  publisher: Scalars['String'];
  tags?: Maybe<Array<Tag>>;
  year: Scalars['Int'];
};

export type BookContent = {
  __typename: 'BookContent';
  language: SupportedLanguage;
  summary: Scalars['String'];
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type BookContentInput = {
  language: SupportedLanguage;
  summary: Scalars['String'];
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type BookInput = {
  ISBN: Scalars['String'];
  authors: Array<AuthorInput>;
  content: Array<BookContentInput>;
  externalLink: Scalars['String'];
  originalLanguage: SupportedLanguage;
  publisher: Scalars['String'];
  tagIds: Array<Scalars['Int']>;
  year: Scalars['Int'];
};

export type CrossrefAuthor = {
  __typename: 'CrossrefAuthor';
  name: Scalars['String'];
};

export type CrossrefPublication = {
  __typename: 'CrossrefPublication';
  authors: Array<CrossrefAuthor>;
  content?: Maybe<CrossrefPublicationContent>;
  doi: Scalars['String'];
  externalLink: Scalars['String'];
  originalLanguage?: Maybe<SupportedLanguage>;
  venue: Scalars['String'];
  year: Scalars['Int'];
};

export type CrossrefPublicationContent = {
  __typename: 'CrossrefPublicationContent';
  abstract: Scalars['String'];
  language: SupportedLanguage;
  title: Scalars['String'];
};

export type LatestEntriesInput = {
  limit: Scalars['Int'];
  preferredLanguage: SupportedLanguage;
};

export type LatestEntriesResult = {
  __typename: 'LatestEntriesResult';
  entries: Array<SearchResultData>;
};

export type Mutation = {
  __typename: 'Mutation';
  createBook: Book;
  createNarrative: Narrative;
  createPublication: Publication;
  createSummary: Summary;
  createTag: Tag;
  createTagCategory: TagCategory;
  createVideoLink: VideoLink;
  deleteBook: Book;
  deleteNarrative: NarrativeData;
  deletePublication: PublicationData;
  deleteSummary: Summary;
  deleteTag: TagData;
  deleteTagCategory: TagCategoryData;
  deleteVideoLink: VideoLink;
  updateBook: Book;
  updateNarrative: Narrative;
  updatePublication: Publication;
  updateSummary: Summary;
  updateTag: Tag;
  updateTagCategory: TagCategory;
  updateTagCategoryPriorities: Array<TagCategory>;
  updateVideoLink: VideoLink;
};

export type MutationCreateBookArgs = {
  input: BookInput;
};

export type MutationCreateNarrativeArgs = {
  input: NarrativeInput;
};

export type MutationCreatePublicationArgs = {
  input: PublicationInput;
};

export type MutationCreateSummaryArgs = {
  input: SummaryInput;
};

export type MutationCreateTagArgs = {
  input: TagInput;
};

export type MutationCreateTagCategoryArgs = {
  input: TagCategoryInput;
};

export type MutationCreateVideoLinkArgs = {
  input: VideoLinkInput;
};

export type MutationDeleteBookArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteNarrativeArgs = {
  id: Scalars['Int'];
};

export type MutationDeletePublicationArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteSummaryArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteTagArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteTagCategoryArgs = {
  id: Scalars['Int'];
};

export type MutationDeleteVideoLinkArgs = {
  id: Scalars['Int'];
};

export type MutationUpdateBookArgs = {
  id: Scalars['Int'];
  input: BookInput;
};

export type MutationUpdateNarrativeArgs = {
  id: Scalars['Int'];
  input: NarrativeInput;
};

export type MutationUpdatePublicationArgs = {
  id: Scalars['Int'];
  input: PublicationInput;
};

export type MutationUpdateSummaryArgs = {
  id: Scalars['Int'];
  input: SummaryInput;
};

export type MutationUpdateTagArgs = {
  id: Scalars['Int'];
  input: TagInput;
};

export type MutationUpdateTagCategoryArgs = {
  id: Scalars['Int'];
  input: TagCategoryInput;
};

export type MutationUpdateTagCategoryPrioritiesArgs = {
  priorityList: Array<Scalars['Int']>;
};

export type MutationUpdateVideoLinkArgs = {
  id: Scalars['Int'];
  input: VideoLinkInput;
};

export type Narrative = {
  __typename: 'Narrative';
  authors: Array<Author>;
  content: Array<NarrativeContent>;
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  id: Scalars['Int'];
  originalLanguage: SupportedLanguage;
  tags?: Maybe<Array<Tag>>;
};

export type NarrativeContent = {
  __typename: 'NarrativeContent';
  content: Scalars['String'];
  language: SupportedLanguage;
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type NarrativeContentInput = {
  content: Scalars['String'];
  language: SupportedLanguage;
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type NarrativeData = {
  __typename: 'NarrativeData';
  authors: Array<Author>;
  content: Array<NarrativeContent>;
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  originalLanguage: SupportedLanguage;
  tags?: Maybe<Array<Tag>>;
};

export type NarrativeInput = {
  authors: Array<AuthorInput>;
  content: Array<NarrativeContentInput>;
  originalLanguage: SupportedLanguage;
  tagIds: Array<Scalars['Int']>;
};

export type Publication = {
  __typename: 'Publication';
  authors: Array<Author>;
  content: Array<PublicationContent>;
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  doi: Scalars['String'];
  externalLink: Scalars['String'];
  id: Scalars['Int'];
  originalLanguage: SupportedLanguage;
  tags?: Maybe<Array<Tag>>;
  venue: Scalars['String'];
  year: Scalars['Int'];
};

export type PublicationContent = {
  __typename: 'PublicationContent';
  abstract: Scalars['String'];
  language: SupportedLanguage;
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type PublicationContentInput = {
  abstract: Scalars['String'];
  language: SupportedLanguage;
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type PublicationData = {
  __typename: 'PublicationData';
  authors: Array<Author>;
  content: Array<PublicationContent>;
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  doi: Scalars['String'];
  externalLink: Scalars['String'];
  originalLanguage: SupportedLanguage;
  tags?: Maybe<Array<Tag>>;
  venue: Scalars['String'];
  year: Scalars['Int'];
};

export type PublicationInput = {
  authors: Array<AuthorInput>;
  content: Array<PublicationContentInput>;
  doi: Scalars['String'];
  externalLink: Scalars['String'];
  originalLanguage: SupportedLanguage;
  tagIds: Array<Scalars['Int']>;
  venue: Scalars['String'];
  year: Scalars['Int'];
};

export type Query = {
  __typename: 'Query';
  book: Book;
  books: Array<Book>;
  fetchPublicationDataFromCrossref: CrossrefPublication;
  latestEntries: LatestEntriesResult;
  narrative: Narrative;
  narratives: Array<Narrative>;
  publication: Publication;
  publications: Array<Publication>;
  search: SearchResult;
  summaries: Array<Summary>;
  summary: Summary;
  tag: Tag;
  tagCategories: Array<TagCategory>;
  tagCategory: TagCategory;
  tags: Array<Tag>;
  videoLink: VideoLink;
  videoLinks: Array<VideoLink>;
};

export type QueryBookArgs = {
  id: Scalars['Int'];
};

export type QueryFetchPublicationDataFromCrossrefArgs = {
  doi: Scalars['String'];
};

export type QueryLatestEntriesArgs = {
  input: LatestEntriesInput;
};

export type QueryNarrativeArgs = {
  id: Scalars['Int'];
};

export type QueryPublicationArgs = {
  id: Scalars['Int'];
};

export type QuerySearchArgs = {
  input: SearchInput;
};

export type QuerySummaryArgs = {
  id: Scalars['Int'];
};

export type QueryTagArgs = {
  id: Scalars['Int'];
};

export type QueryTagCategoryArgs = {
  id: Scalars['Int'];
};

export type QueryVideoLinkArgs = {
  id: Scalars['Int'];
};

export enum ResourceType {
  Book = 'book',
  Narrative = 'narrative',
  Publication = 'publication',
  Summary = 'summary',
  VideoLink = 'videoLink',
}

export type SearchInput = {
  preferredLanguage: SupportedLanguage;
  searchTerm: Scalars['String'];
  tagFilter: Array<Scalars['Int']>;
};

export type SearchResult = {
  __typename: 'SearchResult';
  books: Array<SearchResultData>;
  executionTimeInSeconds: Scalars['Float'];
  narratives: Array<SearchResultData>;
  publications: Array<SearchResultData>;
  summaries: Array<SearchResultData>;
  videoLinks: Array<SearchResultData>;
};

export type SearchResultData = {
  __typename: 'SearchResultData';
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  id: Scalars['Int'];
  language: SupportedLanguage;
  metadata: Scalars['String'];
  summary: Scalars['String'];
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String'];
  type: ResourceType;
};

export type Summary = {
  __typename: 'Summary';
  authors: Array<Author>;
  content: Array<SummaryContent>;
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  externalLink: Scalars['String'];
  id: Scalars['Int'];
  originalLanguage: SupportedLanguage;
  tags?: Maybe<Array<Tag>>;
};

export type SummaryContent = {
  __typename: 'SummaryContent';
  language: SupportedLanguage;
  text: Scalars['String'];
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type SummaryContentInput = {
  language: SupportedLanguage;
  text: Scalars['String'];
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type SummaryInput = {
  authors: Array<AuthorInput>;
  content: Array<SummaryContentInput>;
  externalLink: Scalars['String'];
  originalLanguage: SupportedLanguage;
  tagIds: Array<Scalars['Int']>;
};

export enum SupportedLanguage {
  De = 'de',
  En = 'en',
  It = 'it',
}

export type Tag = {
  __typename: 'Tag';
  bookIds: Array<Scalars['Int']>;
  category: TagCategory;
  content: Array<TagContent>;
  id: Scalars['Int'];
  narrativeIds: Array<Scalars['Int']>;
  publicationIds: Array<Scalars['Int']>;
  references: Array<TagReference>;
  summaryIds: Array<Scalars['Int']>;
  videoLinkIds: Array<Scalars['Int']>;
};

export type TagCategory = {
  __typename: 'TagCategory';
  color: Scalars['String'];
  content: Array<TagCategoryContent>;
  id: Scalars['Int'];
  priority: Scalars['Int'];
  references: Array<TagCategoryReference>;
  tags?: Maybe<Array<Tag>>;
};

export type TagCategoryContent = {
  __typename: 'TagCategoryContent';
  description?: Maybe<Scalars['String']>;
  language: SupportedLanguage;
  name: Scalars['String'];
};

export type TagCategoryContentInput = {
  description?: Maybe<Scalars['String']>;
  language: SupportedLanguage;
  name: Scalars['String'];
};

export type TagCategoryData = {
  __typename: 'TagCategoryData';
  color: Scalars['String'];
  content: Array<TagCategoryContent>;
  priority: Scalars['Int'];
  references: Array<TagCategoryReference>;
  tags?: Maybe<Array<Tag>>;
};

export type TagCategoryInput = {
  color: Scalars['String'];
  content: Array<TagCategoryContentInput>;
  references: Array<TagCategoryReferenceInput>;
};

export type TagCategoryReference = {
  __typename: 'TagCategoryReference';
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type TagCategoryReferenceInput = {
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type TagContent = {
  __typename: 'TagContent';
  description?: Maybe<Scalars['String']>;
  language: SupportedLanguage;
  name: Scalars['String'];
};

export type TagContentInput = {
  description?: Maybe<Scalars['String']>;
  language: SupportedLanguage;
  name: Scalars['String'];
};

export type TagData = {
  __typename: 'TagData';
  bookIds: Array<Scalars['Int']>;
  category: TagCategory;
  content: Array<TagContent>;
  narrativeIds: Array<Scalars['Int']>;
  publicationIds: Array<Scalars['Int']>;
  references: Array<TagReference>;
  summaryIds: Array<Scalars['Int']>;
  videoLinkIds: Array<Scalars['Int']>;
};

export type TagInput = {
  categoryId: Scalars['Int'];
  content: Array<TagContentInput>;
  references: Array<TagReferenceInput>;
};

export type TagReference = {
  __typename: 'TagReference';
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type TagReferenceInput = {
  text: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type VideoLink = {
  __typename: 'VideoLink';
  authors: Array<Author>;
  content: Array<VideoLinkContent>;
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  id: Scalars['Int'];
  link: Scalars['String'];
  originalLanguage: SupportedLanguage;
  tags?: Maybe<Array<Tag>>;
};

export type VideoLinkContent = {
  __typename: 'VideoLinkContent';
  description: Scalars['String'];
  language: SupportedLanguage;
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type VideoLinkContentInput = {
  description: Scalars['String'];
  language: SupportedLanguage;
  title: Scalars['String'];
  translator: Scalars['String'];
};

export type VideoLinkInput = {
  authors: Array<AuthorInput>;
  content: Array<VideoLinkContentInput>;
  link: Scalars['String'];
  originalLanguage: SupportedLanguage;
  tagIds: Array<Scalars['Int']>;
};

export type AcademicWorkDataFragment = { __typename: 'Summary' } & Pick<
  Summary,
  'id' | 'externalLink' | 'originalLanguage'
> & {
    authors: Array<{ __typename: 'Author' } & AuthorDataFragment>;
    content: Array<
      { __typename: 'SummaryContent' } & Pick<
        SummaryContent,
        'text' | 'language' | 'title' | 'translator'
      >
    >;
    tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
  };

export type ArticleDataFragment = { __typename: 'Publication' } & Pick<
  Publication,
  'id' | 'doi' | 'externalLink' | 'originalLanguage' | 'venue' | 'year'
> & {
    authors: Array<{ __typename: 'Author' } & AuthorDataFragment>;
    content: Array<
      { __typename: 'PublicationContent' } & Pick<
        PublicationContent,
        'abstract' | 'language' | 'title' | 'translator'
      >
    >;
    tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
  };

export type AuthorDataFragment = { __typename: 'Author' } & Pick<
  Author,
  'id' | 'name'
>;

export type BookDataFragment = { __typename: 'Book' } & Pick<
  Book,
  'id' | 'ISBN' | 'externalLink' | 'originalLanguage' | 'publisher' | 'year'
> & {
    authors: Array<{ __typename: 'Author' } & AuthorDataFragment>;
    content: Array<
      { __typename: 'BookContent' } & Pick<
        BookContent,
        'summary' | 'language' | 'title' | 'translator'
      >
    >;
    tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
  };

export type CrossrefPublicationDataFragment = {
  __typename: 'CrossrefPublication';
} & Pick<
  CrossrefPublication,
  'doi' | 'externalLink' | 'venue' | 'year' | 'originalLanguage'
> & {
    authors: Array<
      { __typename: 'CrossrefAuthor' } & Pick<CrossrefAuthor, 'name'>
    >;
    content?: Maybe<
      { __typename: 'CrossrefPublicationContent' } & Pick<
        CrossrefPublicationContent,
        'language' | 'title' | 'abstract'
      >
    >;
  };

export type LinkDataFragment = { __typename: 'VideoLink' } & Pick<
  VideoLink,
  'id' | 'originalLanguage' | 'link'
> & {
    authors: Array<{ __typename: 'Author' } & AuthorDataFragment>;
    content: Array<
      { __typename: 'VideoLinkContent' } & Pick<
        VideoLinkContent,
        'description' | 'language' | 'title' | 'translator'
      >
    >;
    tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
  };

export type SearchResultDataFragment = {
  __typename: 'SearchResultData';
} & Pick<
  SearchResultData,
  'id' | 'language' | 'dateCreated' | 'type' | 'title' | 'metadata' | 'summary'
> & { tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>> };

export type SearchResultFragment = { __typename: 'SearchResult' } & Pick<
  SearchResult,
  'executionTimeInSeconds'
> & {
    summaries: Array<
      { __typename: 'SearchResultData' } & SearchResultDataFragment
    >;
    articles: Array<
      { __typename: 'SearchResultData' } & SearchResultDataFragment
    >;
    links: Array<{ __typename: 'SearchResultData' } & SearchResultDataFragment>;
    books: Array<{ __typename: 'SearchResultData' } & SearchResultDataFragment>;
    academicWorks: Array<
      { __typename: 'SearchResultData' } & SearchResultDataFragment
    >;
  };

export type SummaryDataFragment = { __typename: 'Narrative' } & Pick<
  Narrative,
  'id' | 'dateCreated' | 'dateUpdated' | 'originalLanguage'
> & {
    authors: Array<{ __typename: 'Author' } & AuthorDataFragment>;
    content: Array<
      { __typename: 'NarrativeContent' } & Pick<
        NarrativeContent,
        'content' | 'language' | 'title' | 'translator'
      >
    >;
    tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
  };

export type TagCategoryDataFragment = { __typename: 'TagCategory' } & Pick<
  TagCategory,
  'id' | 'color' | 'priority'
> & {
    content: Array<
      { __typename: 'TagCategoryContent' } & Pick<
        TagCategoryContent,
        'name' | 'description' | 'language'
      >
    >;
    references: Array<
      { __typename: 'TagCategoryReference' } & Pick<
        TagCategoryReference,
        'text' | 'url'
      >
    >;
  };

export type TagDataFragment = { __typename: 'Tag' } & Pick<
  Tag,
  'id' | 'bookIds'
> & {
    articleIds: Tag['publicationIds'];
    summaryIds: Tag['narrativeIds'];
    linkIds: Tag['videoLinkIds'];
    academicWorkIds: Tag['summaryIds'];
  } & {
    category: { __typename: 'TagCategory' } & TagCategoryDataFragment;
    content: Array<
      { __typename: 'TagContent' } & Pick<
        TagContent,
        'name' | 'description' | 'language'
      >
    >;
    references: Array<
      { __typename: 'TagReference' } & Pick<TagReference, 'text' | 'url'>
    >;
  };

export type CreateAcademicWorkMutationVariables = Exact<{
  input: SummaryInput;
}>;

export type CreateAcademicWorkMutation = { __typename: 'Mutation' } & {
  createAcademicWork: { __typename: 'Summary' } & AcademicWorkDataFragment;
};

export type DeleteAcademicWorkMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteAcademicWorkMutation = { __typename: 'Mutation' } & {
  deleteAcademicWork: { __typename: 'Summary' } & Pick<
    Summary,
    'originalLanguage'
  > & {
      content: Array<
        { __typename: 'SummaryContent' } & Pick<
          SummaryContent,
          'text' | 'language' | 'title' | 'translator'
        >
      >;
    };
};

export type UpdateAcademicWorkMutationVariables = Exact<{
  id: Scalars['Int'];
  input: SummaryInput;
}>;

export type UpdateAcademicWorkMutation = { __typename: 'Mutation' } & {
  updateAcademicWork: { __typename: 'Summary' } & AcademicWorkDataFragment;
};

export type CreateArticleMutationVariables = Exact<{
  input: PublicationInput;
}>;

export type CreateArticleMutation = { __typename: 'Mutation' } & {
  createArticle: { __typename: 'Publication' } & ArticleDataFragment;
};

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteArticleMutation = { __typename: 'Mutation' } & {
  deleteArticle: { __typename: 'PublicationData' } & Pick<
    PublicationData,
    'doi'
  >;
};

export type UpdateArticleMutationVariables = Exact<{
  id: Scalars['Int'];
  input: PublicationInput;
}>;

export type UpdateArticleMutation = { __typename: 'Mutation' } & {
  updateArticle: { __typename: 'Publication' } & ArticleDataFragment;
};

export type CreateBookMutationVariables = Exact<{
  input: BookInput;
}>;

export type CreateBookMutation = { __typename: 'Mutation' } & {
  createBook: { __typename: 'Book' } & BookDataFragment;
};

export type DeleteBookMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteBookMutation = { __typename: 'Mutation' } & {
  deleteBook: { __typename: 'Book' } & Pick<Book, 'ISBN'>;
};

export type UpdateBookMutationVariables = Exact<{
  id: Scalars['Int'];
  input: BookInput;
}>;

export type UpdateBookMutation = { __typename: 'Mutation' } & {
  updateBook: { __typename: 'Book' } & BookDataFragment;
};

export type CreateLinkMutationVariables = Exact<{
  input: VideoLinkInput;
}>;

export type CreateLinkMutation = { __typename: 'Mutation' } & {
  createLink: { __typename: 'VideoLink' } & LinkDataFragment;
};

export type DeleteLinkMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteLinkMutation = { __typename: 'Mutation' } & {
  deleteLink: { __typename: 'VideoLink' } & Pick<
    VideoLink,
    'link' | 'originalLanguage'
  > & {
      content: Array<
        { __typename: 'VideoLinkContent' } & Pick<
          VideoLinkContent,
          'description' | 'language' | 'title' | 'translator'
        >
      >;
    };
};

export type UpdateLinkMutationVariables = Exact<{
  id: Scalars['Int'];
  input: VideoLinkInput;
}>;

export type UpdateLinkMutation = { __typename: 'Mutation' } & {
  updateLink: { __typename: 'VideoLink' } & LinkDataFragment;
};

export type CreateSummaryMutationVariables = Exact<{
  input: NarrativeInput;
}>;

export type CreateSummaryMutation = { __typename: 'Mutation' } & {
  createSummary: { __typename: 'Narrative' } & SummaryDataFragment;
};

export type DeleteSummaryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteSummaryMutation = { __typename: 'Mutation' } & {
  deleteSummary: { __typename: 'NarrativeData' } & Pick<
    NarrativeData,
    'dateCreated' | 'dateUpdated' | 'originalLanguage'
  > & {
      content: Array<
        { __typename: 'NarrativeContent' } & Pick<
          NarrativeContent,
          'content' | 'language' | 'title' | 'translator'
        >
      >;
    };
};

export type UpdateSummaryMutationVariables = Exact<{
  id: Scalars['Int'];
  input: NarrativeInput;
}>;

export type UpdateSummaryMutation = { __typename: 'Mutation' } & {
  updateSummary: { __typename: 'Narrative' } & SummaryDataFragment;
};

export type CreateTagCategoryMutationVariables = Exact<{
  input: TagCategoryInput;
}>;

export type CreateTagCategoryMutation = { __typename: 'Mutation' } & {
  createTagCategory: { __typename: 'TagCategory' } & TagCategoryDataFragment;
};

export type DeleteTagCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTagCategoryMutation = { __typename: 'Mutation' } & {
  deleteTagCategory: { __typename: 'TagCategoryData' } & Pick<
    TagCategoryData,
    'color' | 'priority'
  > & {
      content: Array<
        { __typename: 'TagCategoryContent' } & Pick<
          TagCategoryContent,
          'name' | 'language'
        >
      >;
    };
};

export type UpdateTagCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  input: TagCategoryInput;
}>;

export type UpdateTagCategoryMutation = { __typename: 'Mutation' } & {
  updateTagCategory: { __typename: 'TagCategory' } & TagCategoryDataFragment;
};

export type UpdateTagCategoryPrioritiesMutationVariables = Exact<{
  priorityList: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type UpdateTagCategoryPrioritiesMutation = { __typename: 'Mutation' } & {
  updateTagCategoryPriorities: Array<
    { __typename: 'TagCategory' } & TagCategoryDataFragment
  >;
};

export type CreateTagMutationVariables = Exact<{
  input: TagInput;
}>;

export type CreateTagMutation = { __typename: 'Mutation' } & {
  createTag: { __typename: 'Tag' } & TagDataFragment;
};

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteTagMutation = { __typename: 'Mutation' } & {
  deleteTag: { __typename: 'TagData' } & {
    content: Array<
      { __typename: 'TagContent' } & Pick<TagContent, 'name' | 'language'>
    >;
  };
};

export type UpdateTagMutationVariables = Exact<{
  id: Scalars['Int'];
  input: TagInput;
}>;

export type UpdateTagMutation = { __typename: 'Mutation' } & {
  updateTag: { __typename: 'Tag' } & TagDataFragment;
};

export type AcademicWorkQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type AcademicWorkQuery = { __typename: 'Query' } & {
  academicWork: { __typename: 'Summary' } & AcademicWorkDataFragment;
};

export type AcademicWorksTableQueryVariables = Exact<{ [key: string]: never }>;

export type AcademicWorksTableQuery = { __typename: 'Query' } & {
  academicWorks: Array<
    { __typename: 'Summary' } & Pick<Summary, 'id'> & {
        authors: Array<{ __typename: 'Author' } & Pick<Author, 'name'>>;
        content: Array<
          { __typename: 'SummaryContent' } & Pick<
            SummaryContent,
            'title' | 'language'
          >
        >;
        tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
      }
  >;
};

export type AcademicWorksQueryVariables = Exact<{ [key: string]: never }>;

export type AcademicWorksQuery = { __typename: 'Query' } & {
  academicWorks: Array<{ __typename: 'Summary' } & AcademicWorkDataFragment>;
};

export type ArticleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ArticleQuery = { __typename: 'Query' } & {
  article: { __typename: 'Publication' } & ArticleDataFragment;
};

export type ArticleDoisQueryVariables = Exact<{ [key: string]: never }>;

export type ArticleDoisQuery = { __typename: 'Query' } & {
  articles: Array<
    { __typename: 'Publication' } & Pick<Publication, 'id' | 'doi'>
  >;
};

export type ArticlesTableQueryVariables = Exact<{ [key: string]: never }>;

export type ArticlesTableQuery = { __typename: 'Query' } & {
  articles: Array<
    { __typename: 'Publication' } & Pick<Publication, 'id' | 'doi' | 'year'> & {
        authors: Array<{ __typename: 'Author' } & Pick<Author, 'name'>>;
        content: Array<
          { __typename: 'PublicationContent' } & Pick<
            PublicationContent,
            'title' | 'language'
          >
        >;
        tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
      }
  >;
};

export type ArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type ArticlesQuery = { __typename: 'Query' } & {
  articles: Array<{ __typename: 'Publication' } & ArticleDataFragment>;
};

export type BookQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type BookQuery = { __typename: 'Query' } & {
  book: { __typename: 'Book' } & BookDataFragment;
};

export type BooksTableQueryVariables = Exact<{ [key: string]: never }>;

export type BooksTableQuery = { __typename: 'Query' } & {
  books: Array<
    { __typename: 'Book' } & Pick<Book, 'id' | 'ISBN' | 'year'> & {
        authors: Array<{ __typename: 'Author' } & Pick<Author, 'name'>>;
        content: Array<
          { __typename: 'BookContent' } & Pick<
            BookContent,
            'title' | 'language'
          >
        >;
        tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
      }
  >;
};

export type BooksQueryVariables = Exact<{ [key: string]: never }>;

export type BooksQuery = { __typename: 'Query' } & {
  books: Array<{ __typename: 'Book' } & BookDataFragment>;
};

export type FetchPublicationDataFromCrossrefQueryVariables = Exact<{
  doi: Scalars['String'];
}>;

export type FetchPublicationDataFromCrossrefQuery = { __typename: 'Query' } & {
  fetchPublicationDataFromCrossref: {
    __typename: 'CrossrefPublication';
  } & CrossrefPublicationDataFragment;
};

export type LatestEntriesQueryVariables = Exact<{
  latestEntriesInput: LatestEntriesInput;
}>;

export type LatestEntriesQuery = { __typename: 'Query' } & {
  latestEntries: { __typename: 'LatestEntriesResult' } & {
    entries: Array<
      { __typename: 'SearchResultData' } & SearchResultDataFragment
    >;
  };
};

export type LinkQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type LinkQuery = { __typename: 'Query' } & {
  link: { __typename: 'VideoLink' } & LinkDataFragment;
};

export type LinksTableQueryVariables = Exact<{ [key: string]: never }>;

export type LinksTableQuery = { __typename: 'Query' } & {
  links: Array<
    { __typename: 'VideoLink' } & Pick<VideoLink, 'id'> & {
        authors: Array<{ __typename: 'Author' } & Pick<Author, 'name'>>;
        content: Array<
          { __typename: 'VideoLinkContent' } & Pick<
            VideoLinkContent,
            'title' | 'language'
          >
        >;
        tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
      }
  >;
};

export type LinksQueryVariables = Exact<{ [key: string]: never }>;

export type LinksQuery = { __typename: 'Query' } & {
  links: Array<{ __typename: 'VideoLink' } & LinkDataFragment>;
};

export type SearchQueryVariables = Exact<{
  input: SearchInput;
}>;

export type SearchQuery = { __typename: 'Query' } & {
  search: { __typename: 'SearchResult' } & SearchResultFragment;
};

export type SummariesTableQueryVariables = Exact<{ [key: string]: never }>;

export type SummariesTableQuery = { __typename: 'Query' } & {
  summaries: Array<
    { __typename: 'Narrative' } & Pick<Narrative, 'id'> & {
        authors: Array<{ __typename: 'Author' } & Pick<Author, 'name'>>;
        content: Array<
          { __typename: 'NarrativeContent' } & Pick<
            NarrativeContent,
            'title' | 'language'
          >
        >;
        tags?: Maybe<Array<{ __typename: 'Tag' } & TagDataFragment>>;
      }
  >;
};

export type SummariesQueryVariables = Exact<{ [key: string]: never }>;

export type SummariesQuery = { __typename: 'Query' } & {
  summaries: Array<{ __typename: 'Narrative' } & SummaryDataFragment>;
};

export type SummaryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type SummaryQuery = { __typename: 'Query' } & {
  summary: { __typename: 'Narrative' } & SummaryDataFragment;
};

export type TagCategoriesTableQueryVariables = Exact<{ [key: string]: never }>;

export type TagCategoriesTableQuery = { __typename: 'Query' } & {
  tagCategories: Array<{ __typename: 'TagCategory' } & TagCategoryDataFragment>;
};

export type TagCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type TagCategoriesQuery = { __typename: 'Query' } & {
  tagCategories: Array<{ __typename: 'TagCategory' } & TagCategoryDataFragment>;
};

export type TagCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TagCategoryQuery = { __typename: 'Query' } & {
  tagCategory: { __typename: 'TagCategory' } & TagCategoryDataFragment;
};

export type TagQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type TagQuery = { __typename: 'Query' } & {
  tag: { __typename: 'Tag' } & TagDataFragment;
};

export type TagsTableQueryVariables = Exact<{ [key: string]: never }>;

export type TagsTableQuery = { __typename: 'Query' } & {
  tags: Array<{ __typename: 'Tag' } & TagDataFragment>;
};

export type TagsQueryVariables = Exact<{ [key: string]: never }>;

export type TagsQuery = { __typename: 'Query' } & {
  tags: Array<{ __typename: 'Tag' } & TagDataFragment>;
};

export const AuthorDataFragmentDoc = gql`
  fragment AuthorData on Author {
    id
    name
  }
`;
export const TagCategoryDataFragmentDoc = gql`
  fragment TagCategoryData on TagCategory {
    id
    content {
      name
      description
      language
    }
    references {
      text
      url
    }
    color
    priority
  }
`;
export const TagDataFragmentDoc = gql`
  fragment TagData on Tag {
    id
    category {
      ...TagCategoryData
    }
    content {
      name
      description
      language
    }
    references {
      text
      url
    }
    articleIds: publicationIds
    summaryIds: narrativeIds
    linkIds: videoLinkIds
    bookIds
    academicWorkIds: summaryIds
  }
  ${TagCategoryDataFragmentDoc}
`;
export const AcademicWorkDataFragmentDoc = gql`
  fragment AcademicWorkData on Summary {
    id
    externalLink
    originalLanguage
    authors {
      ...AuthorData
    }
    content {
      text
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AuthorDataFragmentDoc}
  ${TagDataFragmentDoc}
`;
export const ArticleDataFragmentDoc = gql`
  fragment ArticleData on Publication {
    id
    doi
    externalLink
    originalLanguage
    venue
    year
    authors {
      ...AuthorData
    }
    content {
      abstract
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AuthorDataFragmentDoc}
  ${TagDataFragmentDoc}
`;
export const BookDataFragmentDoc = gql`
  fragment BookData on Book {
    id
    ISBN
    externalLink
    originalLanguage
    publisher
    year
    authors {
      ...AuthorData
    }
    content {
      summary
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AuthorDataFragmentDoc}
  ${TagDataFragmentDoc}
`;
export const CrossrefPublicationDataFragmentDoc = gql`
  fragment CrossrefPublicationData on CrossrefPublication {
    doi
    externalLink
    venue
    year
    originalLanguage
    authors {
      name
    }
    content {
      language
      title
      abstract
    }
  }
`;
export const LinkDataFragmentDoc = gql`
  fragment LinkData on VideoLink {
    id
    originalLanguage
    link
    authors {
      ...AuthorData
    }
    content {
      description
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AuthorDataFragmentDoc}
  ${TagDataFragmentDoc}
`;
export const SearchResultDataFragmentDoc = gql`
  fragment SearchResultData on SearchResultData {
    id
    language
    dateCreated
    type
    title
    metadata
    summary
    tags {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;
export const SearchResultFragmentDoc = gql`
  fragment SearchResult on SearchResult {
    executionTimeInSeconds
    summaries: narratives {
      ...SearchResultData
    }
    articles: publications {
      ...SearchResultData
    }
    links: videoLinks {
      ...SearchResultData
    }
    books {
      ...SearchResultData
    }
    academicWorks: summaries {
      ...SearchResultData
    }
  }
  ${SearchResultDataFragmentDoc}
`;
export const SummaryDataFragmentDoc = gql`
  fragment SummaryData on Narrative {
    id
    dateCreated
    dateUpdated
    originalLanguage
    authors {
      ...AuthorData
    }
    content {
      content
      language
      title
      translator
    }
    tags {
      ...TagData
    }
  }
  ${AuthorDataFragmentDoc}
  ${TagDataFragmentDoc}
`;
export const CreateAcademicWorkDocument = gql`
  mutation createAcademicWork($input: SummaryInput!) {
    createAcademicWork: createSummary(input: $input) {
      ...AcademicWorkData
    }
  }
  ${AcademicWorkDataFragmentDoc}
`;
export type CreateAcademicWorkMutationFn = Apollo.MutationFunction<
  CreateAcademicWorkMutation,
  CreateAcademicWorkMutationVariables
>;

/**
 * __useCreateAcademicWorkMutation__
 *
 * To run a mutation, you first call `useCreateAcademicWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAcademicWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAcademicWorkMutation, { data, loading, error }] = useCreateAcademicWorkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAcademicWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAcademicWorkMutation,
    CreateAcademicWorkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAcademicWorkMutation,
    CreateAcademicWorkMutationVariables
  >(CreateAcademicWorkDocument, options);
}
export type CreateAcademicWorkMutationHookResult = ReturnType<
  typeof useCreateAcademicWorkMutation
>;
export type CreateAcademicWorkMutationResult =
  Apollo.MutationResult<CreateAcademicWorkMutation>;
export type CreateAcademicWorkMutationOptions = Apollo.BaseMutationOptions<
  CreateAcademicWorkMutation,
  CreateAcademicWorkMutationVariables
>;
export const DeleteAcademicWorkDocument = gql`
  mutation deleteAcademicWork($id: Int!) {
    deleteAcademicWork: deleteSummary(id: $id) {
      originalLanguage
      content {
        text
        language
        title
        translator
      }
    }
  }
`;
export type DeleteAcademicWorkMutationFn = Apollo.MutationFunction<
  DeleteAcademicWorkMutation,
  DeleteAcademicWorkMutationVariables
>;

/**
 * __useDeleteAcademicWorkMutation__
 *
 * To run a mutation, you first call `useDeleteAcademicWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAcademicWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAcademicWorkMutation, { data, loading, error }] = useDeleteAcademicWorkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAcademicWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAcademicWorkMutation,
    DeleteAcademicWorkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAcademicWorkMutation,
    DeleteAcademicWorkMutationVariables
  >(DeleteAcademicWorkDocument, options);
}
export type DeleteAcademicWorkMutationHookResult = ReturnType<
  typeof useDeleteAcademicWorkMutation
>;
export type DeleteAcademicWorkMutationResult =
  Apollo.MutationResult<DeleteAcademicWorkMutation>;
export type DeleteAcademicWorkMutationOptions = Apollo.BaseMutationOptions<
  DeleteAcademicWorkMutation,
  DeleteAcademicWorkMutationVariables
>;
export const UpdateAcademicWorkDocument = gql`
  mutation updateAcademicWork($id: Int!, $input: SummaryInput!) {
    updateAcademicWork: updateSummary(id: $id, input: $input) {
      ...AcademicWorkData
    }
  }
  ${AcademicWorkDataFragmentDoc}
`;
export type UpdateAcademicWorkMutationFn = Apollo.MutationFunction<
  UpdateAcademicWorkMutation,
  UpdateAcademicWorkMutationVariables
>;

/**
 * __useUpdateAcademicWorkMutation__
 *
 * To run a mutation, you first call `useUpdateAcademicWorkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAcademicWorkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAcademicWorkMutation, { data, loading, error }] = useUpdateAcademicWorkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAcademicWorkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAcademicWorkMutation,
    UpdateAcademicWorkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAcademicWorkMutation,
    UpdateAcademicWorkMutationVariables
  >(UpdateAcademicWorkDocument, options);
}
export type UpdateAcademicWorkMutationHookResult = ReturnType<
  typeof useUpdateAcademicWorkMutation
>;
export type UpdateAcademicWorkMutationResult =
  Apollo.MutationResult<UpdateAcademicWorkMutation>;
export type UpdateAcademicWorkMutationOptions = Apollo.BaseMutationOptions<
  UpdateAcademicWorkMutation,
  UpdateAcademicWorkMutationVariables
>;
export const CreateArticleDocument = gql`
  mutation createArticle($input: PublicationInput!) {
    createArticle: createPublication(input: $input) {
      ...ArticleData
    }
  }
  ${ArticleDataFragmentDoc}
`;
export type CreateArticleMutationFn = Apollo.MutationFunction<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;

/**
 * __useCreateArticleMutation__
 *
 * To run a mutation, you first call `useCreateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArticleMutation, { data, loading, error }] = useCreateArticleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateArticleMutation,
    CreateArticleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateArticleMutation,
    CreateArticleMutationVariables
  >(CreateArticleDocument, options);
}
export type CreateArticleMutationHookResult = ReturnType<
  typeof useCreateArticleMutation
>;
export type CreateArticleMutationResult =
  Apollo.MutationResult<CreateArticleMutation>;
export type CreateArticleMutationOptions = Apollo.BaseMutationOptions<
  CreateArticleMutation,
  CreateArticleMutationVariables
>;
export const DeleteArticleDocument = gql`
  mutation deleteArticle($id: Int!) {
    deleteArticle: deletePublication(id: $id) {
      doi
    }
  }
`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;

/**
 * __useDeleteArticleMutation__
 *
 * To run a mutation, you first call `useDeleteArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArticleMutation, { data, loading, error }] = useDeleteArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteArticleMutation,
    DeleteArticleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteArticleMutation,
    DeleteArticleMutationVariables
  >(DeleteArticleDocument, options);
}
export type DeleteArticleMutationHookResult = ReturnType<
  typeof useDeleteArticleMutation
>;
export type DeleteArticleMutationResult =
  Apollo.MutationResult<DeleteArticleMutation>;
export type DeleteArticleMutationOptions = Apollo.BaseMutationOptions<
  DeleteArticleMutation,
  DeleteArticleMutationVariables
>;
export const UpdateArticleDocument = gql`
  mutation updateArticle($id: Int!, $input: PublicationInput!) {
    updateArticle: updatePublication(id: $id, input: $input) {
      ...ArticleData
    }
  }
  ${ArticleDataFragmentDoc}
`;
export type UpdateArticleMutationFn = Apollo.MutationFunction<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>;

/**
 * __useUpdateArticleMutation__
 *
 * To run a mutation, you first call `useUpdateArticleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArticleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArticleMutation, { data, loading, error }] = useUpdateArticleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateArticleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateArticleMutation,
    UpdateArticleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateArticleMutation,
    UpdateArticleMutationVariables
  >(UpdateArticleDocument, options);
}
export type UpdateArticleMutationHookResult = ReturnType<
  typeof useUpdateArticleMutation
>;
export type UpdateArticleMutationResult =
  Apollo.MutationResult<UpdateArticleMutation>;
export type UpdateArticleMutationOptions = Apollo.BaseMutationOptions<
  UpdateArticleMutation,
  UpdateArticleMutationVariables
>;
export const CreateBookDocument = gql`
  mutation createBook($input: BookInput!) {
    createBook(input: $input) {
      ...BookData
    }
  }
  ${BookDataFragmentDoc}
`;
export type CreateBookMutationFn = Apollo.MutationFunction<
  CreateBookMutation,
  CreateBookMutationVariables
>;

/**
 * __useCreateBookMutation__
 *
 * To run a mutation, you first call `useCreateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookMutation, { data, loading, error }] = useCreateBookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookMutation,
    CreateBookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBookMutation, CreateBookMutationVariables>(
    CreateBookDocument,
    options
  );
}
export type CreateBookMutationHookResult = ReturnType<
  typeof useCreateBookMutation
>;
export type CreateBookMutationResult =
  Apollo.MutationResult<CreateBookMutation>;
export type CreateBookMutationOptions = Apollo.BaseMutationOptions<
  CreateBookMutation,
  CreateBookMutationVariables
>;
export const DeleteBookDocument = gql`
  mutation deleteBook($id: Int!) {
    deleteBook(id: $id) {
      ISBN
    }
  }
`;
export type DeleteBookMutationFn = Apollo.MutationFunction<
  DeleteBookMutation,
  DeleteBookMutationVariables
>;

/**
 * __useDeleteBookMutation__
 *
 * To run a mutation, you first call `useDeleteBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookMutation, { data, loading, error }] = useDeleteBookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBookMutation,
    DeleteBookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBookMutation, DeleteBookMutationVariables>(
    DeleteBookDocument,
    options
  );
}
export type DeleteBookMutationHookResult = ReturnType<
  typeof useDeleteBookMutation
>;
export type DeleteBookMutationResult =
  Apollo.MutationResult<DeleteBookMutation>;
export type DeleteBookMutationOptions = Apollo.BaseMutationOptions<
  DeleteBookMutation,
  DeleteBookMutationVariables
>;
export const UpdateBookDocument = gql`
  mutation updateBook($id: Int!, $input: BookInput!) {
    updateBook(id: $id, input: $input) {
      ...BookData
    }
  }
  ${BookDataFragmentDoc}
`;
export type UpdateBookMutationFn = Apollo.MutationFunction<
  UpdateBookMutation,
  UpdateBookMutationVariables
>;

/**
 * __useUpdateBookMutation__
 *
 * To run a mutation, you first call `useUpdateBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookMutation, { data, loading, error }] = useUpdateBookMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookMutation,
    UpdateBookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBookMutation, UpdateBookMutationVariables>(
    UpdateBookDocument,
    options
  );
}
export type UpdateBookMutationHookResult = ReturnType<
  typeof useUpdateBookMutation
>;
export type UpdateBookMutationResult =
  Apollo.MutationResult<UpdateBookMutation>;
export type UpdateBookMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookMutation,
  UpdateBookMutationVariables
>;
export const CreateLinkDocument = gql`
  mutation createLink($input: VideoLinkInput!) {
    createLink: createVideoLink(input: $input) {
      ...LinkData
    }
  }
  ${LinkDataFragmentDoc}
`;
export type CreateLinkMutationFn = Apollo.MutationFunction<
  CreateLinkMutation,
  CreateLinkMutationVariables
>;

/**
 * __useCreateLinkMutation__
 *
 * To run a mutation, you first call `useCreateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkMutation, { data, loading, error }] = useCreateLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLinkMutation,
    CreateLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLinkMutation, CreateLinkMutationVariables>(
    CreateLinkDocument,
    options
  );
}
export type CreateLinkMutationHookResult = ReturnType<
  typeof useCreateLinkMutation
>;
export type CreateLinkMutationResult =
  Apollo.MutationResult<CreateLinkMutation>;
export type CreateLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateLinkMutation,
  CreateLinkMutationVariables
>;
export const DeleteLinkDocument = gql`
  mutation deleteLink($id: Int!) {
    deleteLink: deleteVideoLink(id: $id) {
      link
      originalLanguage
      content {
        description
        language
        title
        translator
      }
    }
  }
`;
export type DeleteLinkMutationFn = Apollo.MutationFunction<
  DeleteLinkMutation,
  DeleteLinkMutationVariables
>;

/**
 * __useDeleteLinkMutation__
 *
 * To run a mutation, you first call `useDeleteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkMutation, { data, loading, error }] = useDeleteLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLinkMutation,
    DeleteLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLinkMutation, DeleteLinkMutationVariables>(
    DeleteLinkDocument,
    options
  );
}
export type DeleteLinkMutationHookResult = ReturnType<
  typeof useDeleteLinkMutation
>;
export type DeleteLinkMutationResult =
  Apollo.MutationResult<DeleteLinkMutation>;
export type DeleteLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteLinkMutation,
  DeleteLinkMutationVariables
>;
export const UpdateLinkDocument = gql`
  mutation updateLink($id: Int!, $input: VideoLinkInput!) {
    updateLink: updateVideoLink(id: $id, input: $input) {
      ...LinkData
    }
  }
  ${LinkDataFragmentDoc}
`;
export type UpdateLinkMutationFn = Apollo.MutationFunction<
  UpdateLinkMutation,
  UpdateLinkMutationVariables
>;

/**
 * __useUpdateLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkMutation, { data, loading, error }] = useUpdateLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLinkMutation,
    UpdateLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLinkMutation, UpdateLinkMutationVariables>(
    UpdateLinkDocument,
    options
  );
}
export type UpdateLinkMutationHookResult = ReturnType<
  typeof useUpdateLinkMutation
>;
export type UpdateLinkMutationResult =
  Apollo.MutationResult<UpdateLinkMutation>;
export type UpdateLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateLinkMutation,
  UpdateLinkMutationVariables
>;
export const CreateSummaryDocument = gql`
  mutation createSummary($input: NarrativeInput!) {
    createSummary: createNarrative(input: $input) {
      ...SummaryData
    }
  }
  ${SummaryDataFragmentDoc}
`;
export type CreateSummaryMutationFn = Apollo.MutationFunction<
  CreateSummaryMutation,
  CreateSummaryMutationVariables
>;

/**
 * __useCreateSummaryMutation__
 *
 * To run a mutation, you first call `useCreateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSummaryMutation, { data, loading, error }] = useCreateSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSummaryMutation,
    CreateSummaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSummaryMutation,
    CreateSummaryMutationVariables
  >(CreateSummaryDocument, options);
}
export type CreateSummaryMutationHookResult = ReturnType<
  typeof useCreateSummaryMutation
>;
export type CreateSummaryMutationResult =
  Apollo.MutationResult<CreateSummaryMutation>;
export type CreateSummaryMutationOptions = Apollo.BaseMutationOptions<
  CreateSummaryMutation,
  CreateSummaryMutationVariables
>;
export const DeleteSummaryDocument = gql`
  mutation deleteSummary($id: Int!) {
    deleteSummary: deleteNarrative(id: $id) {
      dateCreated
      dateUpdated
      originalLanguage
      content {
        content
        language
        title
        translator
      }
    }
  }
`;
export type DeleteSummaryMutationFn = Apollo.MutationFunction<
  DeleteSummaryMutation,
  DeleteSummaryMutationVariables
>;

/**
 * __useDeleteSummaryMutation__
 *
 * To run a mutation, you first call `useDeleteSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSummaryMutation, { data, loading, error }] = useDeleteSummaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSummaryMutation,
    DeleteSummaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSummaryMutation,
    DeleteSummaryMutationVariables
  >(DeleteSummaryDocument, options);
}
export type DeleteSummaryMutationHookResult = ReturnType<
  typeof useDeleteSummaryMutation
>;
export type DeleteSummaryMutationResult =
  Apollo.MutationResult<DeleteSummaryMutation>;
export type DeleteSummaryMutationOptions = Apollo.BaseMutationOptions<
  DeleteSummaryMutation,
  DeleteSummaryMutationVariables
>;
export const UpdateSummaryDocument = gql`
  mutation updateSummary($id: Int!, $input: NarrativeInput!) {
    updateSummary: updateNarrative(id: $id, input: $input) {
      ...SummaryData
    }
  }
  ${SummaryDataFragmentDoc}
`;
export type UpdateSummaryMutationFn = Apollo.MutationFunction<
  UpdateSummaryMutation,
  UpdateSummaryMutationVariables
>;

/**
 * __useUpdateSummaryMutation__
 *
 * To run a mutation, you first call `useUpdateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSummaryMutation, { data, loading, error }] = useUpdateSummaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSummaryMutation,
    UpdateSummaryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSummaryMutation,
    UpdateSummaryMutationVariables
  >(UpdateSummaryDocument, options);
}
export type UpdateSummaryMutationHookResult = ReturnType<
  typeof useUpdateSummaryMutation
>;
export type UpdateSummaryMutationResult =
  Apollo.MutationResult<UpdateSummaryMutation>;
export type UpdateSummaryMutationOptions = Apollo.BaseMutationOptions<
  UpdateSummaryMutation,
  UpdateSummaryMutationVariables
>;
export const CreateTagCategoryDocument = gql`
  mutation createTagCategory($input: TagCategoryInput!) {
    createTagCategory(input: $input) {
      ...TagCategoryData
    }
  }
  ${TagCategoryDataFragmentDoc}
`;
export type CreateTagCategoryMutationFn = Apollo.MutationFunction<
  CreateTagCategoryMutation,
  CreateTagCategoryMutationVariables
>;

/**
 * __useCreateTagCategoryMutation__
 *
 * To run a mutation, you first call `useCreateTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagCategoryMutation, { data, loading, error }] = useCreateTagCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTagCategoryMutation,
    CreateTagCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTagCategoryMutation,
    CreateTagCategoryMutationVariables
  >(CreateTagCategoryDocument, options);
}
export type CreateTagCategoryMutationHookResult = ReturnType<
  typeof useCreateTagCategoryMutation
>;
export type CreateTagCategoryMutationResult =
  Apollo.MutationResult<CreateTagCategoryMutation>;
export type CreateTagCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateTagCategoryMutation,
  CreateTagCategoryMutationVariables
>;
export const DeleteTagCategoryDocument = gql`
  mutation deleteTagCategory($id: Int!) {
    deleteTagCategory(id: $id) {
      content {
        name
        language
      }
      color
      priority
    }
  }
`;
export type DeleteTagCategoryMutationFn = Apollo.MutationFunction<
  DeleteTagCategoryMutation,
  DeleteTagCategoryMutationVariables
>;

/**
 * __useDeleteTagCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagCategoryMutation, { data, loading, error }] = useDeleteTagCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTagCategoryMutation,
    DeleteTagCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTagCategoryMutation,
    DeleteTagCategoryMutationVariables
  >(DeleteTagCategoryDocument, options);
}
export type DeleteTagCategoryMutationHookResult = ReturnType<
  typeof useDeleteTagCategoryMutation
>;
export type DeleteTagCategoryMutationResult =
  Apollo.MutationResult<DeleteTagCategoryMutation>;
export type DeleteTagCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteTagCategoryMutation,
  DeleteTagCategoryMutationVariables
>;
export const UpdateTagCategoryDocument = gql`
  mutation updateTagCategory($id: Int!, $input: TagCategoryInput!) {
    updateTagCategory(id: $id, input: $input) {
      ...TagCategoryData
    }
  }
  ${TagCategoryDataFragmentDoc}
`;
export type UpdateTagCategoryMutationFn = Apollo.MutationFunction<
  UpdateTagCategoryMutation,
  UpdateTagCategoryMutationVariables
>;

/**
 * __useUpdateTagCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagCategoryMutation, { data, loading, error }] = useUpdateTagCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagCategoryMutation,
    UpdateTagCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTagCategoryMutation,
    UpdateTagCategoryMutationVariables
  >(UpdateTagCategoryDocument, options);
}
export type UpdateTagCategoryMutationHookResult = ReturnType<
  typeof useUpdateTagCategoryMutation
>;
export type UpdateTagCategoryMutationResult =
  Apollo.MutationResult<UpdateTagCategoryMutation>;
export type UpdateTagCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagCategoryMutation,
  UpdateTagCategoryMutationVariables
>;
export const UpdateTagCategoryPrioritiesDocument = gql`
  mutation updateTagCategoryPriorities($priorityList: [Int!]!) {
    updateTagCategoryPriorities(priorityList: $priorityList) {
      ...TagCategoryData
    }
  }
  ${TagCategoryDataFragmentDoc}
`;
export type UpdateTagCategoryPrioritiesMutationFn = Apollo.MutationFunction<
  UpdateTagCategoryPrioritiesMutation,
  UpdateTagCategoryPrioritiesMutationVariables
>;

/**
 * __useUpdateTagCategoryPrioritiesMutation__
 *
 * To run a mutation, you first call `useUpdateTagCategoryPrioritiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagCategoryPrioritiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagCategoryPrioritiesMutation, { data, loading, error }] = useUpdateTagCategoryPrioritiesMutation({
 *   variables: {
 *      priorityList: // value for 'priorityList'
 *   },
 * });
 */
export function useUpdateTagCategoryPrioritiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagCategoryPrioritiesMutation,
    UpdateTagCategoryPrioritiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTagCategoryPrioritiesMutation,
    UpdateTagCategoryPrioritiesMutationVariables
  >(UpdateTagCategoryPrioritiesDocument, options);
}
export type UpdateTagCategoryPrioritiesMutationHookResult = ReturnType<
  typeof useUpdateTagCategoryPrioritiesMutation
>;
export type UpdateTagCategoryPrioritiesMutationResult =
  Apollo.MutationResult<UpdateTagCategoryPrioritiesMutation>;
export type UpdateTagCategoryPrioritiesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTagCategoryPrioritiesMutation,
    UpdateTagCategoryPrioritiesMutationVariables
  >;
export const CreateTagDocument = gql`
  mutation createTag($input: TagInput!) {
    createTag(input: $input) {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;
export type CreateTagMutationFn = Apollo.MutationFunction<
  CreateTagMutation,
  CreateTagMutationVariables
>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTagMutation,
    CreateTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(
    CreateTagDocument,
    options
  );
}
export type CreateTagMutationHookResult = ReturnType<
  typeof useCreateTagMutation
>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<
  CreateTagMutation,
  CreateTagMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation deleteTag($id: Int!) {
    deleteTag(id: $id) {
      content {
        name
        language
      }
    }
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTagMutation,
    DeleteTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(
    DeleteTagDocument,
    options
  );
}
export type DeleteTagMutationHookResult = ReturnType<
  typeof useDeleteTagMutation
>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteTagMutation,
  DeleteTagMutationVariables
>;
export const UpdateTagDocument = gql`
  mutation updateTag($id: Int!, $input: TagInput!) {
    updateTag(id: $id, input: $input) {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTagMutation,
    UpdateTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(
    UpdateTagDocument,
    options
  );
}
export type UpdateTagMutationHookResult = ReturnType<
  typeof useUpdateTagMutation
>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateTagMutation,
  UpdateTagMutationVariables
>;
export const AcademicWorkDocument = gql`
  query academicWork($id: Int!) {
    academicWork: summary(id: $id) {
      ...AcademicWorkData
    }
  }
  ${AcademicWorkDataFragmentDoc}
`;

/**
 * __useAcademicWorkQuery__
 *
 * To run a query within a React component, call `useAcademicWorkQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicWorkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicWorkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAcademicWorkQuery(
  baseOptions: Apollo.QueryHookOptions<
    AcademicWorkQuery,
    AcademicWorkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AcademicWorkQuery, AcademicWorkQueryVariables>(
    AcademicWorkDocument,
    options
  );
}
export function useAcademicWorkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AcademicWorkQuery,
    AcademicWorkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AcademicWorkQuery, AcademicWorkQueryVariables>(
    AcademicWorkDocument,
    options
  );
}
export type AcademicWorkQueryHookResult = ReturnType<
  typeof useAcademicWorkQuery
>;
export type AcademicWorkLazyQueryHookResult = ReturnType<
  typeof useAcademicWorkLazyQuery
>;
export type AcademicWorkQueryResult = Apollo.QueryResult<
  AcademicWorkQuery,
  AcademicWorkQueryVariables
>;
export const AcademicWorksTableDocument = gql`
  query academicWorksTable {
    academicWorks: summaries {
      id
      authors {
        name
      }
      content {
        title
        language
      }
      tags {
        ...TagData
      }
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useAcademicWorksTableQuery__
 *
 * To run a query within a React component, call `useAcademicWorksTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicWorksTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicWorksTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcademicWorksTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AcademicWorksTableQuery,
    AcademicWorksTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AcademicWorksTableQuery,
    AcademicWorksTableQueryVariables
  >(AcademicWorksTableDocument, options);
}
export function useAcademicWorksTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AcademicWorksTableQuery,
    AcademicWorksTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AcademicWorksTableQuery,
    AcademicWorksTableQueryVariables
  >(AcademicWorksTableDocument, options);
}
export type AcademicWorksTableQueryHookResult = ReturnType<
  typeof useAcademicWorksTableQuery
>;
export type AcademicWorksTableLazyQueryHookResult = ReturnType<
  typeof useAcademicWorksTableLazyQuery
>;
export type AcademicWorksTableQueryResult = Apollo.QueryResult<
  AcademicWorksTableQuery,
  AcademicWorksTableQueryVariables
>;
export const AcademicWorksDocument = gql`
  query academicWorks {
    academicWorks: summaries {
      ...AcademicWorkData
    }
  }
  ${AcademicWorkDataFragmentDoc}
`;

/**
 * __useAcademicWorksQuery__
 *
 * To run a query within a React component, call `useAcademicWorksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicWorksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicWorksQuery({
 *   variables: {
 *   },
 * });
 */
export function useAcademicWorksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AcademicWorksQuery,
    AcademicWorksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AcademicWorksQuery, AcademicWorksQueryVariables>(
    AcademicWorksDocument,
    options
  );
}
export function useAcademicWorksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AcademicWorksQuery,
    AcademicWorksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AcademicWorksQuery, AcademicWorksQueryVariables>(
    AcademicWorksDocument,
    options
  );
}
export type AcademicWorksQueryHookResult = ReturnType<
  typeof useAcademicWorksQuery
>;
export type AcademicWorksLazyQueryHookResult = ReturnType<
  typeof useAcademicWorksLazyQuery
>;
export type AcademicWorksQueryResult = Apollo.QueryResult<
  AcademicWorksQuery,
  AcademicWorksQueryVariables
>;
export const ArticleDocument = gql`
  query article($id: Int!) {
    article: publication(id: $id) {
      ...ArticleData
    }
  }
  ${ArticleDataFragmentDoc}
`;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleQuery(
  baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options
  );
}
export function useArticleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(
    ArticleDocument,
    options
  );
}
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<
  ArticleQuery,
  ArticleQueryVariables
>;
export const ArticleDoisDocument = gql`
  query articleDois {
    articles: publications {
      id
      doi
    }
  }
`;

/**
 * __useArticleDoisQuery__
 *
 * To run a query within a React component, call `useArticleDoisQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleDoisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleDoisQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticleDoisQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArticleDoisQuery,
    ArticleDoisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleDoisQuery, ArticleDoisQueryVariables>(
    ArticleDoisDocument,
    options
  );
}
export function useArticleDoisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleDoisQuery,
    ArticleDoisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticleDoisQuery, ArticleDoisQueryVariables>(
    ArticleDoisDocument,
    options
  );
}
export type ArticleDoisQueryHookResult = ReturnType<typeof useArticleDoisQuery>;
export type ArticleDoisLazyQueryHookResult = ReturnType<
  typeof useArticleDoisLazyQuery
>;
export type ArticleDoisQueryResult = Apollo.QueryResult<
  ArticleDoisQuery,
  ArticleDoisQueryVariables
>;
export const ArticlesTableDocument = gql`
  query articlesTable {
    articles: publications {
      id
      doi
      year
      authors {
        name
      }
      content {
        title
        language
      }
      tags {
        ...TagData
      }
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useArticlesTableQuery__
 *
 * To run a query within a React component, call `useArticlesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticlesTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ArticlesTableQuery,
    ArticlesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticlesTableQuery, ArticlesTableQueryVariables>(
    ArticlesTableDocument,
    options
  );
}
export function useArticlesTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticlesTableQuery,
    ArticlesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticlesTableQuery, ArticlesTableQueryVariables>(
    ArticlesTableDocument,
    options
  );
}
export type ArticlesTableQueryHookResult = ReturnType<
  typeof useArticlesTableQuery
>;
export type ArticlesTableLazyQueryHookResult = ReturnType<
  typeof useArticlesTableLazyQuery
>;
export type ArticlesTableQueryResult = Apollo.QueryResult<
  ArticlesTableQuery,
  ArticlesTableQueryVariables
>;
export const ArticlesDocument = gql`
  query articles {
    articles: publications {
      ...ArticleData
    }
  }
  ${ArticleDataFragmentDoc}
`;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticlesQuery(
  baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(
    ArticlesDocument,
    options
  );
}
export function useArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticlesQuery,
    ArticlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(
    ArticlesDocument,
    options
  );
}
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<
  typeof useArticlesLazyQuery
>;
export type ArticlesQueryResult = Apollo.QueryResult<
  ArticlesQuery,
  ArticlesQueryVariables
>;
export const BookDocument = gql`
  query book($id: Int!) {
    book(id: $id) {
      ...BookData
    }
  }
  ${BookDataFragmentDoc}
`;

/**
 * __useBookQuery__
 *
 * To run a query within a React component, call `useBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookQuery(
  baseOptions: Apollo.QueryHookOptions<BookQuery, BookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BookQuery, BookQueryVariables>(BookDocument, options);
}
export function useBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookQuery, BookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BookQuery, BookQueryVariables>(
    BookDocument,
    options
  );
}
export type BookQueryHookResult = ReturnType<typeof useBookQuery>;
export type BookLazyQueryHookResult = ReturnType<typeof useBookLazyQuery>;
export type BookQueryResult = Apollo.QueryResult<BookQuery, BookQueryVariables>;
export const BooksTableDocument = gql`
  query booksTable {
    books {
      id
      ISBN
      year
      authors {
        name
      }
      content {
        title
        language
      }
      tags {
        ...TagData
      }
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useBooksTableQuery__
 *
 * To run a query within a React component, call `useBooksTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useBooksTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BooksTableQuery,
    BooksTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BooksTableQuery, BooksTableQueryVariables>(
    BooksTableDocument,
    options
  );
}
export function useBooksTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BooksTableQuery,
    BooksTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BooksTableQuery, BooksTableQueryVariables>(
    BooksTableDocument,
    options
  );
}
export type BooksTableQueryHookResult = ReturnType<typeof useBooksTableQuery>;
export type BooksTableLazyQueryHookResult = ReturnType<
  typeof useBooksTableLazyQuery
>;
export type BooksTableQueryResult = Apollo.QueryResult<
  BooksTableQuery,
  BooksTableQueryVariables
>;
export const BooksDocument = gql`
  query books {
    books {
      ...BookData
    }
  }
  ${BookDataFragmentDoc}
`;

/**
 * __useBooksQuery__
 *
 * To run a query within a React component, call `useBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useBooksQuery(
  baseOptions?: Apollo.QueryHookOptions<BooksQuery, BooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BooksQuery, BooksQueryVariables>(
    BooksDocument,
    options
  );
}
export function useBooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BooksQuery, BooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BooksQuery, BooksQueryVariables>(
    BooksDocument,
    options
  );
}
export type BooksQueryHookResult = ReturnType<typeof useBooksQuery>;
export type BooksLazyQueryHookResult = ReturnType<typeof useBooksLazyQuery>;
export type BooksQueryResult = Apollo.QueryResult<
  BooksQuery,
  BooksQueryVariables
>;
export const FetchPublicationDataFromCrossrefDocument = gql`
  query fetchPublicationDataFromCrossref($doi: String!) {
    fetchPublicationDataFromCrossref(doi: $doi) {
      ...CrossrefPublicationData
    }
  }
  ${CrossrefPublicationDataFragmentDoc}
`;

/**
 * __useFetchPublicationDataFromCrossrefQuery__
 *
 * To run a query within a React component, call `useFetchPublicationDataFromCrossrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPublicationDataFromCrossrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPublicationDataFromCrossrefQuery({
 *   variables: {
 *      doi: // value for 'doi'
 *   },
 * });
 */
export function useFetchPublicationDataFromCrossrefQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchPublicationDataFromCrossrefQuery,
    FetchPublicationDataFromCrossrefQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchPublicationDataFromCrossrefQuery,
    FetchPublicationDataFromCrossrefQueryVariables
  >(FetchPublicationDataFromCrossrefDocument, options);
}
export function useFetchPublicationDataFromCrossrefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPublicationDataFromCrossrefQuery,
    FetchPublicationDataFromCrossrefQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchPublicationDataFromCrossrefQuery,
    FetchPublicationDataFromCrossrefQueryVariables
  >(FetchPublicationDataFromCrossrefDocument, options);
}
export type FetchPublicationDataFromCrossrefQueryHookResult = ReturnType<
  typeof useFetchPublicationDataFromCrossrefQuery
>;
export type FetchPublicationDataFromCrossrefLazyQueryHookResult = ReturnType<
  typeof useFetchPublicationDataFromCrossrefLazyQuery
>;
export type FetchPublicationDataFromCrossrefQueryResult = Apollo.QueryResult<
  FetchPublicationDataFromCrossrefQuery,
  FetchPublicationDataFromCrossrefQueryVariables
>;
export const LatestEntriesDocument = gql`
  query latestEntries($latestEntriesInput: LatestEntriesInput!) {
    latestEntries(input: $latestEntriesInput) {
      entries {
        ...SearchResultData
      }
    }
  }
  ${SearchResultDataFragmentDoc}
`;

/**
 * __useLatestEntriesQuery__
 *
 * To run a query within a React component, call `useLatestEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestEntriesQuery({
 *   variables: {
 *      latestEntriesInput: // value for 'latestEntriesInput'
 *   },
 * });
 */
export function useLatestEntriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestEntriesQuery,
    LatestEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestEntriesQuery, LatestEntriesQueryVariables>(
    LatestEntriesDocument,
    options
  );
}
export function useLatestEntriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestEntriesQuery,
    LatestEntriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestEntriesQuery, LatestEntriesQueryVariables>(
    LatestEntriesDocument,
    options
  );
}
export type LatestEntriesQueryHookResult = ReturnType<
  typeof useLatestEntriesQuery
>;
export type LatestEntriesLazyQueryHookResult = ReturnType<
  typeof useLatestEntriesLazyQuery
>;
export type LatestEntriesQueryResult = Apollo.QueryResult<
  LatestEntriesQuery,
  LatestEntriesQueryVariables
>;
export const LinkDocument = gql`
  query link($id: Int!) {
    link: videoLink(id: $id) {
      ...LinkData
    }
  }
  ${LinkDataFragmentDoc}
`;

/**
 * __useLinkQuery__
 *
 * To run a query within a React component, call `useLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkQuery(
  baseOptions: Apollo.QueryHookOptions<LinkQuery, LinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinkQuery, LinkQueryVariables>(LinkDocument, options);
}
export function useLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LinkQuery, LinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinkQuery, LinkQueryVariables>(
    LinkDocument,
    options
  );
}
export type LinkQueryHookResult = ReturnType<typeof useLinkQuery>;
export type LinkLazyQueryHookResult = ReturnType<typeof useLinkLazyQuery>;
export type LinkQueryResult = Apollo.QueryResult<LinkQuery, LinkQueryVariables>;
export const LinksTableDocument = gql`
  query linksTable {
    links: videoLinks {
      id
      authors {
        name
      }
      content {
        title
        language
      }
      tags {
        ...TagData
      }
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useLinksTableQuery__
 *
 * To run a query within a React component, call `useLinksTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinksTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LinksTableQuery,
    LinksTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinksTableQuery, LinksTableQueryVariables>(
    LinksTableDocument,
    options
  );
}
export function useLinksTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinksTableQuery,
    LinksTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinksTableQuery, LinksTableQueryVariables>(
    LinksTableDocument,
    options
  );
}
export type LinksTableQueryHookResult = ReturnType<typeof useLinksTableQuery>;
export type LinksTableLazyQueryHookResult = ReturnType<
  typeof useLinksTableLazyQuery
>;
export type LinksTableQueryResult = Apollo.QueryResult<
  LinksTableQuery,
  LinksTableQueryVariables
>;
export const LinksDocument = gql`
  query links {
    links: videoLinks {
      ...LinkData
    }
  }
  ${LinkDataFragmentDoc}
`;

/**
 * __useLinksQuery__
 *
 * To run a query within a React component, call `useLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<LinksQuery, LinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinksQuery, LinksQueryVariables>(
    LinksDocument,
    options
  );
}
export function useLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LinksQuery, LinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinksQuery, LinksQueryVariables>(
    LinksDocument,
    options
  );
}
export type LinksQueryHookResult = ReturnType<typeof useLinksQuery>;
export type LinksLazyQueryHookResult = ReturnType<typeof useLinksLazyQuery>;
export type LinksQueryResult = Apollo.QueryResult<
  LinksQuery,
  LinksQueryVariables
>;
export const SearchDocument = gql`
  query search($input: SearchInput!) {
    search(input: $input) {
      ...SearchResult
    }
  }
  ${SearchResultFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export const SummariesTableDocument = gql`
  query summariesTable {
    summaries: narratives {
      id
      authors {
        name
      }
      content {
        title
        language
      }
      tags {
        ...TagData
      }
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useSummariesTableQuery__
 *
 * To run a query within a React component, call `useSummariesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummariesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummariesTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummariesTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SummariesTableQuery,
    SummariesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SummariesTableQuery, SummariesTableQueryVariables>(
    SummariesTableDocument,
    options
  );
}
export function useSummariesTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SummariesTableQuery,
    SummariesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SummariesTableQuery, SummariesTableQueryVariables>(
    SummariesTableDocument,
    options
  );
}
export type SummariesTableQueryHookResult = ReturnType<
  typeof useSummariesTableQuery
>;
export type SummariesTableLazyQueryHookResult = ReturnType<
  typeof useSummariesTableLazyQuery
>;
export type SummariesTableQueryResult = Apollo.QueryResult<
  SummariesTableQuery,
  SummariesTableQueryVariables
>;
export const SummariesDocument = gql`
  query summaries {
    summaries: narratives {
      ...SummaryData
    }
  }
  ${SummaryDataFragmentDoc}
`;

/**
 * __useSummariesQuery__
 *
 * To run a query within a React component, call `useSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<SummariesQuery, SummariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SummariesQuery, SummariesQueryVariables>(
    SummariesDocument,
    options
  );
}
export function useSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SummariesQuery,
    SummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SummariesQuery, SummariesQueryVariables>(
    SummariesDocument,
    options
  );
}
export type SummariesQueryHookResult = ReturnType<typeof useSummariesQuery>;
export type SummariesLazyQueryHookResult = ReturnType<
  typeof useSummariesLazyQuery
>;
export type SummariesQueryResult = Apollo.QueryResult<
  SummariesQuery,
  SummariesQueryVariables
>;
export const SummaryDocument = gql`
  query summary($id: Int!) {
    summary: narrative(id: $id) {
      ...SummaryData
    }
  }
  ${SummaryDataFragmentDoc}
`;

/**
 * __useSummaryQuery__
 *
 * To run a query within a React component, call `useSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<SummaryQuery, SummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SummaryQuery, SummaryQueryVariables>(
    SummaryDocument,
    options
  );
}
export function useSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SummaryQuery, SummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SummaryQuery, SummaryQueryVariables>(
    SummaryDocument,
    options
  );
}
export type SummaryQueryHookResult = ReturnType<typeof useSummaryQuery>;
export type SummaryLazyQueryHookResult = ReturnType<typeof useSummaryLazyQuery>;
export type SummaryQueryResult = Apollo.QueryResult<
  SummaryQuery,
  SummaryQueryVariables
>;
export const TagCategoriesTableDocument = gql`
  query tagCategoriesTable {
    tagCategories {
      ...TagCategoryData
    }
  }
  ${TagCategoryDataFragmentDoc}
`;

/**
 * __useTagCategoriesTableQuery__
 *
 * To run a query within a React component, call `useTagCategoriesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagCategoriesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagCategoriesTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagCategoriesTableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TagCategoriesTableQuery,
    TagCategoriesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TagCategoriesTableQuery,
    TagCategoriesTableQueryVariables
  >(TagCategoriesTableDocument, options);
}
export function useTagCategoriesTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagCategoriesTableQuery,
    TagCategoriesTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TagCategoriesTableQuery,
    TagCategoriesTableQueryVariables
  >(TagCategoriesTableDocument, options);
}
export type TagCategoriesTableQueryHookResult = ReturnType<
  typeof useTagCategoriesTableQuery
>;
export type TagCategoriesTableLazyQueryHookResult = ReturnType<
  typeof useTagCategoriesTableLazyQuery
>;
export type TagCategoriesTableQueryResult = Apollo.QueryResult<
  TagCategoriesTableQuery,
  TagCategoriesTableQueryVariables
>;
export const TagCategoriesDocument = gql`
  query tagCategories {
    tagCategories {
      ...TagCategoryData
    }
  }
  ${TagCategoryDataFragmentDoc}
`;

/**
 * __useTagCategoriesQuery__
 *
 * To run a query within a React component, call `useTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TagCategoriesQuery,
    TagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagCategoriesQuery, TagCategoriesQueryVariables>(
    TagCategoriesDocument,
    options
  );
}
export function useTagCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagCategoriesQuery,
    TagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagCategoriesQuery, TagCategoriesQueryVariables>(
    TagCategoriesDocument,
    options
  );
}
export type TagCategoriesQueryHookResult = ReturnType<
  typeof useTagCategoriesQuery
>;
export type TagCategoriesLazyQueryHookResult = ReturnType<
  typeof useTagCategoriesLazyQuery
>;
export type TagCategoriesQueryResult = Apollo.QueryResult<
  TagCategoriesQuery,
  TagCategoriesQueryVariables
>;
export const TagCategoryDocument = gql`
  query tagCategory($id: Int!) {
    tagCategory(id: $id) {
      ...TagCategoryData
    }
  }
  ${TagCategoryDataFragmentDoc}
`;

/**
 * __useTagCategoryQuery__
 *
 * To run a query within a React component, call `useTagCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    TagCategoryQuery,
    TagCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagCategoryQuery, TagCategoryQueryVariables>(
    TagCategoryDocument,
    options
  );
}
export function useTagCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagCategoryQuery,
    TagCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagCategoryQuery, TagCategoryQueryVariables>(
    TagCategoryDocument,
    options
  );
}
export type TagCategoryQueryHookResult = ReturnType<typeof useTagCategoryQuery>;
export type TagCategoryLazyQueryHookResult = ReturnType<
  typeof useTagCategoryLazyQuery
>;
export type TagCategoryQueryResult = Apollo.QueryResult<
  TagCategoryQuery,
  TagCategoryQueryVariables
>;
export const TagDocument = gql`
  query tag($id: Int!) {
    tag(id: $id) {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(
  baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
}
export function useTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
}
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const TagsTableDocument = gql`
  query tagsTable {
    tags {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useTagsTableQuery__
 *
 * To run a query within a React component, call `useTagsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsTableQuery(
  baseOptions?: Apollo.QueryHookOptions<TagsTableQuery, TagsTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsTableQuery, TagsTableQueryVariables>(
    TagsTableDocument,
    options
  );
}
export function useTagsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TagsTableQuery,
    TagsTableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsTableQuery, TagsTableQueryVariables>(
    TagsTableDocument,
    options
  );
}
export type TagsTableQueryHookResult = ReturnType<typeof useTagsTableQuery>;
export type TagsTableLazyQueryHookResult = ReturnType<
  typeof useTagsTableLazyQuery
>;
export type TagsTableQueryResult = Apollo.QueryResult<
  TagsTableQuery,
  TagsTableQueryVariables
>;
export const TagsDocument = gql`
  query tags {
    tags {
      ...TagData
    }
  }
  ${TagDataFragmentDoc}
`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
}
export function useTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(
    TagsDocument,
    options
  );
}
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
