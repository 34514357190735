import React from 'react';
import { MUIDataTableColumn } from 'mui-datatables';
import { useTranslation } from 'react-i18next';

import { SupportedLanguage, TagDataFragment } from '../../../../graphql/models';
import {
  getTagDescriptionTooltipProps,
  getTranslatedTagNameOrDefault,
} from '../../../../model/Tag';
import { useTableStyles } from '../styles';
import { TagChip } from '../../../tag/TagChip';
import { sortOutOfPlace } from '../../../../common/ListUtils';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';

export interface ResourcesTableRow {
  id: number;
  title: string;
  tags: string[];
  authors: string[];
  languages: string[];
  publicLink: string;
}

export interface ResourcesTableRowWithTags
  extends Omit<ResourcesTableRow, 'tags'> {
  tags: TagDataFragment[];
}

export function useResourcesTableColumns(
  tableData: ResourcesTableRowWithTags[]
): MUIDataTableColumn[] {
  const { t, i18n } = useTranslation();
  const classes = useTableStyles();

  return [
    {
      name: 'id',
      label: 'ID',
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (filterValue: string) => `ID: ${filterValue}`,
        },
      },
    },
    {
      name: 'title',
      label: t('commons.title'),
      options: {
        filterType: 'textField',
        customFilterListOptions: {
          render: (filterValue: string) =>
            `${t('commons.title')}: ${filterValue}`,
        },
      },
    },
    {
      name: 'tags',
      label: t('commons.tags'),
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return (
            <span>
              {sortOutOfPlace(
                tableData[dataIndex].tags,
                (a, b) => a.category.priority - b.category.priority
              ).map((tag, index) => {
                return (
                  <TagChip
                    className={classes.chip}
                    key={index}
                    label={getTranslatedTagNameOrDefault(
                      tag,
                      i18n.language as SupportedLanguage
                    )}
                    hoverValue={getTagDescriptionTooltipProps(tag, i18n, t)}
                    color={tag.category.color}
                  />
                );
              })}
            </span>
          );
        },
        customFilterListOptions: {
          render: (filterValue: string) => `Tag: ${filterValue}`,
        },
      },
    },
    {
      name: 'authors',
      label: t('commons.authors'),
      options: {
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return <div>{tableData[dataIndex].authors.join(', ')}</div>;
        },
        customFilterListOptions: {
          render: (filterValue: string) =>
            `${t('commons.author')}: ${filterValue}`,
        },
        sortCompare: (order) => {
          return (a: { data: string[] }, b: { data: string[] }) => {
            const aAuthors = [...a.data].join(',');
            const bAuthors = [...b.data].join(',');

            return (
              aAuthors.localeCompare(bAuthors) * (order === 'asc' ? 1 : -1)
            );
          };
        },
      },
    },
    {
      name: 'languages',
      label: t('commons.languages'),
      options: {
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return <div>{tableData[dataIndex].languages.join(', ')}</div>;
        },
        customFilterListOptions: {
          render: (filterValue: string) =>
            `${t('commons.language')}: ${filterValue}`,
        },
      },
    },
    {
      name: 'publicLink',
      label: t('commons.link'),
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex: number): JSX.Element => {
          return (
            <Button
              color="primary"
              component={RouterLink}
              to={tableData[dataIndex].publicLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                event.stopPropagation();
              }}
            >
              {t('commons.link')}
            </Button>
          );
        },
        filter: false,
      },
    },
  ];
}
