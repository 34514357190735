import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';

import { UserRole } from '../../../common/access-control/has-role-hook';
import { KEYCLOAK_REALM, KEYCLOAK_URL } from '../../../config';
import { KeycloakUserWithRole } from './get-users-hook';
import { useAuthorizationHeader } from './authorization-header-hook';

export const useUpdateUserRole = (): {
  updateUserRole: (
    user: KeycloakUserWithRole,
    newUserRole: UserRole
  ) => Promise<void>;
  loading: boolean;
  error?: Error;
} => {
  const [error, setError] = React.useState<Error | undefined>();
  const [loading, setLoading] = React.useState(false);

  const { authorizationHeader } = useAuthorizationHeader();

  const updateUserRole = async (
    user: KeycloakUserWithRole,
    newUserRole: UserRole
  ) => {
    setLoading(true);

    const allRolesUrl = `${KEYCLOAK_URL}/admin/realms/${KEYCLOAK_REALM}/roles`;
    const userRolesUrl = `${KEYCLOAK_URL}/admin/realms/${KEYCLOAK_REALM}/users/${user.id}/role-mappings/realm`;

    const config: AxiosRequestConfig = {
      headers: {
        ...authorizationHeader,
      },
    };

    try {
      // before changing the role we need to remove the previous one
      // the roles API requires both "id" and "name" of the role
      const { data } = await axios.get<Array<{ id: string; name: string }>>(
        allRolesUrl,
        config
      );

      const allRoles = data.map(({ id, name }) => ({ id, name }));

      const currentRole = allRoles.find((role) => role.name === user.role);
      const newRole = allRoles.find((role) => role.name === newUserRole);

      await axios.delete(userRolesUrl, { ...config, data: [currentRole] });
      await axios.post(userRolesUrl, [newRole], config);

      setLoading(false);
    } catch (axiosError) {
      setError(axiosError as Error);
    }
  };

  return { updateUserRole, loading, error };
};
