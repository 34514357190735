import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  SupportedLanguage,
  useTagCategoriesQuery,
} from '../../../graphql/models';
import { getTranslatedTagCategoryContentOrDefault } from '../../../model/TagCategory';
import { ApiRequestErrorAndLoadingWrapper } from '../../common/ApiRequestWrapper';
import { SelectInputField } from '../../common/form/SelectInputField';

interface Props {
  name: string;
  label: string;
  errorData?: FieldError;
}

export const TagCategorySelectFormField = (props: Props): JSX.Element => {
  const { i18n } = useTranslation();

  const { data: tagCategoriesData, loading, error } = useTagCategoriesQuery();

  const tagCategories = tagCategoriesData?.tagCategories ?? [];

  const optionData = tagCategories.map((tagCategory) => {
    return {
      value: tagCategory.id,
      displayValue: getTranslatedTagCategoryContentOrDefault(
        tagCategory,
        'name',
        i18n.language as SupportedLanguage
      ),
    };
  });

  return (
    <ApiRequestErrorAndLoadingWrapper loading={loading} error={error}>
      <SelectInputField {...props} optionData={optionData} />
    </ApiRequestErrorAndLoadingWrapper>
  );
};
