import { Divider, Grid } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { SearchResultDataFragment } from '../../../graphql/models';
import { LayoutConstants } from '../../../theme/LayoutConstants';
import { SearchResultsPagination } from './SearchResultsPagination';
import { SearchResultEntry } from './SearchResultEntry';
import { ResourceType } from '../../../pages/app/search/types';
import { titleCase } from '../../../common/StringUtils';

interface Props {
  results: SearchResultDataFragment[];
  resourceType: ResourceType;
}

const SearchResultsList = ({ results, resourceType }: Props): JSX.Element => {
  const pageSize = 10;
  const pageTotal = Math.ceil(results.length / pageSize);

  useEffect(() => {
    setPaginatedResults(paginate(results, 1));
  }, [results]);

  const paginate = (
    array: SearchResultDataFragment[],
    pageNumber: number
  ): SearchResultDataFragment[] => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const [paginatedResults, setPaginatedResults] = useState(
    paginate(results, 1)
  );

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    page: number
  ): void => {
    setPaginatedResults(paginate(results, page));
  };

  return (
    <Grid item xs={12} container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={12}>
        {paginatedResults.map((result, index) => (
          <Fragment key={`${titleCase(resourceType)}:${result.id}`}>
            <SearchResultEntry
              resourceType={resourceType}
              result={result}
              isFirst={index === 0}
            />
            <Divider />
          </Fragment>
        ))}
      </Grid>
      <Grid item xs={12}>
        {pageTotal > 1 ? (
          <SearchResultsPagination
            handlePageChange={handlePageChange}
            count={pageTotal}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export { SearchResultsList as ResultList };
