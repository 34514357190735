import { Grid, Button } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { LayoutConstants } from '../../theme/LayoutConstants';

export const LoginAndRegisterButtons = (): JSX.Element => {
  const { keycloak } = useKeycloak();

  const { t } = useTranslation();

  return (
    <Grid container spacing={LayoutConstants.gridSpacing}>
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Button
          color="primary"
          onClick={() => keycloak.login()}
          variant="contained"
          style={{ width: '100%' }}
        >
          Login
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          color="primary"
          onClick={() => keycloak.register()}
          variant="outlined"
          style={{ width: '100%' }}
        >
          {t('phrases.register')}
        </Button>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};
