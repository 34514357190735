import gql from 'graphql-tag';

export const TAG_CATEGORY_FRAGMENT = gql`
  fragment TagCategoryData on TagCategory {
    id
    content {
      name
      description
      language
    }
    references {
      text
      url
    }
    color
    priority
  }
`;
