import {
  Divider,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { SupportedLanguage, TagDataFragment } from '../../graphql/models';
import { LayoutConstants } from '../../theme/LayoutConstants';
import {
  getTagDescriptionTooltipProps,
  getTranslatedTagNameOrDefault,
} from '../../model/Tag';
import { useTranslation } from 'react-i18next';
import { GlossaryReferences } from './GlossaryReferences';
import {
  defaultSearchInput,
  useSearchDataContext,
} from '../search/SearchContext';
import { useHistory } from 'react-router-dom';
import { useRoutes } from '../../common/routing/routes-hook';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    tagItem: {
      cursor: 'pointer',
      '&:hover': {
        background: _theme.palette.background.paper,
      },
    },
  })
);

interface Props {
  tags: TagDataFragment[];
}

export const GlossaryTags = ({ tags }: Props): JSX.Element => {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const history = useHistory();
  const { routes } = useRoutes();
  const searchPath = routes.search.path;
  const { searchInputData, setSearchInputData } = useSearchDataContext();
  const defaultValues = searchInputData || defaultSearchInput;

  if (tags.length === 0 || !tags) {
    return <></>;
  }

  return (
    <Grid
      container
      spacing={LayoutConstants.gridSpacing}
      alignItems="center"
      style={{ marginTop: '10px' }}
    >
      {tags.map((tag, index) => (
        <>
          <Grid
            item
            container
            key={tag.id}
            spacing={LayoutConstants.gridSpacing}
            className={classes.tagItem}
            onClick={() => {
              if (!tag.id) {
                return;
              }
              setSearchInputData({
                ...defaultValues,
                tagFilter: [tag.id],
              });
              history.push(searchPath);
            }}
          >
            <Grid item xs={4}>
              <Typography>
                {getTranslatedTagNameOrDefault(
                  tag,
                  i18n.language as SupportedLanguage
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ textAlign: 'justify' }}>
                {getTagDescriptionTooltipProps(tag, i18n).description ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <GlossaryReferences references={tag.references} />
            </Grid>
          </Grid>
          {index < tags.length - 1 && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};
