import {
  Button,
  FormControl,
  Grid,
  GridDirection,
  GridItemsAlignment,
  GridSize,
  GridSpacing,
  makeStyles,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import * as Yup from 'yup';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { buildYupLocaleObject } from '../../../../common/yup-validation';
import { SearchInput } from '../../../../graphql/models';
import {
  useSearchDataContext,
  defaultSearchInput,
} from '../../../search/SearchContext';
import { SearchTermInputField } from './SearchTermInputField';
import { SearchTagFilterInputField } from './SearchTagFilterInputField';
import { useRoutes } from '../../../../common/routing/routes-hook';

interface Props {
  gridContainerProps?: {
    className?: string;
    alignItems?: GridItemsAlignment;
    direction?: GridDirection;
    spacing?: GridSpacing;
  };
  gridItemProps?: {
    className?: string;
    xs?: GridSize;
    lg?: GridSize;
  };
  searchButtonGridProps?: {
    className?: string;
    xs?: GridSize;
    lg?: GridSize;
  };
  searchButtonProps?: {
    size?: 'small' | 'medium' | 'large';
  };
  inputSize?: 'small' | 'medium';
  resetFormOnMount?: boolean;
}

const useStyles = makeStyles(() => ({
  startIcon: {
    margin: 0,
  },
  autocomplete: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
}));

export const SearchForm = ({
  gridContainerProps,
  gridItemProps,
  searchButtonProps,
  searchButtonGridProps,
  resetFormOnMount,
  inputSize,
}: Props): JSX.Element => {
  const classes = useStyles();

  const { t } = useTranslation();
  const history = useHistory();

  Yup.setLocale(buildYupLocaleObject(t));

  const { searchInputData, setSearchInputData } = useSearchDataContext();
  const { routes } = useRoutes();

  const searchPath = routes.search.path;

  const defaultValues = searchInputData || defaultSearchInput;

  const searchForm = useForm<SearchInput>({
    defaultValues,
    mode: 'onTouched',
  });

  const { handleSubmit } = searchForm;

  const submitHandler: SubmitHandler<SearchInput> = (data) => {
    setSearchInputData({ ...data });
    history.push(searchPath);
  };

  const handleFormReset = (): void => {
    searchForm.reset(defaultSearchInput);
    setSearchInputData(defaultSearchInput);
  };

  return (
    <FormProvider {...searchForm}>
      <form onSubmit={handleSubmit(submitHandler)} onReset={handleFormReset}>
        <FormControl variant="outlined" fullWidth>
          <Grid container {...gridContainerProps}>
            <Grid item {...gridItemProps}>
              <SearchTermInputField
                size={inputSize}
                resetOnMount={resetFormOnMount}
              />
            </Grid>
            <Grid className={classes.autocomplete} item {...gridItemProps}>
              <SearchTagFilterInputField size={inputSize} />
            </Grid>
            <Grid item {...searchButtonGridProps}>
              <Button
                className="searchButton"
                variant="contained"
                startIcon={<Search />}
                color="primary"
                type="submit"
                {...searchButtonProps}
              >
                {t('commons.search')}
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </FormProvider>
  );
};
