import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core';
import { LayoutConstants } from '../../../theme/LayoutConstants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface Props {
  message: string;
  isOpen?: boolean;
  severity: 'success' | 'error' | 'warning';
}

function Alert(props: AlertProps) {
  return (
    <MuiAlert
      elevation={LayoutConstants.elevation}
      variant="filled"
      {...props}
    />
  );
}

export const GenericSnackbar = ({
  message,
  isOpen = false,
  severity,
}: Props): JSX.Element => {
  const [open, setOpen] = React.useState(isOpen);
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
